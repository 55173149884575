import { HttpClient } from "@angular/common/http";
import { Component, Input } from "@angular/core";
import { environment } from "@envs/environment";
import {ResourcesService} from "@/_services/resources.service";
import {IncidentsService} from "@/_services/incidents.service";

export interface IncidentMetrics {
  currentIncidents: number;
  requestedAreaCovered: number;
  segmentsAtRisk: number;
  overallRiskLevel: number;
  totalPoints: number;
  totalAreaAtRisk: number;
}

@Component({
  standalone: false,
  selector: 'event-metrics',
  providers: [
    {
      provide: ResourcesService,
      useClass: IncidentsService
    }
  ],
  templateUrl: './event-metrics.component.html',
  styleUrls: ['./event-metrics.component.scss']
})
export class EventMetricsComponent {
  @Input()
  public data: IncidentMetrics;
  private http: HttpClient;
  public loading: boolean = true;
  private _filter: any = {};
  public service: ResourcesService;

  constructor(httpClient: HttpClient, public _resourceService: ResourcesService) {
    this.data = {
      currentIncidents: 0,
      requestedAreaCovered: 0,
      segmentsAtRisk: 0,
      overallRiskLevel: 0,
      totalPoints: 0,
      totalAreaAtRisk: 0
    };
    this.service = _resourceService
    this.http = httpClient;

  }
  @Input() set filter(value: any) {
    if(this._filter != value && value != undefined) {
      this._filter = value;
      this.refreshMetrics();
    }
  }
  refreshMetrics(): void {
    this.loading = true;
    this.service.getMetrics(this._filter, {})
      .subscribe({
        next: (result: any) => {
          if (result?.item != null) {
            this.data = { ...this.data, ...result?.item };
            this.data.totalPoints = 0;
          }
          this.loading = false;
        },
        error: (error: Error) => console.error(error)
      });
  }
}
