<h1 mat-dialog-title *ngIf="data.title">{{data.title}}</h1>
<div mat-dialog-content>
  <div class="d-flex flex-row align-items-center">
    <div class="me-4">
      <i-feather name="{{data.icon}}"></i-feather>
    </div>
    <div>
      <span class="mat-dialog-message">{{data.message}}</span>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button (click)="onNoClick()" class="btn btn-secondary me-2">No</button>
  <button [mat-dialog-close]="true" class="btn btn-primary">Yes</button>
</div>
