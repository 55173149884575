import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";
import { DialogService } from "@/_services/dialog.service";
import { environment } from "@envs/environment";
import { Router } from "@angular/router";
import { DataLayersService } from "@/_services/data-layers.service";

@Component({
  selector: 'data-layer-create',
  providers: [
    {
      provide: DataLayersService
    }
  ],
  styleUrls: ['data-layer-create.component.scss'],
  templateUrl: 'data-layer-create.component.html',
})
export class DataLayerCreateComponent {
  public customDataLayerName: string = "";
  public createLoader: boolean = false;

  http: HttpClient;

  public selectedDataLayers: any = [];
  public availableFilters: any = [];
  public selectedFilters: any = [];
  public showHint: boolean = true;
  public hintText: string | undefined;
  private readonly _dataLayersLookupsService: DataLayersService;
  public dataDate: any = { operator: 11, valuePrimary: null, valueSecondary: null };
  public groupId: any;
  public mergeR: any = ["0.5"];

  public dataLayers = [
    {
      group: "Optical data",
      name: "Vegetation",
      id: "vegetation",
      filters: [
        {
          id: "type",
          title: "Vegetation type",
          field: "IncidentClassId",
          type: "lookup-multi",
          lookupName: "INCIDENTTYPE"
        },
        {
          id: "ndvi",
          field: "NDVI",
          title: "Ndvi",
          type: "number",
          value: { operator: 11, valuePrimary: null, valueSecondary: null }
        },
        {
          id: "height",
          field: "Height",
          title: "Height estimate [m]",
          type: "number",
          value: { operator: 4, valuePrimary: 10, valueSecondary: null }
        },
      ]
    },
    {
      group: "Optical data",
      name: "Man made",
      id: "man_made",
      filters: [
        {
          id: "height",
          field: "Height",
          title: "Height estimate [m]",
          type: "number",
          value: { operator: 4, valuePrimary: 10, valueSecondary: null }
        }
      ]
    },
    {
      group: "Optical data",
      name: "Water",
      id: "water"
    },
    {
      group: "Wether data",
      name: "Wind",
      id: "wind"
    },
    {
      group: "Wether data",
      name: "Temperature",
      id: "temperature"
    }
  ]
  public distanceFilter = {
    field: "Distance",
    title: "Horizontal distance [m]",
    type: "numberlimited",
    value: { operator: 6, valuePrimary: null, valueSecondary: null }
  };
  public areaFilter = {
    field: "Area",
    title: "Area",
    type: "numberlimited",
    value: { operator: 4, valuePrimary: null, valueSecondary: null }
  };

  constructor(
    private readonly _http: HttpClient,
    public dialogService: DialogService,
    private readonly router: Router,
    _dataLayersLookupsService: DataLayersService
  ) {
    this.http = _http;
    this.showHint = false;
    this._dataLayersLookupsService = _dataLayersLookupsService;

    this._dataLayersLookupsService.getNonPagedList().subscribe(result => {
      if (result.ok && result.items.length > 0) {
        result.items.forEach((item: any) => {
          this.dataLayers = [...this.dataLayers, {
            id: item.id,
            name: item.name,
            group: "Custom Data Layers",
            filters: item.availableFilters
          }];
        });
      }
    });
  }
  onDataLayersChange($event: any) {
    this.availableFilters = [];
    $event.forEach((dataLayer: any) => {
      if(dataLayer.filters != undefined) {
        dataLayer.filters.forEach((filter: any) => {
          if (!this.availableFilters.find((x: any) => {
            return x.id == filter.id && x.parentId == dataLayer.id
          })) {
            filter.parentId = dataLayer.id
            filter.parentName = dataLayer.name;
            this.availableFilters = [...this.availableFilters, filter];
          }
        })
      }
    })
  }
  onDataLayersClear($event: any) {
    this.availableFilters = [];
  }

  onDataLayerRemove($event: any) {
    this.selectedFilters = [...this.selectedFilters.filter((x: any) => { return x.parentId != $event.id })]
  }
  create() {
    let selectedDataLayers: SingleDataLayerInfo[] = [];

    this.selectedDataLayers.forEach((dataLayer: any) => {
      selectedDataLayers.push({
        Id: dataLayer.id,
        Name: dataLayer.name,
        Date: this.dataDate,
        GroupId: undefined,
        Columns: [],
        Table: "",
        Filters: []
      })
    });

    this.selectedFilters.forEach((filter: any) => {
      let index = selectedDataLayers.findIndex((x: any) => { return x.Id == filter.parentId })
      if (index > -1) {
        if(selectedDataLayers[index].Filters != undefined) {
          selectedDataLayers[index].Filters.push({
            Title: filter.name,
            Field: filter.field,
            Type: filter.type,
            Value: (filter.value.operator != undefined) ? filter.value : {Values: filter.value},
          });
        }
      } else {
        selectedDataLayers.push({
          Id: filter.parentId,
          Name: filter.parentName,
          Date: this.dataDate,
          GroupId: undefined,
          Columns: [],
          Table: "",
          Filters: [{
            Field: filter.field,
            Title: filter.title,
            Type: filter.type,
            Value: (filter.value.operator != undefined) ? filter.value : {Values: filter.value},
          }]
        })
      }
    })

    const params = {
      Name: this.customDataLayerName,
      SelectedLayers: selectedDataLayers,
      DistanceFilter: this.distanceFilter,
      AreaFilter: this.areaFilter,
      MergeDistance: this.mergeR[0],
    };
    let closeme = true;

    if (!this.createLoader) {
      this.createLoader = true;
      this.http.post<any>(`${environment.apiUrl}/api/DataLayer/CreateCustomDataLayer/`, params).subscribe(result => {
        if (result.ok) {
          this.createLoader = false;
          this.router.navigate(["/data-layers"]);
        }
        else {
          this.dialogService.displayErrorMessages(result.messages);
          closeme = false;
          this.createLoader = false;
        }
      });
      setTimeout(() => {
        if (closeme) {
          this.createLoader = false;
          this.router.navigate(["/data-layers"]);
        }
      }, 5000);
    }
  }

  close() {
    this.router.navigate(["/report/list"]);
  }
}

class SingleDataLayerInfo {
  Id: number | undefined;
  Name: string = "";
  Table: string = "";
  Date: any = { operator: 11, valuePrimary: null, valueSecondary: null };
  GroupId: number | undefined;
  Columns: string[] = [];
  Filters: GenericFilterDefinition[] = [];
}

class GenericFilterDefinition {
  Field: string = "";
  Type: string = "";
  Title: string = "";
  Value: any = { operator: null, valuePrimary: null, valueSecondary: null };
}
