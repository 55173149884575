import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResourcesService } from "./resources.service";
import {Observable, Subject, switchMap} from "rxjs";
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AssetsService extends ResourcesService {
  protected getAssetsForMapEndpoint = "/GetAssetsForMap";
  protected getAssetsForMapRequest$ = new Subject();
  protected getAssetsForMapResponse$ = new Subject();

  constructor(protected override httpClient: HttpClient ) {
    super(httpClient);
    this.getAssetsForMapRequest$.pipe(switchMap(
      params => {
        return this.httpClient.post<any[]>(`${this.apiUrl + this.getAssetsForMapEndpoint}`, params)
          .pipe(
            catchError(this.handleError)
          )})).subscribe(results => this.getAssetsForMapResponse$.next(results));
  }
  getAssetsForMap(filter: any = {}, sort: any = {}): Observable<any> {
    let params = {
      "operationType":"SEARCH",
      "updateCount":true,
      "sort":
        {
          "columnName": sort?.columnName,
          "sortDirectionText": sort?.order
        },
      "filter": filter
    }
    this.getAssetsForMapRequest$.next(params);
    return this.getAssetsForMapResponse$.asObservable();
  }
  override getResourceUrl(): string {
    return 'Asset';
  }
}
