<div class="d-flex flex-row align-items-center pt-2 pb-2 mb-2 border-bottom">
  <h1 class="h4 header">Create Custom Data Layer</h1>
  <div class="name-input">
    <mat-form-field appearance="outline" subscriptSizing="dynamic">
      <mat-label>Custom Data Layer Name</mat-label>
      <input matInput [(ngModel)]="customDataLayerName" required>
    </mat-form-field>
  </div>
</div>
<div class="row">
  <div class="col-2">
    <div class="my-1">
      <label for="choose-data-layer">Choose data layer(s)</label>
      <ng-select id="choose-data-layer"
                 [items]="dataLayers"
                 bindLabel="name"
                 [multiple]="true"
                 placeholder="Choose data layer(s)"
                 (clear)="onDataLayersClear($event)"
                 (change)="onDataLayersChange($event)"
                 (remove)="onDataLayerRemove($event)"
                 [(ngModel)]="selectedDataLayers"
                 groupBy="group"
                 [selectableGroupAsModel]="false">
        <ng-template ng-option-tmp let-item="item">
          {{ item.name }}
        </ng-template>
      </ng-select>
    </div>
    <div class="my-1">
        <label for="choose-filters">Choose filters</label>
        <ng-select id="choose-filters"
                   [items]="availableFilters"
                   [multiple]="true"
                   bindLabel="title"
                   class="columnsSelect"
                   placeholder="Choose filters"
                   [(ngModel)]="selectedFilters"
                   groupBy="parentName"
                   [selectableGroupAsModel]="false">
          <ng-template ng-option-tmp let-item="item">
            {{ item.title }}
          </ng-template>
        </ng-select>
    </div>
    <div class="my-1">
      <button (click)="create()" class="btn btn-primary" [disabled]="createLoader">
        <span *ngIf="createLoader" class="spinner-border spinner-border-sm mr-1"></span>
        Create
      </button>
    </div>
  </div>
  <div class="col-5">
    <h3>{{customDataLayerName}}</h3>
    <div *ngIf="selectedDataLayers">
      <h4>Data layers and filters</h4>
      <ul class="selected-data-layers-list">
        <li *ngFor="let dataLayer of selectedDataLayers" class="selected-data-layer">
          <span class="label">{{dataLayer.name}}</span>
          <ul class="selected-filters-list">
            <li *ngFor="let filter of selectedFilters | filterBy: ['parentId']: dataLayer.id" class="selected-item">
                <span class="label">{{filter.title}} - {{filter.parentName}}</span>
                <app-filter-input *ngIf="filter.type == 'string'" [(ngModel)]="filter.value" [disabled]="filter.disabled" type="text"></app-filter-input>
                <app-filter-input *ngIf="filter.type == 'number'" [(ngModel)]="filter.value" [disabled]="filter.disabled" type="number"></app-filter-input>
                <app-filter-input *ngIf="filter.type == 'numberlimited'" [(ngModel)]="filter.value" [disabled]="filter.disabled" type="numberlimited"></app-filter-input>
                <app-filter-input *ngIf="filter.type == 'date'" [(ngModel)]="filter.value" [disabled]="filter.disabled" type="date"></app-filter-input>
                <app-lookup *ngIf="filter.type == 'lookup-single'" [name]="filter.lookupName" [(ngModel)]="filter.value" mode="single" [disabled]="filter.disabled"></app-lookup>
                <app-lookup *ngIf="filter.type == 'lookup-multi'" [name]="filter.lookupName" [(ngModel)]="filter.value" mode="multi" [disabled]="filter.disabled"></app-lookup>
                <app-lookup *ngIf="filter.type == 'lookup-single-nonnull'" [name]="filter.lookupName" [(ngModel)]="filter.value" mode="singlenn" [disabled]="filter.disabled"></app-lookup>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
  <div class="col-5">
    <strong>
      Select data range for the report:
    </strong>
    <div class="d-flex flex-row">
      <label class="form-label form-label-sm m-1 ms-0" for="group">By Pass: </label>
      <app-lookup [name]="'GROUP'" [(ngModel)]="groupId" mode="singlenn" id="group"></app-lookup>
      <label class="form-label form-label-sm m-1" for="dates">or by Date: </label>
      <app-filter-input [(ngModel)]="dataDate" type="date" id="dates"></app-filter-input>
    </div>
    <div class="col-12">
      <b>Merge events</b>
    </div>
    <div class="col-12">
      <span>
        the number of single events can sometimes be overwhelming. Use the selector below to group events related to the same variable, that are close to each other:
      </span>
    </div>
    <div class="col-12 overflow-y-visible">
      Merge events that are less than <app-lookup [name]="'MERGERADIUS'" [(ngModel)]="mergeR" mode="singlenn"></app-lookup> from each other.
    </div>
    <div class="input-190">
      <strong>Distance</strong>
        <app-filter-input [(ngModel)]="distanceFilter.value" type="numberlimited" postfix="m"></app-filter-input>
    </div>
    <div class="input-190">
        <strong>Area</strong>
        <app-filter-input [(ngModel)]="areaFilter.value" type="numberlimited" postfix="m"></app-filter-input>
    </div>
  </div>
</div>
