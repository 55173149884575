import {Component, Input } from "@angular/core";
import {environment} from "@envs/environment";
import {HttpClient} from "@angular/common/http";
import {ResourcesService} from "@/_services/resources.service";
import {AssetsService} from "@/_services/assets.service";

export interface AssetMetrics {
  total: number,
  totalPoints: number,
  totalLength: number,
  totalArea: number
}

@Component({
  standalone: false,
  selector: 'asset-metrics',
  providers: [
    {
      provide: ResourcesService,
      useClass: AssetsService
    }
  ],
  templateUrl: './asset-metrics.component.html',
  styleUrls: ['./asset-metrics.component.scss']
})
export class AssetMetricsComponent {
  @Input() public data: AssetMetrics;
  private http: HttpClient;
  public loading: boolean = true;
  private _filter: any = {};
  public service: ResourcesService;
  public metricsCount: number = 0;

  constructor(httpClient: HttpClient, _resourceService: ResourcesService) {
    this.data = {
      total: 0,
      totalPoints: 0,
      totalLength: 0,
      totalArea: 0
    };
    this.http = httpClient;
    this.service = _resourceService;
  }

  @Input() set filter(value: any) {
    if(this._filter != value && value != undefined) {
      this._filter = value;
      this.refreshMetrics();
    }
  }

  refreshMetrics(): void {
    this.loading= true;
    this.service.getMetrics(this._filter, {})
      .subscribe({
        next: (result: any) => {
          if (result?.item != null) {
            this.data = { ...this.data, ...result?.item };
            Object.entries(this.data)
              .forEach(([key, value]) => {
                if (value > 0) {
                  this.metricsCount++;
                }
              })
          }
          this.loading= false;
        },
        error: (error: Error) => console.error(error)
      });
  }
}
