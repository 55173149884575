import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { ResourcesService } from "@/_services/resources.service";
import { MatExpansionPanel } from '@angular/material/expansion';
import { Subject, takeUntil } from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import { GridConfigDialog } from '../grid-config-dialog/grid-config-dialog.component';
import { filter  as lodashFilter } from "lodash" ;

@Component({
  standalone: false,
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent {
  @ViewChild(MatExpansionPanel, { static: false }) expansionPanel!: MatExpansionPanel;
  @Input() data: any;
  private _config: GridConfig | undefined;
  @Input() filterAsArray: boolean = false;
  @Input() set config(value: GridConfig | undefined) {
    if (value != undefined) {
      this._config = value;
    }
  }
  get config() {
    return this._config;
  }

  @Input() service: ResourcesService | undefined;
  @Input() loadData: boolean = true;
  @Input() showHideFiltersBtn: boolean = false;
  @Input() parentId: number | undefined;
  @Output() filterEvent = new EventEmitter<any>();
  public filters: any = [];
  public sort: SortConfig | undefined;
  public page: number;
  @Input() count: number = 15;
  public totalCount: number;
  public pageNumber: number;
  public rangeOffset: number;
  public range: number;
  private _filter: any;
  public dataNotFound: boolean;
  public loading: boolean;
  private readonly unsubscribe$:Subject<void> = new Subject();
  public selectedItemsList: any[];
  public allSelected: boolean;
  public lodashFilter = lodashFilter;

  public isColumnVisible = function(x: any) {
    return x.visible || x.visible === undefined;
  }

  constructor(private readonly dialog: MatDialog) {
    this.page = 0;
    this.totalCount = 0;
    this.pageNumber = 1;
    this.rangeOffset = 0;
    this.range = 5;
    this.dataNotFound = false;
    this.loading = false;
    this.selectedItemsList = [];
    this.allSelected = false;
  }

  @Input() set filter(value: any) {
    this._filter = value;
    this.page = 0;
    this.filterEvent.emit(this._filter);
    if(this._filter !== undefined && this.loadData) {
      this.refreshGrid();
    }
  }
  changeSelection() {
    this.fetchSelectedItems()
  }
  selectAllChange() {
    if(this.allSelected) {
      this.data.forEach((item: any) => {
        item.selected = true;
      })
    }
    else {
      this.data.forEach((item: any) => {
        item.selected = false;
      })
    }
    this.fetchSelectedItems()
  }
  fetchSelectedItems() {
    this.selectedItemsList = this.data.filter((value: any) => {
      return value.selected
    });
    this.allSelected = this.selectedItemsList.length == this.data.length;
  }
  sortColumn(sortColumn: string): void {
    if (sortColumn != undefined && sortColumn != "") {
      if (this.sort == undefined || this.sort.columnName == "" || this.sort.columnName != sortColumn) {
        this.sort = {
          columnName: sortColumn,
          order: "desc"
        }
      } else if (this.sort.columnName == sortColumn && this.sort.order == "desc") {
        this.sort.order = "asc"
      } else if (this.sort.columnName == sortColumn && this.sort.order == "asc") {
        delete this.sort.columnName;
      }
      this.refreshGrid();
    }
  }
  clearSort(): void {
    if (this.sort?.columnName) {
      delete this.sort.columnName;
    }
  }
  refreshPagination(): void {
    if (this.pageNumber > 5) {
      this.range = 5;
    }
    else {
      this.range = this.pageNumber;
    }
    if (this.page < 5) {
      this.rangeOffset = -this.page;
    }
    else if (this.pageNumber - this.page < 3) {
      this.rangeOffset = -4 + (this.pageNumber - (this.page + 1));
    }
    else {
      this.rangeOffset = -2;
    }
  }
  refreshGrid(): void {
    this.loading = true;
    this.refreshPagination();
    this.data = [];
    if (this.service != undefined && this.loadData) {
      this.service.getList(this.page, this.count, this._filter, this.sort, this.parentId).pipe(takeUntil(this.unsubscribe$)).subscribe({
        next: (result) => {
          this.data = result.items;
          this.loading = false;
          if(result.items!= null) {
            if (result.items.length == 0) {
              this.dataNotFound = true;
            }
            if (result.items.length > 0) {
              this.dataNotFound = false;
            }
          }
          this.totalCount = result.totalItemCount;
          this.pageNumber = Math.ceil(this.totalCount / this.count);
          this.refreshPagination();
          this.fetchSelectedItems()
        },
        error: (error) => console.error(error)
      });
    }
  }

  async unsubscribeService(): Promise<void> {
    this.unsubscribe$.next();
  }

  goToPage(num: number, reload: boolean = true) {
    this.page = num;
    if(reload) {
      this.refreshGrid();
    }
  }

  nextPage() {
    if ((this.page + 1) < this.pageNumber) {
      this.page += 1;
      this.refreshGrid();
    }
  }

  prevPage() {
    if (this.page > 0) {
      this.page -= 1;
      this.refreshGrid();
    }
  }

  runAction(column: GridColDef, item: any) {
    let args = column.paramFields.map((element) => { return item[element] });
    let itemArray = new Array<any>();
    itemArray.push(item)
    if (typeof column.action === 'function') {
      column.action.apply(this.config?._self,itemArray.concat(args))
    }
    else {
      this.config?._self[column.action](...itemArray.concat(args))
    }
  }

  public openGridConfig(): void {
    this.dialog.open(GridConfigDialog, {
      data: this.config,
      width: '30vw',
      height: '80vh',
      disableClose: true
    });
  }
}
interface GridConfig {
  _self: any,
  title: string | undefined;
  colDefs: GridColDef[] | undefined;
  filters: any[] | undefined;
  selectable: boolean | undefined;
  selectActions: any[] | undefined;
  customiseParams: CustomConfigParams | undefined;
}

interface SortConfig {
  columnName?: string,
  order: string
}

interface CustomConfigParams {
  apiUrl: string,
  column: string,
  id: any,
}

interface GridColDef {
  id: string,
  title: string,
  originalTitle: string,
  field: string,
  sortColumn: string,
  type: string,
  check: string,
  btnText: string,
  btnIconName: string,
  btnTooltip: string,
  visible: boolean,
  editable: boolean,
  action: any,
  paramFields: Array<string>
  filter: FilterConfig
}
interface FilterConfig {
  field: string,
  title: string,
  type: string,
  lookupName: string,
  value: { operator: number, valuePrimary: string, valueSecondary: string, values: string[] }
}
