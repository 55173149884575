export const defaultAssetViewGridConfigColDef = [
  {
    title: '',
    type: 'action',
    btnText: '',
    btnIconName: 'map',
    action: 'showOnMap',
    paramFields: ['id']
  },
  {
    title: "Asset name",
    field: "name",
    sortColumn: "Name",
  },
  {
    title: "Event count",
    field: "incidentCount",
    sortColumn: "IncidentCount",
  },
  {
    title: "Minimal distance to asset",
    field: "minimalDistanceToAsset",
    sortColumn: "MinimalDistanceToAsset",
  },
  {
    title: "Minimal absolute distance to asset",
    field: "minimalDistance3DToAsset",
    sortColumn: "MinimalDistance3DToAsset",
  },
  {
    title: "Estimated length asset impacted [m]",
    field: "estimatedAssetLengthImpacted",
    sortColumn: "EstimatedAssetLengthImpacted",
  },
  {
    title: "Estimated  asset length impacted [%]",
    field: "estimatedAssetPercentageImpacted",
    sortColumn: "EstimatedAssetPercentageImpacted",
  },
  {
    title: "Total length of asset [m]",
    field: "assetLength",
    sortColumn: "AssetLength",
  },
];
