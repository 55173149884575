<div class="row">
  <div class="col-3">
    <div class="h-100 p-1 d-flex flex-column border rounded-3 text-center">
      <h6 class="text-warning">Current events</h6>
      <span class="display-6">
        <ngx-skeleton-loader count="1" [theme]="{margin: 0, width: '40%', height: '100%'}" *ngIf="loading"></ngx-skeleton-loader>
        <span *ngIf="!loading" class="text-warning">{{data.currentIncidents}}</span>
      </span>
    </div>
  </div>
  <div class="col-3">
    <div class="h-100 p-1 d-flex flex-column border rounded-3 text-center">
      <h6>Total # line assets at risk</h6>
      <span class="display-6">
        <ngx-skeleton-loader count="1" [theme]="{margin: 0, width: '40%', height: '100%'}" *ngIf="loading"></ngx-skeleton-loader>
        <span *ngIf="!loading">{{data.segmentsAtRisk}}</span>
      </span>
    </div>
  </div>
  <div class="col-3">
    <div class="h-100 p-1 d-flex flex-column border rounded-3 text-center">
      <h6>Total # point assets at risk</h6>
      <span class="display-6">
        <ngx-skeleton-loader count="1" [theme]="{margin: 0, width: '40%', height: '100%'}" *ngIf="loading"></ngx-skeleton-loader>
        <span *ngIf="!loading">{{data.totalPoints}}</span>
      </span>
    </div>
  </div>

  <div class="col-3">
    <div class="h-100 p-1 d-flex flex-column border rounded-3 text-center">
      <h6>Total km<sup>2</sup> land assets at risk</h6>
      <span class="display-6">
        <ngx-skeleton-loader count="1" [theme]="{margin: 0, width: '40%', height: '100%'}" *ngIf="loading"></ngx-skeleton-loader>
        <span *ngIf="!loading">{{data.totalAreaAtRisk}}</span>
      </span>
    </div>
  </div>
</div>
