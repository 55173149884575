<div class="d-flex flex-row align-items-center justify-content-between pt-3 pb-2 mb-1 border-bottom">
  <h1 mat-dialog-title>Configure Columns</h1>
  <button class="btn btn-warning btn-sm download-excel-btn" style="margin-right:25px" (click)="resetToDefault()">Reset to default</button>
</div>
<mat-dialog-content>
  <div class="example-list" cdkDropList (cdkDropListDropped)="drop($event)">
    @for (column of data.colDefs; track column) {
    <div class="example-box " *ngIf="column.editable" cdkDrag>
      <!--<span>{{data.colDefs.indexOf(column) +1}}</span>-->
      <input matInput [(ngModel)]="column.title">
      <span [innerHTML]="column.originalTitle" style="font-size:x-small"></span>
      <mat-checkbox [(ngModel)]="column.visible">
      </mat-checkbox>
    </div>
    }
  </div>
</mat-dialog-content>
<div mat-dialog-actions>
  <button mat-stroked-button large mat-dialog-close>Close</button>
  <button mat-stroked-button large (click)="save()">Save</button>
</div>
