<div class="d-flex flex-row align-items-center pt-2 pb-2 mb-2 border-bottom">
  <h1 class="h4 header">Create Report</h1>
  <div class="name-input">
    <mat-form-field appearance="outline" subscriptSizing="dynamic" [style.width.%]="100">
      <mat-label>Report Name</mat-label>
      <input matInput [(ngModel)]="reportName" required>
    </mat-form-field>
  </div>
</div>
<div class="row">
  <div class="col-3  mb-4">
    <div class="my-1">
      <label for="select-dimension">Choose dimension</label>
      <ng-select id="select-dimension"
                 [items]="dimensions"
                 bindLabel="label"
                 placeholder="Choose dimension"
                 (clear)="onDimensionClear($event)"
                 (change)="onDimensionChange($event)"
                 [(ngModel)]="selectedDimension"
                 [searchFn]="customSearchFn">
        <ng-template ng-option-tmp let-item="item">
          {{ item.label }}
        </ng-template>
      </ng-select>
    </div>
    <div class="my-1" *ngIf="selectedDimension?.type == 'asset'">
      <a href="#" data-bs-toggle="collapse" class="collapsed" data-bs-target="#add-compare">Add compare <i-feather name="chevron-down" class="xsm"></i-feather></a>
      <div id="add-compare" class="collapse">
        <ng-select id="choose-data-layer"
                   [items]="dataLayers"
                   [(ngModel)]="dataLayersToCompare"
                   [ngModelOptions]="{standalone: true}"
                   [multiple]="true"
                   bindLabel="name"
                   [maxSelectedItems]="2"
                   placeholder="Choose two data layers to compare">
        </ng-select>
        <button class="btn btn-secondary btn-sm" (click)="getCompareColumns()"  [disabled]="compareLoader">
          <span *ngIf="compareLoader" class="spinner-border spinner-border-sm mr-1"></span>
          Compare
        </button>
      </div>
    </div>
    <div class="my-1">
      <form [formGroup]="reportForm" novalidate>
        <label for="select-columns">Choose columns</label>
        <ng-select #compareSelect
                   id="select-columns"
                   (search)="onColumnSearch($event)"
                   formControlName="columns"
                   class="columnsSelect"
                   placeholder="Choose columns"
                   [searchFn]="customColumnsSearchFn"
                   (close)="onColumnSelectClose()">
          <ng-template ng-header-tmp>
            <div class="customBoxWrapper">
              <ul class="categoriesList">
                @for (dataLayer of dataLayers; track dataLayer.id+'-'+dataLayer.compareToId) {
                <li *ngIf="dataLayer.visible">
                  <span (mouseenter)="onMouseEnterColumnGroup(dataLayer)" data-bs-toggle="collapse" class="data-layer-name collapsed" [attr.data-bs-target]="'#' + dataLayer.id + '-' + dataLayer.compareToId">
                    <span>{{dataLayer.name}}</span>
                    <i-feather name="chevron-down" class="xsm"></i-feather>
                  </span>
                  <ul [id]="dataLayer.id + '-' + dataLayer.compareToId" class="columnsList collapse">
                    <ng-container *ngIf="dataLayer.colDefs != undefined && dataLayer.colDefs.length > 0">
                      @for (column of dataLayer.colDefs; track column.title) {
                        <li *ngIf="!column.selected && column.visible">
                          <span [ngClass]="{active: column.active}"
                                (click)="selectColumn(column, dataLayer)"
                                (keydown)="selectColumn(column, dataLayer)"
                                (mouseenter)="onMouseEnterColumnOption(column)"
                                (focus)="onMouseEnterColumnOption(column)"
                                [innerHTML]="column.title"></span>
                        </li>
                      }
                    </ng-container>
                  </ul>
                </li>
                }
              </ul>
              <div class="hintBox" [ngClass]="{'show': showHint}">
                {{this.hintText}}
              </div>
            </div>
          </ng-template>
          <ng-template ng-typetosearch-tmp></ng-template>
          <ng-template ng-notfound-tmp let-searchTerm="searchTerm"></ng-template>
          <ng-template ng-loadingtext-tmp let-searchTerm="searchTerm"></ng-template>
        </ng-select>
      </form>
    </div>
  </div>
  <div class="col-9">
    <div *ngIf="selectedDimension">
      <h4>Dimension</h4>
      <ul class="selected-list">
        <li class="selected-item"><span class="label">{{selectedDimension.label}}</span></li>
      </ul>
    </div>
    <div *ngIf="selectedColumns.length > 0">
      <h4>Columns</h4>
      <ul cdkDropList (cdkDropListDropped)="dropColumn($event)" class="selected-list">
        <li *ngFor="let column of selectedColumns" cdkDrag class="selected-item">
          <span class="drag-handle" cdkDragHandle>
            <i-feather name="more-vertical" class="xsm"></i-feather>
            <i-feather name="more-vertical" class="xsm"></i-feather>
          </span>
          <span class="label"><span [innerHTML]="column.title"></span>- {{column.parentName}}</span>
          <button class="remove" (click)="removeColumn(column)"><i-feather name="x" class="xsm"></i-feather></button>
        </li>
      </ul>
    </div>
    <hr />
    <h5>Preview</h5>
    <h4>{{reportName}}</h4>
    <app-grid class="w-100" [data]="fakeData" [config]="previewGridConfig"></app-grid>
  </div>
</div>
<div class="row">
  <div class="col-6">
    <div class="text-start my-1 ">
      <button (click)="close()" class="btn btn-primary" [disabled]="createLoader">
        Close
      </button>
    </div>
  </div>
  <div class="col-6">
    <div class="text-end my-1">
      <button (click)="create()" class="btn btn-primary" [disabled]="createLoader">
        <span *ngIf="createLoader" class="spinner-border spinner-border-sm mr-1"></span>
        Create
      </button>
    </div>
  </div>
</div>
