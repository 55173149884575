<div class="upload-container" *ngIf="files && files.length == 0">
  <input type="file" #fileInput id="fileInput" (change)="selectFile($event)">
  <h3>Drag and drop file here</h3>
  <h3>or</h3>
  <button mat-stroked-button for="fileInput" color="warning">Select file</button>
</div>
<div class="file-list" *ngIf="files.length > 0">
  <div class="file-container" *ngFor="let file of files">
    <div class="header">
      <div class="file-name-group ">
        <div class="file-name">{{file.file.name}}</div>
        <mat-hint class="file-size">{{file.rangeStart | fileSize}} / {{file.fileSize | fileSize}}</mat-hint>
      </div>
      <div class="file-controls">
        <button mat-icon-button color="primary" *ngIf="file.progress < 100 && !file.isPaused" (click)="pause(file)">
          <mat-icon>pause</mat-icon>
        </button>
        <button mat-icon-button color="primary" *ngIf="file.progress < 100 && file.isPaused" (click)="pause(file)">
          <mat-icon>play_arrow</mat-icon>
        </button>
        <button mat-icon-button color="primary" *ngIf="file.progress < 100 && !file.isCancelled" (click)="cancel(file)">
          <mat-icon>close</mat-icon>
        </button>
        <button mat-icon-button color="primary" *ngIf="file.progress == 100 || file.isCancelled" (click)="delete(file)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
    <div class="loader">
      <mtx-progress [type]="file.isCancelled ? 'danger' : (file.progress == 100 ? 'success' : (file.isPaused ? 'warning' : 'info'))"
                    [striped]="file.progress < 100"
                    [animate]="!file.isPaused"
                    [value]="file.progress"
                    [color]="'#fff'"
                    [height]="'16px'">
        {{file.progress + '%'}}
      </mtx-progress>
    </div>
  </div>
</div>
