import { HttpClient } from "@angular/common/http";
import { Component, Inject, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { environment } from "@envs/environment";
import { JsonItemResponse } from "@/_models/response";
import { MatTabGroup } from "@angular/material/tabs";
import { DialogService } from '@/_services/dialog.service';

@Component({
  standalone: false,
  selector: 'satelite-import',
  templateUrl: './satelite-import.component.html',
  styleUrls: ['./satelite-import.component.scss']
})
export class SateliteImportComponent {
  public loading: boolean = false;
  public content: IDictionary<string>[] = [];
  public contentColumns: string[] = [];
  public guid: string = "";
  public analysisName: string = "New satelite raster";
  public date: any;
  public fileUploaded: boolean = false;
  public enableImport: boolean = false;


  http: HttpClient;

  @ViewChild("shpUploadTabs", { static: false }) shpUploadTabs: MatTabGroup | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SateliteImportComponent>,
    private _http: HttpClient,
    public dialog: MatDialog,
    public dialogService: DialogService
  ) {
    this.http = _http;
  }

  onCancelled(index: number) {
    this.guid = "";
  }

  onError(index: number, message: string) {
    this.dialogService.displayError(message);
    this.loading = false;
    this.guid = "";
  }

  onRemoved(index: number, guid: string) {
    this.guid = "";
  }

  onFileProgress(index: number, rangeStart: number, guid: string) {
  }

  onFileUploaded(index: number, guid: string) {
    this.guid = guid;
    this.enableImport = true;
    //this.loading = true;
  }

  import() {
    this.loading = true;
    var request = {
      guid: this.guid,
      name: this.analysisName,
      date: (new Date(Date.UTC(this.date.getFullYear(), this.date.getMonth(), this.date.getDate(), 0, 0, 0))).toISOString(),
      isWMS: true
    }
    this.http.post<JsonItemResponse<number>>(`${environment.apiUrl}/api/SatelliteImage/Create`, request).subscribe(result => {
      if (result.ok) {
        this.dialogRef.close();
      }
      else {
        this.dialogService.displayErrorMessages(result.messages);
        this.loading = false;
      }
      this.loading = false;
    });
  }
}

interface IDictionary<T> {
  [key: string]: T
}
