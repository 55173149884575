<!-- nav -->
<header *ngIf="currentUser" class="sticky-top">
  <nav class="navbar p-0 flex-md-nowrap shadow">
    <a class="navbar-brand col-md-2 me-0 px-3" routerLink="/"><img src="assets/spottitt-logo.png" class="logo"></a>
    <a href="https://spottitt.com/contact/" class="btn-contact-us btn btn-warning btn-sm" target="_blank"><strong>Contact Us</strong></a>
    <button class="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false"
            aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <h1>Spottitt Metrics Factory</h1>
    <div class="navbar-nav">
      <div class="nav-item text-nowrap flex-row d-flex">
        <span class="navbar-text px-3 py-1"><i-feather name="user" class="sm"></i-feather> {{currentUser.firstName}} {{currentUser.lastName}}</span>
        <!--<a href="https://spottitt.com/spottitt-mf-demo-help-guide/" class="btn btn-warning btn-sm" target="_blank"><strong>Help</strong></a>-->
        <a class="nav-link px-3 py-1" (click)="logout()"><i-feather name="log-out" class="sm"></i-feather> Logout</a>
      </div>
    </div>
  </nav>
</header>

<!-- main app container -->
<div class="container-fluid" *ngIf="currentUser">
  <div class="row">
    <nav id="sidebarMenu" class="d-md-block bg-light sidebar collapse">
      <div class="position-sticky">
        <app-main-menu></app-main-menu>
      </div>
      <div class="app-version">{{version}}</div>
    </nav>
    <main class="ms-sm-auto px-md-4" [ngClass]="{'main-default': currentUser, 'main-map': isMap}">
      <router-outlet *ngIf="currentUser"></router-outlet>
    </main>
  </div>
</div>
<router-outlet *ngIf="!currentUser"></router-outlet>
