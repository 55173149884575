import { Component, Input, forwardRef } from '@angular/core';

@Component({
  standalone: false,
  selector: 'app-filter-as-text',
  templateUrl: './filter-as-text.component.html',
  styleUrls: ['./filter-as-text.component.scss'],
  providers: []
})

export class FilterAsTextComponent {
  @Input() value: any;
  constructor() { }
}
