<div class="row">
  <div class="col-6">
    <div class="h-100 p-1 d-flex flex-column border rounded-3 text-center">
      <h6>Latest data layer date:</h6>
      <span class="display-6">
        <ngx-skeleton-loader count="1" [theme]="{margin: 0, width: '40%', height: '100%'}" *ngIf="loading"></ngx-skeleton-loader>
        <span *ngIf="!loading">{{data.latestAnalysisDate | date: 'yyyy-MM-dd'}}</span>
      </span>
    </div>
  </div>
  <div class="col-6">
    <div class="h-100 p-1 d-flex flex-column border rounded-3 text-center">
      <h6>Number of data layer available</h6>
      <span class="display-6">
        <ngx-skeleton-loader count="1" [theme]="{margin: 0, width: '40%', height: '100%'}" *ngIf="loading"></ngx-skeleton-loader>
        <span *ngIf="!loading">{{data.analysesDone}}</span>
      </span>
    </div>
  </div>
</div>
