import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResourcesService } from "./resources.service";
import {Observable, Subject, switchMap} from "rxjs";
import {catchError} from "rxjs/operators";
import {IncidentsService} from "@/_services/incidents.service";

@Injectable({
  providedIn: 'root'
})
export class IncidentsByAssetService extends IncidentsService {
  protected getIncidentsMappedToAssetsEndpoint = "/GetIncidentsMappedToAssets";
  protected getIncidentsMappedToAssetsRequest$ = new Subject();
  protected getIncidentsMappedToAssetsResponse$ = new Subject();
  constructor(protected override httpClient: HttpClient) {
    super(httpClient);
    this.getListEndpoint = "/GetIncidentsByAssetList";
    this.getNonPagedListEndpoint = "/GetIncidentsByAssetNonPagedList";
    this.getIncidentsMappedToAssetsRequest$.pipe(switchMap(
      params => {
        return this.httpClient.post<any[]>(`${this.apiUrl + this.getIncidentsMappedToAssetsEndpoint}`, params)
          .pipe(
            catchError(this.handleError)
          )})).subscribe(results => this.getIncidentsMappedToAssetsResponse$.next(results));
  }
  getIncidentsMappedToAssets(filter: any = {}, sort: any = {}): Observable<any> {
    let params = {
      "operationType":"SEARCH",
      "updateCount":true,
      "sort":
        {
          "columnName": sort?.columnName,
          "sortDirectionText": sort?.order
        },
      "filter": filter
    }
    this.getIncidentsMappedToAssetsRequest$.next(params);
    return this.getIncidentsMappedToAssetsResponse$.asObservable();
  }
}
