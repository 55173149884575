import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@/app.module';
import { environment } from '@envs/environment';

import posthog from 'posthog-js'

if (environment.production) {
  enableProdMode();
}
if(environment.posthogToken) {
  posthog.init(
    environment.posthogToken,
    {
      api_host:"https://ph.spottitt.com:444",
      ui_host:"eu.posthog.com",
      capture_pageview: false
    }
  )
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
