import {Component, Input, OnInit, forwardRef, EventEmitter, Output} from '@angular/core';
import { LookupService } from "@/_services/lookup.service";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { IMultiSelectOption, IMultiSelectSettings } from "ngx-bootstrap-multiselect";

@Component({
  standalone: false,
  selector: 'app-lookup-ngselect',
  templateUrl: './lookup-ngselect.component.html',
  styleUrls: ['./lookup-ngselect.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LookupNgSelectComponent),
      multi: true
    }
  ]
})

export class LookupNgSelectComponent implements OnInit, ControlValueAccessor {
  @Input() name: string = "";
  @Input() _selectedValue: any = [];
  @Input() mode: string = "multi";
  @Input() disabled: boolean = false;
  @Input() noWidthLimit: boolean = false;
  @Output() change = new EventEmitter<any>();
  lookupData: any;
  public myOptions: IMultiSelectOption[] = [];

  onChange(event: any) {
    this.change.emit(event);
  }
  get selectedValue() {
    if((this.mode === "single-as-bool") && this._selectedValue != undefined && !Array.isArray(this._selectedValue)) {
      return this._selectedValue ? "1" : "0";
    }
    else if((this.mode === "single-as-bool") && Array.isArray(this._selectedValue)) {
      return null;
    }
    return this._selectedValue;
  }

  set selectedValue(val) {
    this._selectedValue = val;
    if((this.mode === "single-as-bool") && val != undefined && val.length > 0) {
      this._selectedValue = val[0] == "1"
    }
    else if((this.mode === "single-as-bool") && Array.isArray(this._selectedValue)) {
      this._selectedValue =  null;
    }
    else if((this.mode === "single") && val != undefined && val.length > 0) {
      this._selectedValue = val[0]
    }
    else if((this.mode === "single") && Array.isArray(this._selectedValue)) {
      this._selectedValue =  null;
    }
    this.propagateChange(this._selectedValue);
  }

  constructor(private readonly lookupService: LookupService) { }

  // Settings configuration
  mySettings: IMultiSelectSettings = {
    buttonClasses: 'btn btn-outline-secondary btn-sm',
    dynamicTitleMaxItems: 1
  };

  ngOnInit(): void {
    this.lookupService.getLookup(this.name, "").subscribe((results: any) => {
      if (results != null) {
        this.lookupData = results;
        let tmpArr: IMultiSelectOption[] = [];
        this.lookupData.forEach(function (lookupData: any) {
          tmpArr.push({ id: lookupData.value, name: lookupData.label })
        });
        this.myOptions = tmpArr
      }
    });
    if(this.disabled) {
      this.mySettings.dynamicTitleMaxItems = 100;
      this.mySettings.buttonClasses = this.mySettings.buttonClasses + ' disabled';
    }
    else if (!this.noWidthLimit) {
      this.mySettings.buttonClasses = this.mySettings.buttonClasses + ' btn-max-w-128';
    }
    if (this.mode == "single" || this.mode == "single-as-bool") {
      this.mySettings = Object.assign(this.mySettings, {
        selectionLimit: 1,
        autoUnselect: true,
        closeOnSelect: true,
        checkedStyle: undefined,
        showUncheckAll: true
      })
    }
    if (this.mode == "singlenn") {
      this.mySettings = Object.assign(this.mySettings, {
        selectionLimit: 1,
        minSelectionLimit: 1,
        autoUnselect: true,
        closeOnSelect: true,
        checkedStyle: undefined,
        showUncheckAll: false,
        selectAddedValues: true
      })
    }
  }

  writeValue(value: any) {
    this.selectedValue = value;
  }

  propagateChange = (_: any) => { };

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched() { }
  optionsModel: number[] = [];
}
