import {HttpClient} from "@angular/common/http";
import {Component, OnInit} from "@angular/core";
import {DialogService} from "@/_services/dialog.service";
import {environment} from "@envs/environment";
import {Router} from "@angular/router";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {NgWizardConfig, NgWizardService, TOOLBAR_POSITION} from "ng-wizard";
import {JsonCollectionResponse, JsonItemResponse} from "@/_models/response";
import { v4 as uuidv4 } from 'uuid';
import {forkJoin, Observable, of, shareReplay, timeout} from "rxjs";
import {catchError, tap} from "rxjs/operators";
import {cloneDeep} from "lodash";
import {IMultiSelectSettings} from "ngx-bootstrap-multiselect";

@Component({
  standalone: false,
  selector: 'report-create',
  providers: [],
  styleUrls: ['report-create.component.scss'],
  templateUrl: 'report-create.component.html',
})
export class ReportCreateComponent implements OnInit {
  public wizardConfig: NgWizardConfig = {
    toolbarSettings: {
      toolbarPosition: TOOLBAR_POSITION.none,
      showNextButton: false,
      showPreviousButton: false
    },
    anchorSettings: {
      removeDoneStepOnNavigateBack: true
    }
  };
  public reportName: any;
  public fakeData: any = [{}];
  public previewGridConfig: any = {
    _self: this,
    colDefs: []
  };
  public createLoader: boolean = false;
  http: HttpClient;
  public loadingDataLayers: boolean = true;
  public loadingReadyLayers: boolean = true;
  public selectedDataLayersCount: number = 0;
  public selectedColumns: any = [];
  public selectedDataLayers: any = [];
  public dataLayers: any = [];
  public isAnyLayerSelected: boolean = false;
  public assetLayer: any = {};
  public selectReadyLayersLookupSettings: IMultiSelectSettings = {
    buttonClasses: 'btn btn-outline-secondary btn-sm',
    dynamicTitleMaxItems: 1,
    selectionLimit: 1,
    minSelectionLimit: 1,
    autoUnselect: true,
    closeOnSelect: true,
    checkedStyle: undefined,
    showUncheckAll: false,
    selectAddedValues: true
  };
  public isFiltersValid: boolean = false;
  public showPrepareDataWarning: boolean = false;

  constructor(
    private readonly _http: HttpClient,
    public dialogService: DialogService,
    private readonly router: Router,
    private readonly ngWizardService: NgWizardService,
  ) {
    this.http = _http;
  }
  ngOnInit(): void {
    this.loadingDataLayers = true;
    forkJoin({
      dataLayers: this.http.get<JsonCollectionResponse<any>>(`${environment.apiUrl}/api/DataLayer/GetDataLayerTypes`).pipe(tap(result => {
        if (result.ok && result.items.length > 0) {
          result.items.forEach((dataLayerType: any) => {
            dataLayerType.guid = uuidv4();
            this.dataLayers = [...this.dataLayers, dataLayerType];
          });
        }
      })),
      assetLayer: this.http.get<JsonItemResponse<any>>(`${environment.apiUrl}/api/DataLayer/GetAssetColumns`).pipe(tap(result => {
        if (result.ok && result.item) {
          this.assetLayer = result.item;
        }
      }))
    }).subscribe(() => {
      this.loadingDataLayers = false;
    });
  }
  validateFilters() {
    this.isFiltersValid = true;
    this.dataLayers.forEach((dataLayer: any) => {
      if(dataLayer.selected) {
        dataLayer.availableFilters.forEach((filter: any) => {
          filter.invalid = filter.required && (filter.value == null || filter.value.length == 0);
          if(filter.invalid) {
            this.isFiltersValid = false;
          }
        })
      }
    })
  }
  onLookupChange(filter: any) {
    this.validateFilters();
  }
  showPreviousStep(event?: Event) {
    this.ngWizardService.previous();
  }
  showNextStep(event?: Event) {
    this.ngWizardService.next();
  }
  goToSelectFilters() {
    this.selectedDataLayersCount = this.dataLayers.filter((d:any) => d.selected).length;
    const requests: Observable<any>[] = [];
    this.loadingReadyLayers = true;
    this.ngWizardService.next();
    this.dataLayers.forEach((dataLayer: any) => {
      if (dataLayer.checkReady && dataLayer.selected) {
        requests.push(
        this.http.post<JsonCollectionResponse<any>>(`${environment.apiUrl}/api/DataLayer/GetReadyClimateDataByType`, {Code: dataLayer.code})
          .pipe(tap(result => {
          if (result.ok && result.items.length > 0) {
            dataLayer.readyLayers = result.items;
            dataLayer.ReadyLayerId = [result.items[0].id]
          }
          else {
            dataLayer.requestNew = true;
          }
        })));
      }
    });
    if(requests.length == 0) {
      this.loadingReadyLayers = false;
      this.validateFilters();
    }
    forkJoin(requests).subscribe(() => {
      this.loadingReadyLayers = false;
      this.validateFilters();
    })
  }
  goToSelectColumns() {
    this.ngWizardService.next();
    this.selectedDataLayers = []
    this.dataLayers.forEach((dataLayer: any) => {
      if(dataLayer.selected) {
        let passOrCompare = dataLayer.availableFilters?.find((filter: any) => {return filter.type == "pass_or_compare"})
        if (passOrCompare && passOrCompare.dateMode == "1") {
          let dataLayer1= {...dataLayer, name: dataLayer.name + ' Pass A', assetViewColumns: [], eventViewColumns: [], comparativeViewColumns: []};
          let dataLayer2= {...dataLayer, guid: uuidv4(), name: dataLayer.name + ' Pass B', assetViewColumns: [], eventViewColumns: [], comparativeViewColumns: []};
          let dataLayerCompare= {...dataLayer, guid: dataLayer2.guid, compareToGuid: dataLayer1.guid, name: dataLayer.name + ' Compare', assetViewColumns: [], eventViewColumns: []};
          dataLayer.assetViewColumns.forEach((assetViewColumn: any) => {
            dataLayer1.assetViewColumns.push({...assetViewColumn});
            dataLayer2.assetViewColumns.push({...assetViewColumn});
          })
          dataLayer2.availableFilters = cloneDeep(dataLayer.availableFilters);
          dataLayer2.availableFilters?.filter((filter: any) => {return filter.type == "pass_or_compare"}).forEach((filter: any) => {
            filter.value = filter.value2
            filter.group = filter.group2;
          });
          this.selectedDataLayers.push(dataLayer1);
          this.selectedDataLayers.push(dataLayer2);
          this.selectedDataLayers.push(dataLayerCompare);
        }
        else {
          this.selectedDataLayers.push({...dataLayer});
        }

      }
    })
    this.selectedColumns =
      [
        ...this.assetLayer.colDefs.map(
          (colDef: any) => {
            colDef.parentName = this.assetLayer.name;
            colDef.selected = true;
            return colDef;
          }
        ),
        ...this.selectedDataLayers.reduce((filtered: any, dataLayer: any) => {
          if (dataLayer.mode === "0" && dataLayer.assetViewColumns != null) {
            filtered = [...filtered, ...dataLayer.assetViewColumns.map(
                (colDef: any) => {
                  let colDefCopy = { ...colDef }
                  colDefCopy.parentName = dataLayer.name;
                  if(colDefCopy.code && !dataLayer.requestNew && dataLayer.ReadyLayerId) {
                    let readyLayer = dataLayer.readyLayers.find((layer: any) => layer.id == dataLayer.ReadyLayerId);
                    colDefCopy.title = readyLayer?.name + ' (' + colDefCopy.code + ')'
                  }
                  colDefCopy.selected = true;
                  return colDefCopy;
                })]
          }
          if (dataLayer.mode === "1" && dataLayer.eventViewColumns != null) {
            filtered = [...filtered, ...dataLayer.eventViewColumns.map(
              (colDef: any) => {
                let colDefCopy = { ...colDef }
                colDefCopy.parentName = dataLayer.name;
                if(colDefCopy.code && !dataLayer.requestNew && dataLayer.ReadyLayerId) {
                  let readyLayer = dataLayer.readyLayers.find((layer: any) => layer.id == dataLayer.ReadyLayerId);
                  colDefCopy.title = readyLayer?.name + ' (' + colDefCopy.code + ')'
                }
                colDefCopy.selected = true;
                return colDefCopy;
              }
            )]
          }
          if (dataLayer.compareToGuid != undefined && dataLayer.comparativeViewColumns != null) {
            filtered = [...filtered, ...dataLayer.comparativeViewColumns.map(
              (colDef: any) => {
                colDef.parentName = dataLayer.name;
                colDef.selected = true;
                return colDef;
              }
            )]
          }
          return filtered;
        }, [])
      ]
  }
  goToSummary(){
    this.showPrepareDataWarning = !!this.selectedDataLayers.find((dataLayer: any) => dataLayer.requestNew);
    this.previewGridConfig.colDefs = [];
    this.selectedColumns.forEach((column: any) => {
      if(column.selected) {
        this.previewGridConfig.colDefs.push({
          title: column.title + '<br/><small class="subtitle">(' + column.parentName + ')</small>',
          field: column.field,
        })
      }
    })
    this.ngWizardService.next();
  }
  updateLayerSelected(layer: any): void {
    let _self = this;
    setTimeout(function () {
      if(layer.selected && layer.mode == undefined) {
        layer.mode = "0";
      }
      if(!layer.selected) {
        layer.mode = undefined;
      }
      _self.isAnyLayerSelected = _self.dataLayers.find((dataLayer:any) => {return dataLayer.selected && (dataLayer.mode === "0" || dataLayer.mode === "1")})
    }, 100)
  }
  selectLayerMode(layerType: any): void {
    layerType.selected = true;
    this.updateLayerSelected(layerType);
  }
  toggleLayer(layerType:any) {
    layerType.selected = !layerType.selected;
    this.updateLayerSelected(layerType);
  }
  dropColumn(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.selectedColumns, event.previousIndex, event.currentIndex);
  }
  reduceFilters(filtered: any, filter: any) {
    if (filter.type == "pass_or_compare") {
      filtered.push({
        Field: filter.field + 'Group',
        Title: filter.title,
        Type: filter.type,
        Value: {Values: filter.group},
      })
    }
    if (filter.value != undefined) {
      filtered.push({
        Field: filter.field,
        Title: filter.title,
        Type: filter.type,
        Value: (filter.value.operator != undefined) ? filter.value : {Values: filter.value},
      })
    }
    return filtered;
  }
  reduceColumns(filtered: any, x: any)  {
    if(x.selected) {
      filtered.push( {
                       Name: x.field,
                       DisplayName: x.title + '<br/><small class="subtitle">(' + x.parentName + ')</small>',
                       Type: x.type,
                       Code: x.code,
                       Alias: "",
                       Hint: "",
                       Order: x.order,
                     })
    }
    return filtered;
  }
  create() {
    if(this.reportName == undefined || this.reportName == "") {
      this.dialogService.displayError("Please enter name of report");
      return;
    }
    let columnOrder = 1;
    this.selectedColumns.forEach((column: any) => {
      column.order = columnOrder
      columnOrder++;
    })
    let selectedDataLayers: DataLayerWithColumns[] = [];
    let index = this.assetLayer?.colDefs.findIndex((x: any) => { return x.selected; })
    if(index > -1) {
      selectedDataLayers.push({
        Id: this.assetLayer.id,
        Guid: "",
        CompareToId: undefined,
        CompareToGuid: undefined,
        Name: this.assetLayer.name,
        Schema: "",
        JoinType: "",
        EventLayer: false,
        DataLayerColumns:
          this.assetLayer?.colDefs.reduce((filtered: any, x: any) => {
            if (x.selected) {
              filtered.push({
                Name: x.field,
                DisplayName: x.title,
                Type: x.type,
                Code: x.code,
                Alias: "",
                Hint: "",
                Order: x.order,
              })
            }
            return filtered;
          }, [])
      });
    }
    let createDataLayerRequests: CreateDataLayerRequest[] = [];
    this.selectedDataLayers.forEach((dataLayer: any) => {
      if(dataLayer.compareToGuid == undefined) {
        let mergeRadius = dataLayer.availableFilters?.find((filter: any) => filter.field == "mergeRadius");
        let createDataLayerRequest = {
          Name: dataLayer.name,
          Guid: dataLayer.guid,
          EventLayer: dataLayer.mode === "1",
          MergeDistance: null,
          DataLayerTypeId: dataLayer.id,
          IsManual: dataLayer.requestNew,
          ReadyLayer: false,
          ReadyLayerId: null,
          SelectedLayers: [
            {
              Id: 0,
              Guid: dataLayer.guid,
              Name: dataLayer.name,
              Date: undefined,
              GroupId: undefined,
              Columns: [],
              Table: "",
              Filters: dataLayer.availableFilters?.reduce(this.reduceFilters,[]),
            }
          ]
        }
        if(mergeRadius && mergeRadius.value.length > 0) {
          createDataLayerRequest.MergeDistance = mergeRadius.value[0];
        }
        if(dataLayer.ReadyLayerId && !dataLayer.requestNew && dataLayer.ReadyLayerId.length > 0) {
          createDataLayerRequest.ReadyLayerId = dataLayer.ReadyLayerId[0];
          createDataLayerRequest.ReadyLayer = true;
          createDataLayerRequest.SelectedLayers[0].Filters = [];
          if(dataLayer.readyFilters) {
            createDataLayerRequest.SelectedLayers[0].Filters = dataLayer.readyFilters?.reduce(this.reduceFilters,[]);
          }

        }
        createDataLayerRequests.push(createDataLayerRequest)
      }
      let dataLayerColumns: any[] = this.selectedColumns.filter((column: any) => { return column.parentName == dataLayer.name }).reduce(this.reduceColumns,[])

      selectedDataLayers.push({
        Id: 0,
        Guid: dataLayer.guid,
        CompareToId: undefined,
        CompareToGuid: dataLayer.compareToGuid,
        Name: dataLayer.name,
        Schema: "",
        JoinType: "",
        EventLayer: dataLayer.mode === "1",
        DataLayerColumns: dataLayerColumns
      })
    })
    let params = {
      CreateReportViewRequest: {
        Name: this.reportName,
        SelectedDataLayers: selectedDataLayers
      },
      CreateDataLayerRequest: createDataLayerRequests
    };
    if (!this.createLoader) {
      this.createLoader = true;
      this.http.post<any>(`${environment.apiUrl}/api/Report/FullCreateReport/`, params)
        .pipe(
          shareReplay(1), // Ensures the request is not canceled
          catchError((error) => {
            console.error('Request failed:', error);
            return of(null); // Suppress errors
          })
        )
        .pipe(
          timeout(5000), // Terminate the subscription if it exceeds the timeout 5s
          catchError((error) => {
            if (error.name === 'TimeoutError') {
              this.router.navigate(["/reports"]);
            }
            return of(null);
          })
        )
        .subscribe(result => {
        if (result.ok) {
          this.router.navigate(["/reports"]);
        }
        else {
          this.dialogService.displayErrorMessages(result.messages);
        }
        this.createLoader = false;
      });
    }
  }
  close() {
    this.router.navigate(["/report/list"]);
  }
}

class DataLayerWithColumns {
  Id: number | undefined;
  Guid: string = "";
  CompareToId: number | undefined;
  CompareToGuid: number | undefined;
  Name: string = "";
  Schema: string = "";
  JoinType: string = "";
  EventLayer: boolean = false;
  DataLayerColumns: DataLayerColumn[] = [];
}

class DataLayerColumn {
  Name: string = "";
  DisplayName: string = "";
  Type: string = "";
  Code: string = "";
  Alias: string = "";
  Hint: string = "";
  Order: number = 0;
}
class SingleDataLayerInfo {
  Id: number | undefined;
  Guid: string = "";
  Name: string = "";
  Table: string = "";
  Date: any = { operator: 11, valuePrimary: null, valueSecondary: null };
  GroupId: number | undefined;
  Columns: string[] = [];
  Filters: GenericFilterDefinition[] = [];
}

class GenericFilterDefinition {
  Field: string = "";
  Type: string = "";
  Title: string = "";
  Value: any = { operator: null, valuePrimary: null, valueSecondary: null };
}
class  CreateDataLayerRequest {
  Name: string = "";
  Guid: string = "";
  EventLayer: boolean = false;
  SelectedLayers: SingleDataLayerInfo[] = [];
}
