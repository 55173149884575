<h1 mat-dialog-title>Import statelite raster</h1>
<mat-dialog-content>
  <dashboard-upload (onFileUploaded)="onFileUploaded($event[0], $event[1])"
                    (onFileProgress)="onFileProgress($event[0], $event[1], $event[2])"
                    (onCancelled)="onCancelled($event[0])"
                    (onError)="onError($event[0], $event[1])"
                    (onRemoved)="onRemoved($event[0], $event[1])"></dashboard-upload>
  <mat-form-field appearance="outline">
    <mat-label>Name</mat-label>
    <input matInput [(ngModel)]="analysisName" required>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Date</mat-label>
    <input matInput type="text"  [(ngModel)]="date" placeholder="yyyy-mm-dd" class="input-date form-control form-control-sm" bsDatepicker>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close>Cancel</button>
  <button mat-stroked-button [disabled]="!enableImport" (click)="import()" cdkFocusInitial>Import</button>
</mat-dialog-actions>
<div class="loader-container" *ngIf="loading">
  <div class="loader-contents">
    <mat-spinner></mat-spinner>
  </div>
</div>
