export class MapLayerCategory {
  public name: string | undefined;
  public outlineColor: string = "#FFFFFF";
  public color: string = "#FFFFFF";
  public expression: string | undefined;
  public outlineWidth: number = 1;
  public pxlval: number | undefined;
  public geometryType: string | undefined;
  public dataRangeStart?: number;
  public dataRangeEnd?: number;
  public colorRangeStart?: string;
  public colorRangeEnd?: string;
}
