import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { ResourcesService } from "@/_services/resources.service";
import { MatExpansionPanel } from '@angular/material/expansion';
import { Observable, Subject, Subscription, takeUntil } from "rxjs";
import { MatDialog } from '@angular/material/dialog';
import { GridConfigDialog } from '../grid-config-dialog/grid-config-dialog.component';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { filter  as lodashFilter } from "lodash" ;

@Component({
  selector: 'app-gridext',
  templateUrl: './gridext.component.html',
  styleUrls: ['./gridext.component.scss']
})
export class GridExtComponent {
  @ViewChild(MatExpansionPanel, { static: false }) expansionPanel!: MatExpansionPanel;
  @Input() data: any;
  @Input() config: GridConfig | undefined;
  @Input() service: ResourcesService | undefined;
  @Input() loadData: boolean = true;
  @Input() parentId: number | undefined;
  @Output() filterEvent = new EventEmitter<any>();
  public sort: SortConfig | undefined;
  public page: number;
  public count: number;
  public totalCount: number;
  public pageNumber: number;
  public rangeOffset: number;
  public range: number;
  private _filter: any;
  public dataNotFound: boolean;
  public loading: boolean;
  public numberOfGroups: number | undefined;
  public selectedGroup: any = "climate-conditions";
  private unsubscribe$: Subject<void> = new Subject();
  public selectedItemsList: any[];
  public allSelected: boolean;
  private gridSettingsLoaded: boolean;
  public lodashFilter = lodashFilter;

  constructor(private dialog: MatDialog, private httpClient: HttpClient) {
    this.page = 0;
    this.count = 15;
    this.totalCount = 0;
    this.pageNumber = 1;
    this.rangeOffset = 0;
    this.range = 5;
    this.dataNotFound = false;
    this.loading = false;
    this.selectedItemsList = [];
    this.allSelected = false;
    this.gridSettingsLoaded = false;
  }

  @Input() set filter(value: any) {
    this._filter = value;
    this.page = 0;
    this.filterEvent.emit(this._filter);
    if (this._filter !== undefined && this.loadData) {
      this.refreshGrid();
    }
  }
  changeSelection() {
    this.fetchSelectedItems()
  }
  selectAllChange() {
    if (this.allSelected) {
      this.data.forEach((item: any, index: number) => {
        item.selected = true;
      })
    }
    else {
      this.data.forEach((item: any, index: number) => {
        item.selected = false;
      })
    }
    this.fetchSelectedItems()
  }
  fetchSelectedItems() {
    this.selectedItemsList = this.data.filter((value: any, index: number) => {
      return value.selected
    });
    if (this.selectedItemsList.length == this.data.length) {
      this.allSelected = true;
    }
    else {
      this.allSelected = false;
    }
  }
  sortColumn(sortColumn: string): void {
    if (sortColumn != undefined && sortColumn != "") {
      if (this.sort == undefined || this.sort.columnName == "" || this.sort.columnName != sortColumn) {
        this.sort = {
          columnName: sortColumn,
          order: "desc"
        }
      } else if (this.sort.columnName == sortColumn && this.sort.order == "desc") {
        this.sort.order = "asc"
      } else if (this.sort.columnName == sortColumn && this.sort.order == "asc") {
        delete this.sort.columnName;
      }
      this.refreshGrid();
    }
  }
  clearSort(): void {
    if (this.sort != undefined && this.sort.columnName != undefined) {
      delete this.sort.columnName;
    }
  }
  refreshPagination(): void {
    if (this.pageNumber > 5) {
      this.range = 5;
    }
    else {
      this.range = this.pageNumber;
    }
    if (this.page < 5) {
      this.rangeOffset = -this.page;
    }
    else if (this.pageNumber - this.page < 3) {
      this.rangeOffset = -4 + (this.pageNumber - (this.page + 1));
    }
    else {
      this.rangeOffset = -2;
    }
  }
  refreshGrid(): void {
    this.loading = true;
    this.refreshPagination();
    this.data = [];
    this.numberOfGroups = this.config?.filters?.filter(el => el.type == 'group').length;
    if (this.service != undefined && this.loadData) {
      this.service.getList(this.page, this.count, this._filter, this.sort, this.parentId).pipe(takeUntil(this.unsubscribe$)).subscribe({
        next: (result) => {
          this.data = result.items;
          this.loading = false;
          if (result.items != null) {
            if (result.items.length == 0) {
              this.dataNotFound = true;
            }
            if (result.items.length > 0) {
              this.dataNotFound = false;
            }
          }
          this.totalCount = result.totalItemCount;
          this.pageNumber = Math.ceil(this.totalCount / this.count);
          this.refreshPagination();
          this.fetchSelectedItems()
        },
        error: (error) => console.error(error)
      });
    }
  }

  async unsubscribeService(): Promise<void> {
    this.unsubscribe$.next();
  }

  goToPage(num: number, reload: boolean = true) {
    this.page = num;
    if (reload) {
      this.refreshGrid();
    }
  }

  nextPage() {
    if ((this.page + 1) < this.pageNumber) {
      this.page += 1;
      this.refreshGrid();
    }
  }

  prevPage() {
    if (this.page > 0) {
      this.page -= 1;
      this.refreshGrid();
    }
  }

  runAction(column: GridColDef, item: any) {
    let args = column.paramFields.map((element) => { return item[element] });
    let itemArray = new Array<any>();
    itemArray.push(item)
    if (typeof column.action === 'function') {
      column.action.apply(this.config?._self, itemArray.concat(args))
    }
    else {
      this.config?._self[column.action].apply(this.config?._self, itemArray.concat(args))
    }
  }

  public openGridConfig(): void {
    const dialogRef = this.dialog.open(GridConfigDialog, {
      data: this.config,
      width: '30vw',
      height: '80vh',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      //this.refreshGrid();
    });
  }
}

interface GridConfig {
  _self: any,
  title: string | undefined;
  colDefs: GridColDef[] | undefined;
  filters: any[] | undefined;
  selectable: boolean | undefined;
  selectActions: any[] | undefined;
  customiseParams: CustomConfigParams | undefined;
}

interface SortConfig {
  columnName?: string,
  order: string
}

interface CustomConfigParams {
  apiUrl: string,
  column: string,
  id: any,
}

interface GridColDef {
  title: string,
  originalTitle: string,
  field: string,
  sortColumn: string,
  type: string,
  btnText: string,
  btnIconName: string,
  btnTooltip: string,
  visible: boolean,
  editable: boolean,
  action: any,
  paramFields: Array<string>
}
