import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User } from '@/_models';
import {catchError} from "rxjs/operators";
import {environment} from "@envs/environment";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {MapLayer} from "@/map/models";

@Injectable({ providedIn: 'root' })
export class CompanyConfigService {
  private readonly apiUrl = '/api/company/'
  private _configStore: BehaviorSubject<any> = new BehaviorSubject({});
  public configStore: Observable<any> = this._configStore.asObservable();
  public configLoaded = false;
  constructor(private http: HttpClient) { }
  initialize() {
    this.configLoaded = false;
  }
  loadCompanyConfig() {
    this.configLoaded = false;
    this.http.get<any[]>(`${this.apiUrl + "config"}`).subscribe({
      next: (result: any) => {
        if(result.ok) {
          this.configLoaded = true;
          this._configStore.next(result.item);
        }
      },
      error: (error: Error) => console.error(error)
    });
  }
  clear() {
    this._configStore = new BehaviorSubject({});
    this.configStore = this._configStore.asObservable();
  }
}
