import { Component, Input } from "@angular/core";
import {environment} from "@envs/environment";
import {HttpClient} from "@angular/common/http";
import {ResourcesService} from "@/_services/resources.service";
import {AnalysisDataService} from "@/_services/analysis-data.service";

export interface AnalysisMetrics {
  latestAnalysisDate: Date;
  analysesDone: number;
  inspectedArea: number;
  networkLength: number;
  nextAnalysisDate: Date;
}

@Component({
  standalone: false,
  selector: 'analysis-metrics',
  providers: [
    {
      provide: ResourcesService,
      useClass: AnalysisDataService
    }
  ],
  templateUrl: './analysis-metrics.component.html',
  styleUrls: ['./analysis-metrics.component.scss']
})
export class AnalysisMetricsComponent {
  @Input() public data: AnalysisMetrics;
  private http: HttpClient;
  public loading: boolean = true;
  private _filter: any = {};
  public service: ResourcesService;
  @Input() set filter(value: any) {
    if(this._filter != value && value != undefined) {
      this._filter = value;
      this.refreshMetrics();
    }
  }

  constructor(httpClient: HttpClient, _resourceService: ResourcesService) {
    this.data = {
      latestAnalysisDate: new Date(),
      analysesDone: 0,
      inspectedArea: 0,
      networkLength: 0,
      nextAnalysisDate: new Date()
    };
    this.http = httpClient;
    this.service = _resourceService;
  }

  refreshMetrics(): void {
    this.loading= true;
    this.service.getMetrics(this._filter, {
      "columnName": "analysisDate",
      "sortDirectionText": "desc"
    })
      .subscribe({
        next: (result: any) => {
          if (result?.item != null) {
            this.data = { ...this.data, ...result?.item };
          }
          this.loading= false;
        },
        error: (error: Error) => console.error(error)
      });
  }
}
