import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@envs/environment';
import { User } from '@/_models';
import {RouteReuseStrategy} from "@angular/router";
import {CustomRouteReuseStrategy} from "@/_helpers/custom.route.reuse.strategy";
import {MapLayerService} from "@/_services/map-layer.service";
import {CompanyConfigService} from "@/_services/company.config";
import {LookupService} from "@/_services/lookup.service";

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private session: Subject<void> = new Subject<void>();
  public clearSession: Observable<void>;

  constructor(private http: HttpClient, private reuseStrategy: RouteReuseStrategy, private _mapLayerService: MapLayerService,
              private _companyConfigService: CompanyConfigService, private _lookupService: LookupService) {
    // @ts-ignore
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.clearSession = this.session.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(username: any, password: any) {
    return this.http.post<any>(`${environment.apiUrl}/api/authenticate/login`, { username, password })
      .pipe(map(user => {
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        (this.reuseStrategy as CustomRouteReuseStrategy).clear();
        return user;
      }));
  }

  forgotPassword(username: any) {
    return this.http.post<any>(`${environment.apiUrl}/api/authenticate/forgot-password`, { username });
  }

  resetPassword(password: any, token: any, username: any) {
    return this.http.post<any>(`${environment.apiUrl}/api/authenticate/reset-password`, { password, token, username });
  }

  logout() {
    localStorage.removeItem('currentUser');
    (this.reuseStrategy as CustomRouteReuseStrategy).clear();
    this._mapLayerService.clear();
    this._companyConfigService.clear();
    this._lookupService.clearLookupStore();
    this.session.next();
    // @ts-ignore
    this.currentUserSubject.next(null);
  }
}
