import { HttpClient } from "@angular/common/http";
import {Component, OnInit, ViewChild} from "@angular/core";
import { DialogService } from "@/_services/dialog.service";
import { environment } from "@envs/environment";
import { Router } from "@angular/router";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ReportsLookupsService } from "@/_services/reports-lookups.service";
import {NgSelectComponent} from "@ng-select/ng-select";

@Component({
  standalone: false,
  selector: 'report-create',
  providers: [
    {
      provide: ReportsLookupsService
    }
  ],
  styleUrls: ['report-create.component.scss'],
  templateUrl: 'report-create.component.html',
})
export class ReportCreateComponent implements OnInit {
  public reportForm: FormGroup;
  public reportName: any;
  public fakeData: any = [{}];
  public previewGridConfig: any = {
    _self: this,
    colDefs: []
  };
  public createLoader: boolean = false;
  public compareLoader: boolean = false;
  http: HttpClient;
  public selectedDimension: any;
  public selectedColumns: any = [];
  public showHint: boolean = true;
  public hintText: string | undefined;
  private readonly _reportsLookupsService: ReportsLookupsService;
  public dimensions = [
    {
      type: "asset",
      label: "Asset IDs",
      id: "assetId"
    }
  ]
  public dataLayers = [
    {
      name: "Asset attributes",
      visible: true,
      id: 'assetAttrs',
      hint: "Asset attributes are the static asset information uploaded in the system. Please select those you want to display in your report.",
      colDefs: [
        {
          title: "Asset Name",
          field: "assetName",
          selected: false,
          visible: true,
          active: false
        }],
      compareToId: null
    }
  ];
  public dataLayersToCompare: any;
  @ViewChild('compareSelect') private compareSelect!: NgSelectComponent;

  constructor(
    private readonly _http: HttpClient,
    public dialogService: DialogService,
    private readonly router: Router,
    private readonly fb: FormBuilder,
    _reportsLookupsService: ReportsLookupsService
  ) {
    this.http = _http;
    this.showHint = false;
    this.reportForm = this.fb.group({
      columns: [null, null],
    });
    this.reportForm.controls["columns"].disable();
    this._reportsLookupsService = _reportsLookupsService;
  }

  ngOnInit(): void {
    this._reportsLookupsService.getList().subscribe(result => {
      if (result.ok) {
        result.items.forEach((item: any) => {
          if (item.id > 0) {
            this.dimensions = [...this.dimensions, {
              id: item.id,
              type: 'event',
              label: 'Events - ' + item.name
            }];
          }
        })
      }
    });
  }
  onDimensionChange($event: any) {
    if ($event.type == "asset") {
      this._reportsLookupsService.getList().subscribe(result => {
        if (result.ok) {
          this.dataLayers = result.items;
        }
      });
    }
    if ($event.type == "event") {
      this._reportsLookupsService.getList(null, this.selectedDimension.id).subscribe(result => {
        if (result.ok) {
          this.dataLayers = result.items;
        }
      });
    }
    this.reportForm.controls["columns"].enable();
  }
  onDimensionClear($event: any) {
    this.reportForm.controls["columns"].disable();
  }
  selectColumn(column: any, parent: any) {
    column.selected = !column.selected;
    if (!this.selectedColumns.find((x: any) => { return x.title == column.title && x.parentId == parent.id && x.parentCompareToId == parent.compareToId })) {
      column.parentId = parent.id
      column.parentCompareToId = parent.compareToId;
      column.parentName = parent.name;
      this.selectedColumns.push(column)
    }
    if (!this.previewGridConfig.colDefs.find((x: any) => { return x.id == column.field + column.parentId + column.parentCompareToId })) {
      this.previewGridConfig.colDefs.push({
        id: column.field + column.parentId + column.parentCompareToId,
        title: column.title + '<br/><small class="subtitle">(' + column.parentName + ')</small>',
        field: column.field,
      })
    }
  }
  removeColumn(column: any) {
    let index = this.selectedColumns.findIndex((x: any) => { return x.title == column.title && x.parentId == column.parentId && x.parentCompareToId == column.parentCompareToId })
    column.selected = false;
    if (index > -1) {
      this.selectedColumns.splice(index, 1);
    }
    index = this.previewGridConfig.colDefs.findIndex((x: any) => { return x.id == column.field + column.parentId + column.parentCompareToId })
    if (index > -1) {
      this.previewGridConfig.colDefs.splice(index, 1);
    }
  }
  dropColumn(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.selectedColumns, event.previousIndex, event.currentIndex);
    moveItemInArray(this.previewGridConfig.colDefs, event.previousIndex, event.currentIndex);
  }
  onColumnSelectClose() {
    this.showHint = false;
    this.deactivateAllColumns()
  }
  deactivateAllColumns() {
    this.dataLayers.forEach((dataLayer) => {
      dataLayer.colDefs.forEach((column) => {
        column.active = false;
      });
    })
  }
  onMouseEnterColumnOption(column: any) {
    this.deactivateAllColumns()
    if (column.hint) {
      this.hintText = column.hint;
      this.showHint = true;
      column.active = true;
    }
    else {
      this.showHint = false;
    }
  }
  onMouseEnterColumnGroup(columnGroup: any) {
    this.deactivateAllColumns()
    if (columnGroup.hint) {
      this.hintText = columnGroup.hint;
      this.showHint = true;
    }
    else {
      this.showHint = false;
    }
  }
  customSearchFn(term: string, item: {
    label: string,
    id: string
  }) {
    term = term.toLowerCase();
    return item.label.toLowerCase().indexOf(term) > -1;
  }
  customColumnsSearchFn(term: string, item: {
    title: string,
    field: string
  }) {
    term = term.toLowerCase();
    return item.title.toLowerCase().indexOf(term) > -1;
  }
  onColumnSearch($event: any) {
    let searchTerm = $event.term.toLowerCase();
    this.dataLayers.forEach((dataLayer) => {
      dataLayer.visible = dataLayer.name.toLowerCase().indexOf(searchTerm) > -1;
      dataLayer.colDefs.forEach((column) => {
        if (column.title.toLowerCase().indexOf(searchTerm) > -1 && !column.selected) {
          column.visible = true;
          dataLayer.visible = true;
        }
        else {
          column.visible = false;
        }
      });
    })
  }
  create() {
    let selectedDataLayers: DataLayerWithColumns[] = [];
    let columnOrder = 1;
    this.selectedColumns.forEach((column: any) => {
      let index = selectedDataLayers.findIndex((x: any) => { return x.Id == column.parentId && x.CompareToId == column.parentCompareToId });
      if (index > -1) {
        selectedDataLayers[index].DataLayerColumns.push({
          Name: column.field,
          DisplayName: column.title,
          Type: column.type,
          Code: column.code,
          Alias: "",
          Hint: "",
          Order: columnOrder
        })
      } else {
        selectedDataLayers.push({
          Id: column.parentId,
          CompareToId: column.parentCompareToId,
          Name: "",
          Schema: "",
          JoinType: "",
          EventLayer: false,
          DataLayerColumns: [{
            Name: column.field,
            DisplayName: column.title,
            Type: column.type,
            Code: column.code,
            Alias: "",
            Hint: "",
            Order: columnOrder
          }]
        })
      }
      if(column.parentCompareToId != undefined) {
        index = selectedDataLayers.findIndex((x: any) => { return x.Id == column.parentId && x.CompareToId == undefined });
        if (index == -1) {
          selectedDataLayers.push({
            Id: column.parentId,
            CompareToId: undefined,
            Name: "",
            Schema: "",
            JoinType: "",
            EventLayer: false,
            DataLayerColumns: []
          })
        }
        index = selectedDataLayers.findIndex((x: any) => { return x.Id == column.parentCompareToId && x.CompareToId == undefined });
        if (index == -1) {
          selectedDataLayers.push({
            Id: column.parentCompareToId,
            CompareToId: undefined,
            Name: "",
            Schema: "",
            JoinType: "",
            EventLayer: false,
            DataLayerColumns: []
          })
        }
      }

      columnOrder++;
    })

    if (this.selectedDimension.id > 0 && this.selectedDimension.type == 'event') {
      let index = selectedDataLayers.findIndex((x: any) => { return x.Id == this.selectedDimension.id })
      if (index > -1) {
        selectedDataLayers[index].EventLayer = true;
      }
      else {
        selectedDataLayers.push({
          Id: this.selectedDimension.id,
          CompareToId: undefined,
          Name: this.selectedDimension.name,
          Schema: "",
          JoinType: "",
          EventLayer: true,
          DataLayerColumns: []
        })
      }
    }

    let params = {
      Name: this.reportName,
      SelectedDataLayers: selectedDataLayers,
      ReportDimension: (this.selectedDimension.type == 'event') ? 1 : 0
    };

    let closeme = true;

    if (!this.createLoader) {
      this.createLoader = true;
      this.http.post<any>(`${environment.apiUrl}/api/Report/SaveAsReportView/`, params).subscribe(result => {
        if (result.ok) {
          this.router.navigate(["/reports"]);
        }
        else {
          this.dialogService.displayErrorMessages(result.messages);
        }
        this.createLoader = false;
      });
    }
  }
  close() {
    this.router.navigate(["/report/list"]);
  }
  getCompareColumns() {
    this.compareLoader = true
    this.http.post<any>(`${environment.apiUrl}/api/DataLayer/GetComparativeViewModelForLayers/?layer1Id=${this.dataLayersToCompare[0].id}&layer2Id=${this.dataLayersToCompare[1].id}`, {}).subscribe(result => {
      if (result.ok) {
        this.dataLayers = [result.item, ...this.dataLayers];
        this.compareSelect.open();
      }
      this.compareLoader = false;
    });
    this.dataLayersToCompare = [];
  }
}

class DataLayerWithColumns {
  Id: number | undefined;
  CompareToId: number | undefined;
  Name: string = "";
  Schema: string = "";
  JoinType: string = "";
  EventLayer: boolean = false;
  DataLayerColumns: DataLayerColumn[] = [];
}

class DataLayerColumn {
  Name: string = "";
  DisplayName: string = "";
  Type: string = "";
  Code: string = "";
  Alias: string = "";
  Hint: string = "";
  Order: number = 0;
}
