export const defaultReportAssetViewGridConfigColDef = [
  {
    title: '',
    originalTitle: 'Show on Map',
    type: 'action',
    btnText: '',
    btnIconName: 'map',
    action: 'showOnMap',
    paramFields: ['id'],
    editable: true,
    visible: true,
  },
  {
    title: "Asset name",
    originalTitle: "Asset name",
    field: "name",
    sortColumn: "AssetName",
    editable: true,
    visible: true,
  },
  {
    title: "Event count",
    originalTitle: "Event count",
    field: "incidentCount",
    sortColumn: "IncidentCount",
    editable: true,
    visible: true,
  },
  {
    title: "Minimal horizontal distance to asset [m]",
    originalTitle: "Minimal horizontal distance to asset [m]",
    field: "minimalDistanceToAsset",
    sortColumn: "MinimalDistanceToAsset",
    editable: true,
    visible: true,
  },
  {
    title: "Minimal absolute distance to asset [m]",
    originalTitle: "Minimal absolute distance to asset [m]",
    field: "minimalDistance3DToAsset",
    sortColumn: "MinimalDistance3DToAsset",
    editable: true,
    visible: true,
  },
  {
    title: "Estimated volume [m<sup>3</sup>]",
    originalTitle: "Estimated volume [m<sup>3</sup>]",
    field: "estimatedVolumeImpacted",
    sortColumn: "EstimatedVolumeImpacted",
    editable: true,
    visible: true,
  },
  {
    title: "Estimated area [m<sup>2</sup>]",
    originalTitle: "Estimated area [m<sup>2</sup>]",
    field: "estimatedAreaImpacted",
    sortColumn: "EstimatedAreaImpacted",
    editable: true,
    visible: true,
  },
  {
    title: "Estimated length asset impacted [m]",
    originalTitle: "Estimated length asset impacted [m]",
    field: "estimatedAssetLengthImpacted",
    sortColumn: "EstimatedAssetLengthImpacted",
    editable: true,
    visible: true,
  },
  {
    title: "Estimated  asset length impacted [%]",
    originalTitle: "Estimated  asset length impacted [%]",
    field: "estimatedAssetPercentageImpacted",
    sortColumn: "EstimatedAssetPercentageImpacted",
    editable: true,
    visible: true,
  },
  {
    title: "Total length of asset [m]",
    originalTitle: "Total length of asset [m]",
    field: "assetLength",
    sortColumn: "AssetLength",
    editable: true,
    visible: true,
  },
  {
    title: "Estimated production capacity [kW]",
    originalTitle: "Estimated production capacity [kW]",
    field: "estimatedSolarProductionCapacity",
    sortColumn: "EstimatedSolarProductionCapacity",
    editable: true,
    visible: false,
  },
  {
    title: "Asset type",
    originalTitle: "Asset type",
    field: "assetType",
    sortColumn: "AssetType",
    editable: true,
    visible: false,
  },
  {
    title: "Asset Category",
    field: "assetCategory",
    originalTitle: "Asset Category",
    sortColumn: "AssetCategory",
    editable: true,
    visible: false,
  },
  {
    title: "Elevation",
    field: "elevation",
    originalTitle: "Elevation",
    sortColumn: "Elevation",
    editable: true,
    visible: false,
  },
  {
    title: "Asset Location",
    field: "assetLocation",
    originalTitle: "Asset Location",
    sortColumn: "AssetLocation",
    editable: true,
    visible: false,
  },
  {
    title: "Location subgroup",
    field: "assetSublocation",
    originalTitle: "Location subgroup",
    sortColumn: "AssetSublocation",
    editable: true,
    visible: false,
  },
  {
    title: "Start Point",
    field: "startName",
    originalTitle: "Start Point",
    sortColumn: "StartName",
    editable: true,
    visible: false,
  },
  {
    title: "End Point",
    field: "endName",
    originalTitle: "End Point",
    sortColumn: "EndName",
    editable: true,
    visible: false,
  }

];
