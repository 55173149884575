import { HttpClient } from "@angular/common/http";
import { Component, ViewChild } from "@angular/core";
import { MatTabGroup } from "@angular/material/tabs";
import { DialogService } from "../_services/dialog.service";
import { JsonCollectionResponse } from "../_models/response";
import { environment } from "../../environments/environment";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { VariableDetailsDialogComponent } from "../_components/variable-details-dialog";

export class Variable {
  id: number | undefined;
  name: string | undefined;
  description: string | undefined;
  incidentFilter: string | undefined;
  whereString: string | undefined;
  selected: boolean = false;
  distance: number | undefined;
}

@Component({
  selector: 'create-report',
  styleUrls: ['create-report.component.scss'],
  templateUrl: 'create-report.component.html',
})
export class CreateReportComponent {
  public config: any;
  public filter: any;
  public reportName: any;
  public reportNamePrefix: any;
  public dataDate: any = { operator: 11, valuePrimary: null, valueSecondary: null };
  public dataDate2: any = { operator: 11, valuePrimary: null, valueSecondary: null };
  public groupId: any;
  public groupId2: any;

  public showCustomVariable: boolean = false;
  public deliverComparativeView: boolean = false;
  public variables: any;
  public mergeR: any = ["0.5"];

  public allTrees: any;
  public createLoader: boolean = false;
  public reportMode: number = 0;
  public selectedVariable: any;

  public assetFilter: any;
  public assetFilterValues: any;

  http: HttpClient;

  @ViewChild("createRepotTabs", { static: false }) createRepotTabs: MatTabGroup | undefined;

  constructor(
    private _http: HttpClient,
    public dialogService: DialogService,
    private router: Router,
    private dialog: MatDialog,
  ) {
    this.http = _http;

    let params = {
      "operationType": "SEARCH",
      "startRow": 0,
      "pageSize": 0,
      "updateCount": true,
      "sort": null,
      "filter": null
    }
    this.assetFilter = [
      {
        field: "name",
        title: "Asset Name",
        type: "string",
      },
      {
        title: "Asset type",
        field: "assetType",
        type: "string",
      },
      {
        field: "assetCategory",
        title: "Asset Category",
        type: "string",
      },
      {
        title: "Elevation",
        field: "elevation",
        type: "number",
      },
      {
        field: "assetLocation",
        title: "Asset Location",
        type: "string",
      },
      {
        field: "assetSublocation",
        title: "Location subgroup",
        type: "string",
      },
      {
        title: "Start Point",
        field: "startName",
        type: "string",
      },
      {
        title: "End Point",
        field: "endName",
        type: "string",
      },
    ];

    this.http.post<JsonCollectionResponse<Variable>>(`${environment.apiUrl}/api/Variable/GetNonPagedList`, params).subscribe(result => {
      if (result.ok) {
        this.variables = result.items;
      }
    });
  }

  setView(value: number) {
    this.reportMode = value;
  }

  setVariable(value: any) {
    this.selectedVariable = value;
  }
  create() {
    if (this.selectedVariable) {
      this.selectedVariable.selected = true;
    }
    this.assetFilterValues = {};
    for (var filter of this.assetFilter) {
      if (filter?.value != undefined && filter.value != '') {
        this.assetFilterValues[filter.field] = filter.value;
      }
    }

    var params = {
      Name: this.reportName,
      Filter: this.filter,
      AssetFilter: this.assetFilterValues,
      Variables: [this.selectedVariable],
      ReportMode: this.reportMode,
      MergeDistance: this.mergeR[0],
      Date1: this.dataDate,
      Date2: this.dataDate2,
      GroupId: null,
      GroupId2: null,
    };

    if (this.groupId) {
      params.GroupId = this.groupId[0];
    }
    if (this.groupId2) {
      params.GroupId2 = this.groupId2[0];
    }

    var closeme = true;

    if (!this.createLoader) {
      this.createLoader = true;
      this.http.post<any>(`${environment.apiUrl}/api/Report/SaveAsReport/`, params).subscribe(result => {
        if (result.ok) {
        }
        else {
          this.dialogService.displayErrorMessages(result.messages);
          closeme = false;
          this.createLoader = false;
        }
      });
      setTimeout(() => {
        if (closeme) {
          this.createLoader = false;
          this.router.navigate(["/reports"]);
        }
      }, 5000);
    }
  }

  close() {
    this.router.navigate(["/reports"]);
  }

  nextTab() {
    const tabGroup = this.createRepotTabs;
    if (tabGroup != undefined && tabGroup != null && tabGroup instanceof MatTabGroup) {
      // @ts-ignore
      tabGroup.selectedIndex = tabGroup.selectedIndex + 1;
    }
  }

  openDetails(item: any) {
    this.dialog.open(VariableDetailsDialogComponent, {
      data: item
    });
  }

  onNoClick(): void {
    //this.dialogRef.close();
  }
}
