import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent } from '../_components/info-dialog/info-dialog.component';
import { JsonMessage } from '../_models/response';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private dialog: MatDialog) {
  }

  displayErrors(messages: string[]) {
    this.dialog.open(InfoDialogComponent, {
      data: {
        icon: 'error',
        messages: messages
      }
    });
  }

  displayError(message: string) {
    var messages: string[] = [message];

    this.dialog.open(InfoDialogComponent, {
      data: {
        icon: 'error',
        messages: messages
      }
    });
  }

  displayErrorMessages(jsonMessages: JsonMessage[]) {
    var messages: string[] = [];
    for (var i = 0; i < jsonMessages.length; i++) {
      messages.push(jsonMessages[i].text);
    }

    this.dialog.open(InfoDialogComponent, {
      data: {
        icon: 'error',
        messages: messages
      }
    });
  }

  displayErrorMessage(jsonMessage: JsonMessage) {
    var messages: string[] = [jsonMessage.text];

    this.dialog.open(InfoDialogComponent, {
      data: {
        icon: 'error',
        messages: messages
      }
    });
  }
}
