import {Component, enableProdMode, OnInit} from '@angular/core';
import {ActivationEnd, Event, NavigationEnd, Router} from '@angular/router';
import { AuthenticationService } from './_services';
import { User } from './_models';
import './_content/app.less';
import {environment} from "@envs/environment";
import {HttpClient} from "@angular/common/http";
import { VERSION } from '@envs/version';
import {MapComponent} from "@/map";
import {CompanyConfigService} from "@/_services/company.config";
import {LookupService} from "@/_services/lookup.service";
import posthog from "posthog-js";
import {filter, Observable} from "rxjs";

@Component({
  standalone: false,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  http: HttpClient;
  title = 'Spottitt MF';
  currentUser: User | undefined;
  version: string = VERSION.hash + (VERSION.dirty ? '_d': '');
  public isMap: boolean = false;
  navigationEnd: Observable<NavigationEnd>;

  constructor(private router: Router,  private authenticationService: AuthenticationService, http: HttpClient,
              private _companyConfigServie: CompanyConfigService, private _lookupService: LookupService) {
    this.http = http;
    this.authenticationService.currentUser.subscribe({
      next: (user: User) => {
        this.currentUser = user;
        this.updateUserData();
      }
    });
    this.navigationEnd = router.events.pipe(
      filter((event: Event) => event instanceof NavigationEnd)
    ) as Observable<NavigationEnd>;
  }

  ngOnInit() {
    this.navigationEnd.subscribe((event: NavigationEnd) => {
      if(environment.posthogToken) {
        posthog.capture('$pageview');
      }
    });
    this.router.events.subscribe(event => {
      if (event instanceof ActivationEnd) {
        if (event.snapshot.component == MapComponent) {
          this.isMap = true;
        }
        else {
          this.isMap = false;
        }
      }
    });
  }

  updateUserData() {
    if(this.currentUser && this.currentUser?.firstName!="") {
      this._companyConfigServie.loadCompanyConfig();
      this.http.get<any>(`${environment.apiUrl}/api/user`, {}).subscribe(result => {
        if (this.currentUser) {
          this.currentUser.firstName = result.item?.userName;
          if(environment.posthogToken) {
            posthog.capture('$set',{ $set: { email: result.item?.email } })
          }
        } else {
          this.logout();
        }
      });
    }
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}
