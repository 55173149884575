<h1 mat-dialog-title>Report parameters used for generating</h1>
<div mat-dialog-content>
  <table class="table table-sm table-bordered w-100" *ngIf="variable || entity.parameters != null">
    <tbody>
      <tr *ngIf="variable">
        <td>Selected varaible</td>
        <td>
          <h6><strong>{{variable.name}}</strong></h6>
          {{variable.description}}
        </td>
      </tr>
      <tr *ngIf="variable">
        <td>Variable parameters</td>
        <td>
          <table class="table table-sm table-striped mb-0 table-variable-params">
            <tbody>
              <tr *ngFor="let filter of variable.selectedFilters">
                <td class="text-end">{{filter.title}}</td>
                <td>
                  <ng-container *ngIf="filter.type != 'lookup-type'">
                    <app-filter-as-text [value]="filter.value"></app-filter-as-text>
                  </ng-container>
                  <span *ngIf="filter.type == 'lookup-type'"><app-lookup-as-text name="INCIDENTTYPE" [showValues]="filter.value.valuePrimary" /></span>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr *ngIf="entity.parameters?.date1?.valuePrimary">
        <td *ngIf="entity.reportMode != 2">Date</td>
        <td *ngIf="entity.reportMode == 2"> Date range for pass 1</td>
        <td>
          <app-filter-as-date [value]="entity.parameters.date1"></app-filter-as-date>
        </td>
      </tr>
      <tr *ngIf="entity.parameters?.date2?.valuePrimary">
        <td>Date range for pass 2</td>
        <td>
          <app-filter-as-date [value]="entity.parameters.date2"></app-filter-as-date>
        </td>
      </tr>
      <tr *ngIf="entity.parameters != null && entity.parameters.assetFilter != null">
        <td>Asset filters</td>
        <td>
          <table class="table table-sm table-striped mb-0">
            <tbody>
            <ng-container *ngFor="let filter of assetFilter">
              <tr *ngIf="filter.value.valuePrimary != null">
                <td class="text-end">{{filter.title}}</td>
                <td>
                  <app-filter-as-text [value]="filter.value"></app-filter-as-text>
                </td>
              </tr>
            </ng-container>
            </tbody>
          </table>
        </td>
      </tr>
      <tr *ngIf="entity.parameters != null && entity.parameters.mergeDistance > 0">
        <td>Events merge radius <br/><small></small></td>
        <td>{{entity.parameters.mergeDistance}} m</td>
      </tr>
    </tbody>
  </table>
  <table class="table table-sm table-bordered w-100" *ngIf="entity.creationParams != null">
    <tbody>
      <ng-container *ngFor="let dataLayer of entity.creationParams.selectedDataLayers">
      <tr *ngIf="dataLayer.id > 0 && dataLayer.compareToId == null">
        <td>
          Input data layer name: <strong>{{dataLayer.displayName}}</strong>
          <table class="table table-sm table-bordered w-100" *ngIf="dataLayer.creationParameters != null">
            <tbody>
            <tr *ngIf="dataLayer.creationParameters.distanceFilter != null && dataLayer.creationParameters.distanceFilter.value.valuePrimary != null">
              <td>Horizontal distance [m] <br /><small></small></td>
              <td><app-filter-as-text [value]="dataLayer.creationParameters.distanceFilter.value"></app-filter-as-text></td>
            </tr>
            <tr *ngIf="dataLayer.creationParameters.areaFilter != null && dataLayer.creationParameters.areaFilter.value.valuePrimary != null">
              <td>Area [m^2] <br /><small></small></td>
              <td><app-filter-as-text [value]="dataLayer.creationParameters.distanceFilter.value"></app-filter-as-text></td>
            </tr>
            <tr *ngIf="dataLayer.creationParameters.eventLayer != null">
              <td>
                Layer mode:
                <strong *ngIf="!dataLayer.creationParameters.eventLayer">Group by asset</strong>
                <strong *ngIf="dataLayer.creationParameters.eventLayer">Show event details</strong>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <table class="table table-sm table-striped mb-0 w-100" *ngFor="let layer of dataLayer.creationParameters.selectedLayers">
                  <tr>
                    <td>
                      Source layer:<strong>{{layer.name}}</strong>
                      {{layer.description}}
                    </td>
                  </tr>
                  <tr><td>Layer parameters</td></tr>
                  <tr>
                    <td>
                      <table class="table table-sm table-striped mb-0 table-variable-params w-100">
                        <tbody>
                        <ng-container *ngFor="let filter of layer.filters">
                        <tr *ngIf="filter.value.valuePrimary != null || (filter.value.values != null && filter.value.values.length > 0) ">
                          <td class="text-end">{{filter.title}}</td>
                          <td>
                            <ng-container *ngIf="!['lookup-type', 'lookup-multi', 'date', 'lookup-single-nonnull', 'pass_or_compare'].includes(filter.type)">
                              <app-filter-as-text [value]="filter.value"></app-filter-as-text>
                            </ng-container>
                            <ng-container *ngIf="['date', 'pass_or_compare'].includes(filter.type)">
                              <app-filter-as-date [value]="filter.value"></app-filter-as-date>
                            </ng-container>
                            <span *ngIf="['lookup-type', 'lookup-multi'].includes(filter.type)"><app-lookup-as-text name="INCIDENTTYPE" [showValues]="filter.value.values" /></span>
                            <span *ngIf="['lookup-single-nonnull'].includes(filter.type) && filter.field == 'mergeRadius'"><app-lookup-as-text name="MERGERADIUS" [showValues]="filter.value.values" /></span>
                            <span *ngIf="['pass_or_compare'].includes(filter.type) && filter.value.values != null">
                              <app-lookup-as-text [name]="'GROUP'" [showValues]="filter.value.values"></app-lookup-as-text>
                            </span>
                          </td>
                        </tr>
                        </ng-container>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            </tbody>
          </table>
        </td>
      </tr>
      </ng-container>
    </tbody>
  </table>
</div>
<div mat-dialog-actions>
  <button mat-dialog-close class="btn btn-secondary">Close</button>
</div>
