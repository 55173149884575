<div class="d-flex flex-row align-items-center justify-content-between pt-2 pb-2 mb-2 border-bottom">
  <h1 class="h4 tab-header">Data layers</h1>
  <div class="d-flex flex-row col-auto mx-1">
    <button [disabled]="dataGrid.selectedItemsList.length == 0" (click)="showOnMapMulti()" class="btn btn-sm btn-primary"><i-feather name="map" class="xsm"></i-feather> Show {{dataGrid.selectedItemsList.length}} selected on map</button>
  </div>
  <div class="d-flex flex-row col-auto mx-1" *ngIf="showUpload">
    <button (click)="openUpload()" class="upload-btn btn btn-sm btn-secondary">Upload analysis</button>
  </div>
  <div class="d-flex flex-row col-auto mx-1" *ngIf="showUpload">
    <button (click)="openUploadRaster()" class="upload-btn btn btn-sm btn-secondary">Upload analysis raster</button>
  </div>
</div>
<div class="d-flex flex-row align-items-center">
  <analysis-metrics class="w-100" [filter]="filter"></analysis-metrics>
</div>
<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mt-1">
  <app-grid class="w-100" #dataGrid [data]="items" [config]="gridConfig" [service]="_resourceService" (filterEvent)="filter = $event"></app-grid>
</div>
