import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {first, tap} from 'rxjs/operators';
import {AuthenticationService} from '@/_services';
import { VERSION } from '@envs/version';

@Component({templateUrl: 'login.component.html', styleUrls: ['./login.component.scss']})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  loading = false;
  error = '';
  submitted = false;
  returnUrl: string | undefined;
  version: string = VERSION.hash + (VERSION.dirty ? '_d': '');

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f() { // @ts-ignore
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    // @ts-ignore
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.login(this.f['username'].value, this.f['password'].value)
      .pipe(first())
      .subscribe({
        next: this.redirectToReturnUrl.bind(this),
        error: this.showError.bind(this)
      });
  }

  redirectToReturnUrl(data: any) {
    this.router.navigate([this.returnUrl]);
  }

  showError(err: any) {
    this.error = err;
    this.loading = false;
  }
}
