<div class="input-group input-group-sm b-3" dropdown [ngClass]="{'input-text': type == 'text' }">
  <button dropdownToggle type="button" class="btn btn-sm btn-outline-secondary dropdown-toggle" [ngClass]="{'disabled': disabled}" [disabled]="disabled">
    <span [innerHTML]="operatorSymbols[operator]"></span>
    <span class="caret"></span>
  </button>
  <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" aria-labelledby="button-basic" [ngClass]="{disabled: disabled}">
    <ng-container *ngFor="let operatorOption of availableOperators">
      <li><a class="dropdown-item" [ngClass]="{'active': operator == operatorOption.operatorType}" *ngIf="operatorOption.availableForInputType.includes(type)" (click)="setOperator(operatorOption.operatorType)"><span [innerHTML]="operatorSymbols[operatorOption.operatorType]"></span> {{operatorOption.description}}</a></li>
    </ng-container>
  </ul>
  <span class="input-group-text" *ngIf="(operator == 11 || operator == 12)">(</span>
  <input type="text" [disabled]="disabled" *ngIf="type=='date' && !(operator == 15 || operator == 16)" [(ngModel)]="valuePrimary" placeholder="yyyy-mm-dd" class="input-date form-control form-control-sm" bsDatepicker>
  <input type="text" [disabled]="disabled" class="form-control" *ngIf="type=='text' && !(operator == 15 || operator == 16)" [(ngModel)]="valuePrimary">
  <input type="number" [disabled]="disabled" class="form-control" *ngIf="(type=='number'|| type=='numberlimited') && !(operator == 15 || operator == 16)" [(ngModel)]="valuePrimary">
  <span class="input-group-text" *ngIf="(operator == 11 || operator == 12)">-</span>
  <input type="text" [disabled]="disabled" *ngIf="type=='date'  && (operator == 11 || operator == 12)" [(ngModel)]="valueSecondary" placeholder="yyyy-mm-dd" class="input-date form-control form-control-sm" bsDatepicker>
  <input type="text" [disabled]="disabled" class="form-control" *ngIf="(type=='text') && (operator == 11 || operator == 12)" [(ngModel)]="valueSecondary">
  <input type="number" [disabled]="disabled" class="form-control" *ngIf="(type=='number'|| type=='numberlimited' ) && (operator == 11 || operator == 12)" [(ngModel)]="valueSecondary">
  <span class="input-group-text" *ngIf="(operator == 11 || operator == 12)">)</span>
  <span class="input-group-text" *ngIf="operator == 15">is empty</span>
  <span class="input-group-text" *ngIf="operator == 16">is not empty</span>
  <span class="input-group-text" *ngIf="postfix" [innerHTML]="postfix"></span>
</div>
