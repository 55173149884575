import { Component, Input, forwardRef } from '@angular/core';

@Component({
  selector: 'app-filter-as-date',
  templateUrl: './filter-as-date.component.html',
  styleUrls: ['./filter-as-date.component.scss'],
  providers: []
})

export class FilterAsDateComponent {
  @Input() value: any;
  constructor() { }
}
