<div *ngIf="_config != undefined && _config?.length">
  <div class="collapse show multi-collapse mt-1 mb-1" *ngIf="showHideBtn">
    <button class="btn btn-outline-secondary btn-sm" type="button" data-bs-toggle="collapse" data-bs-target=".multi-collapse" data-bs-delay='{"show":200,"hide":150}' aria-expanded="false" aria-controls="multi-collapse">
      <i-feather name="filter" class="xsm"></i-feather>
      Show filters
    </button>
  </div>
  <div class="row filter-row collapse multi-collapse" id="collapseFilter" [ngClass]="{'show': !showHideBtn}">
    <div class="d-flex flex-row col-auto mt-1 mb-1" *ngIf="showHideBtn">
      <button class="btn btn-outline-secondary btn-sm" type="button" data-bs-toggle="collapse" data-bs-target=".multi-collapse" aria-expanded="false" aria-controls="multi-collapse">
        <i-feather name="filter" class="xsm"></i-feather>
        Hide filters
      </button>
    </div>
    <ng-container *ngFor="let filter of _config">
      <div class="d-flex flex-row col-auto mt-1 mb-1 me-1" *ngIf="filter.type != 'group'">
        <label for="form-{{filter.field}}" class="form-label form-label-sm mt-1 mb-1 me-1" [innerHTML]="filter.title">.</label>
        <app-filter-input id="form-{{filter.field}}" *ngIf="filter.type == 'string'" [(ngModel)]="filter.value" [disabled]="filter.disabled" type="text"></app-filter-input>
        <app-filter-input id="form-{{filter.field}}" *ngIf="filter.type == 'number'" [(ngModel)]="filter.value" [disabled]="filter.disabled" type="number"></app-filter-input>
        <app-filter-input id="form-{{filter.field}}" *ngIf="filter.type == 'date'" [(ngModel)]="filter.value" [disabled]="filter.disabled" type="date"></app-filter-input>
        <app-lookup id="form-{{filter.field}}" *ngIf="filter.type == 'lookup-single'" [name]="filter.lookupName" [(ngModel)]="filter.value" mode="single" [disabled]="filter.disabled"></app-lookup>
        <app-lookup id="form-{{filter.field}}" *ngIf="filter.type == 'lookup-multi'" [name]="filter.lookupName" [(ngModel)]="filter.value" mode="multi" [disabled]="filter.disabled"></app-lookup>
        <app-lookup id="form-{{filter.field}}" *ngIf="filter.type == 'lookup-single-nonnull'" [name]="filter.lookupName" [(ngModel)]="filter.value" mode="singlenn" [disabled]="filter.disabled"></app-lookup>
        <app-lookup id="form-{{filter.field}}" *ngIf="filter.type == 'lookup-single-as-bool'" [name]="filter.lookupName" [(ngModel)]="filter.value" mode="single-as-bool" [disabled]="filter.disabled"></app-lookup>
        <div *ngIf="filter.type == 'boolean' && filter.falseAsNull" >
          <div class="btn-group btn-group-sm" >
            <input type="radio" class="btn-check" value="null" name="form-{{filter.field}}" [(ngModel)]="filter.value.valuePrimary" id="form-{{filter.field}}-off" autocomplete="off" [checked]="filter.value.valuePrimary==null">
            <label class="btn btn-sm btn-outline-primary" for="form-{{filter.field}}-off">{{filter.offText}}</label>
            <input type="radio" class="btn-check" value="true" name="form-{{filter.field}}" [(ngModel)]="filter.value.valuePrimary" id="form-{{filter.field}}-on" autocomplete="off" [checked]="filter.value.valuePrimary=='true'">
            <label class="btn btn-sm btn-outline-primary" for="form-{{filter.field}}-on">{{filter.onText}}</label>
          </div>
        </div>
        <div *ngIf="filter.type == 'boolean' && !filter.falseAsNull" >
          <div class="btn-group btn-group-sm" >
            <input type="radio" class="btn-check" value="false" name="form-{{filter.field}}" [(ngModel)]="filter.value.valuePrimary" id="form-{{filter.field}}-off" autocomplete="off" [checked]="filter.value.valuePrimary=='false'">
            <label class="btn btn-sm btn-outline-primary" for="form-{{filter.field}}-off">{{filter.offText}}</label>
            <input type="radio" class="btn-check" value="true" name="form-{{filter.field}}" [(ngModel)]="filter.value.valuePrimary" id="form-{{filter.field}}-on" autocomplete="off" [checked]="filter.value.valuePrimary=='true'">
            <label class="btn btn-sm btn-outline-primary" for="form-{{filter.field}}-on">{{filter.onText}}</label>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="d-flex flex-row col-auto mt-1 mb-1">
      <button class="btn-sm btn btn-primary" (click)="applyFilter()">Apply</button>
      <button class="btn-sm btn btn-warning ms-1" (click)="clearFilter()">Clear</button>
    </div>
  </div>
</div>
