<div class="lookup-wrapper">
  <ng-select [items]="myOptions"
             bindLabel="name"
             bindValue="id"
             [multiple]="true"
             [closeOnSelect]="false"
             [searchable]="false"
             [clearable]="false"
             placeholder="Select"
             (change)="onChange($event)"
             [(ngModel)]="selectedValue">
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
      <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" /> {{ item.name }}
    </ng-template>
  </ng-select>
</div>
