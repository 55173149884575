import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import WKB from "ol/format/WKB";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Style, Stroke, Fill, Circle } from "ol/style";
import { environment } from "@envs/environment";
import { MapLayer } from "@/map/models";
import { GridComponent } from "@/_components/grid";
import { JsonItemResponse } from "@/_models/response";
import { AssetsService } from "@/_services/assets.service";
import { MapLayerService } from "@/_services/map-layer.service";
import { OlMapService } from "@/_services/ol-map.service";
import { ReportDetailAssetCompareService } from "@/_services/report-detail-asset-compare.service";
import { ReportDetailAssetService } from "@/_services/report-detail-asset.service";
import { ReportDetailService } from "@/_services/report-detail.service";
import { ResourcesService } from "@/_services/resources.service";
import { MatDialog } from "@angular/material/dialog";
import { ReportParametersDialogComponent } from "@/_components/report-parameters-dialog";
import { CompanyConfigService } from "@/_services/company.config";
import { defaultReportAssetViewGridConfigColDef } from "@/_constants/events/default-reports-asset-view-grid-config-coldef";
import { defaultReportCompareViewGridConfigColDef } from "@/_constants/events/default-reports-compare-view-grid-config-coldef";
import { defaultReportEventViewGridConfigColDef } from "@/_constants/events/default-reports-event-view-grid-config-coldef";
import { GridConfigDialog } from "@/_components/grid-config-dialog/grid-config-dialog.component";
import TileLayer from "ol/layer/Tile";
import WKT from "ol/format/WKT";
import { TileWMS } from "ol/source";
import { Geometry, LineString, Point } from "ol/geom";
import { ReportDetailAssetClimateService } from "@/_services/report-detail-asset-climate.service";
import { Feature } from "ol";

@Component({
  standalone: false,
  selector: 'app-report-details',
  providers: [
    { provide: ResourcesService, useClass: ReportDetailService },
    { provide: ReportDetailAssetService },
    { provide: ReportDetailAssetCompareService },
    { provide: ReportDetailAssetClimateService },
    { provide: AssetsService }
  ],
  templateUrl: './report-details.component.html',
  styleUrls: ['./report-details.component.scss']
})
export class ReportDetailsComponent implements OnInit {
  public items: any[] = [];
  public gridConfig: any;
  private _filter: any;
  public mapDataLoading: boolean = false;
  public filterConfig: any;
  public id: number | undefined;
  public mode: string = 'table';
  public entity: any;
  public service: ResourcesService | undefined;
  public variable: any;
  public dataGridLoadData: boolean = false;
  public loading: boolean = true;
  public assetFilter: any;
  public viewType: string = '0';
  private tempAssetColDefs = null;
  private tempEventColDefs = null;
  private _companyConfig: any;
  private readonly _incidentService: ResourcesService;
  private readonly _incidentByAssetService: ResourcesService;
  private readonly _incidentByAssetCompareService: ResourcesService;
  private readonly _incidentByAssetClimateService: ResourcesService;
  public filterAsArray: boolean = true;

  @ViewChild('dataGrid') dataGrid!: GridComponent;

  constructor(public http: HttpClient,
    public _resourceService: ResourcesService,
    private readonly _mapLayerService: MapLayerService,
    private readonly route: ActivatedRoute,
    public _incidentsByAssetService: ReportDetailAssetService,
    public _incidentsByAssetCompareService: ReportDetailAssetCompareService,
    public _incidentsByAssetClimateService: ReportDetailAssetClimateService,
    public _assetsService: AssetsService,
    private readonly _olMapService: OlMapService,
    private readonly dialog: MatDialog,
    private readonly _companyConfigService: CompanyConfigService,
  ) {
    this._incidentService = _resourceService;
    this._incidentByAssetService = _incidentsByAssetService;
    this._incidentByAssetCompareService = _incidentsByAssetCompareService;
    this._incidentByAssetClimateService = _incidentsByAssetClimateService;
    this._assetsService = _assetsService;
  }

  set filter(value: any) {
    this._filter = value;
    if(value != undefined){
      if (this.viewType == "1") {
        this.makeLayerIncidents();
      } else if (this.viewType == "0") {
        this.makeLayerImpactedAssets();
      }
      else if (this.viewType == "3") {
        this.makeReportEventsLayer();
        if (this.entity.showCompartativeMap) {
          this.makeLayerCompareImpactedAssets2();
        }
      }
    }
  }

  get filter() {
    return this._filter;
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params['itemId'];
      this.http.get<JsonItemResponse<any>>(`${environment.apiUrl}/api/Report/GetItem?id=${this.id}`).subscribe(result => {
        if (result.ok) {
          this.entity = result.item;
          this._companyConfigService.configStore.subscribe(
            config => {
              if (this._companyConfigService.configLoaded) {
                this._companyConfig = config;
                this.viewType = this.entity.reportMode;
                this.setViewType(this.entity.reportMode);
                this.handleGridShowOnMapAssign();
              }
            }
          );
          if (result.item?.parameters?.selectedVariables?.length > 0) {
            this.http.get<JsonItemResponse<any>>(`${environment.apiUrl}/api/Variable/GetItem?id=${result.item.parameters.selectedVariables[0]}`).subscribe(result => {
              if (result.ok) {
                this.variable = result.item;
              }
              this.loading = false;
            });
          }
          else {
            this.loading = false;
          }
        }
      });
    });
    this.loadAssetsOnMap();
  }
  public setViewType(viewType: number) {
    this.viewType = viewType.toString();
    this.filterAsArray = false;
    if (this.dataGrid != undefined) {
      this.dataGrid.loading = true;
      this.dataGrid.clearSort();
    }
    if (viewType == 1) {
      this.filterAsArray = false;
      this.service = this._incidentService;
      this.makeLayerIncidents();
      if (this.entity.reportGridConfig?.eventConfig) {
        this.gridConfig = {
          _self: this,
          colDefs: this.entity.reportGridConfig?.eventConfig.colDefs,
          customiseParams: this.entity.reportGridConfig?.eventConfig.customiseParams
        }
      }
      else if (this.tempEventColDefs) {
        this.gridConfig = {
          _self: this,
          colDefs: JSON.parse(JSON.stringify(this.tempEventColDefs)),
          customiseParams: {
            column: "event",
            id: this.id
          }
        }
      }
      else {
        this.gridConfig = {
          _self: this,
          colDefs: JSON.parse(JSON.stringify(defaultReportEventViewGridConfigColDef)),
          customiseParams: {
            column: "event",
            id: this.id
          }
        }
      }
      this.filterConfigFromColDef(true, defaultReportEventViewGridConfigColDef)
    }
    else if (viewType == 0) {
      this.filterAsArray = false;
      if (this.entity.reportGridConfig?.assetConfig) {
        this.gridConfig = {
          _self: this,
          colDefs: this.entity.reportGridConfig?.assetConfig.colDefs,
          customiseParams: this.entity.reportGridConfig?.assetConfig.customiseParams
        }
      }
      else if (this.tempAssetColDefs) {
        this.gridConfig = {
          _self: this,
          colDefs: JSON.parse(JSON.stringify(this.tempAssetColDefs)),
          customiseParams: {
            column: "asset",
            id: this.id
          }
        }
      }
      else {
        this.gridConfig = {
          _self: this,
          colDefs: JSON.parse(JSON.stringify(defaultReportAssetViewGridConfigColDef)),
          customiseParams: {
            column: "asset",
            id: this.id
          }
        }
      }

      this.filterConfigFromColDef(true, defaultReportAssetViewGridConfigColDef);
      this.service = this._incidentByAssetService;
      this.makeLayerImpactedAssets();
    }
    else if (viewType == 2) {
      this.filterAsArray = false;
      if (this.entity.reportGridConfig?.compareConfig) {
        this.gridConfig = {
          _self: this,
          colDefs: this.entity.reportGridConfig?.compareConfig.colDefs,
          customiseParams: this.entity.reportGridConfig?.compareConfig.customiseParams
        }
      } else {
        this.gridConfig = {
          _self: this,
          colDefs: JSON.parse(JSON.stringify(defaultReportCompareViewGridConfigColDef)),
          customiseParams: {
            column: "compare",
            id: this.id
          }
        }
      }
      this.filterConfigFromColDef(true, defaultReportCompareViewGridConfigColDef)
      this.service = this._incidentByAssetCompareService;
      this.makeLayerCompareImpactedAssets();
    }
    else if (viewType == 3) {
      this.filterAsArray = true;

      if (this.entity.reportGridConfig?.assetConfig) {
        this.gridConfig = {
          _self: this,
          colDefs: this.entity.reportGridConfig?.assetConfig.colDefs,
          customiseParams: this.entity.reportGridConfig?.assetConfig.customiseParams
        }
        if (this.gridConfig.customiseParams == null) {
          this.gridConfig.customiseParams = {
            column: "asset",
            id: this.id
          }
        }
      }
      else if (this.tempAssetColDefs) {
        this.gridConfig = {
          _self: this,
          colDefs: JSON.parse(JSON.stringify(this.tempAssetColDefs)),
          customiseParams: {
            column: "asset",
            id: this.id
          }
        }
      }
      else {
        this.gridConfig = {
          _self: this,
          colDefs: JSON.parse(JSON.stringify(defaultReportAssetViewGridConfigColDef)),
          customiseParams: {
            column: "asset",
            id: this.id
          }
        }
      }

      this.filterConfigFromColDef();
      this.service = this._incidentByAssetClimateService;
      // this.makeReportEventsLayer();
      //
      // if (this.entity.showCompartativeMap) {
      //   this.makeLayerCompareImpactedAssets2();
      // }
    }
    let self = this;
    setTimeout(function () {
      self.dataGrid.unsubscribeService()
        .then(() => {
          self.dataGrid.loadData = true;
          self.dataGrid.refreshGrid()
        });
    }, 50);
  }

  private filterConfigFromColDef(useDefault: boolean = false, defaultsColDef: any = []) {
    this.filterConfig = [];
    let filterNumberOffset = 0;
    this.filterConfig.push({
      name: "show",
      falseAsNull: true,
      field: "a_HasIncidents",
      value: {
        valuePrimary: "true"
      },
      offText: "Show all assets",
      onText: "Show only impacted assets",
      type: "boolean"
    })
    filterNumberOffset = 1;
    this.gridConfig?.colDefs?.forEach((colDef: any) => {
      if (colDef?.filter?.type != null && colDef?.filter?.type != "") {
        this.filterConfig.push(colDef.filter)
      }
    });
    if (useDefault && this.filterConfig.length <= filterNumberOffset) {
      let colDefs = JSON.parse(JSON.stringify(defaultsColDef))
      colDefs.forEach((colDef: any) => {
        if (colDef?.filter?.type != null && colDef?.filter?.type != "") {
          this.filterConfig.push(colDef.filter)
        }
      });
    }
  }
  public openGridConfig(): void {
    this.gridConfig.defaultColumns = this.entity.defaultGridConfig.assetConfig.colDefs;

    var dialogRef = this.dialog.open(GridConfigDialog, {
      data: this.gridConfig,
      width: '30vw',
      height: '80vh',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(() => {
      if (this.viewType == "0") {
        this.tempAssetColDefs = this.gridConfig.colDefs;
      }
      if (this.viewType == "1") {
        this.tempEventColDefs = this.gridConfig.colDefs;
      }
      if (this.viewType == "3") {
        this.tempAssetColDefs = this.gridConfig.colDefs;
      }
      this.handleGridShowOnMapAssign();
    });
  }

  private handleGridShowOnMapAssign() {
    let foundIndex = this.gridConfig.colDefs.findIndex((x: any) => x.action === 'showOnMap');
    if (foundIndex > -1) {
      this.gridConfig.colDefs[foundIndex].action = this.showOnMap;
    }
    foundIndex = this.gridConfig.colDefs.findIndex((x: any) => x.originalTitle === 'Show on Map');
    if (foundIndex > -1) {
      this.gridConfig.colDefs[foundIndex].action = this.showOnMap;
    }
  }

  private makeLayerIncidents() {
    this._mapLayerService.addEvent('incidents', 'showLoader', {})
    let layer = new MapLayer();
    this.mapDataLoading = true;
    this._incidentService.getNonPagedList(this.filter, {}, this.id).subscribe({
      next: (result: any) => {
        let mapData = result.items;
        layer.id = "incidents_report" + this.entity.name;
        layer.name = "Report " + this.entity.name;
        layer.visible = true;

        let _vectorSource = new VectorSource();
        let _wkbFormatter = new WKB();

        mapData.forEach(function (value: any) {
          let _feature = _wkbFormatter.readFeature(value.wkb, {
            dataProjection: 'EPSG:3857',
            featureProjection: 'EPSG:3857',
          });
          _feature.setId(value.id);
          _feature.setProperties({
            popup: JSON.stringify({
              'Distance': value.distance,
              'Height': value.height,
              'Class': value.class,
              'NDVI': value.ndvi
            }, undefined, 2)
          })

          let geometry = _feature.getGeometry();
          let geometryType = geometry?.getType();
          if (['LineString', 'MultiLineString'].includes(geometryType!)) {
            _feature.setStyle(
              new Style({
                stroke: new Stroke({
                  color: value.properties.lineColor,
                  width: value.properties.lineWidth,
                })
              })
            );
          }
          else if (['Point', 'MultiPoint'].includes(geometryType!)) {
            _feature.setStyle(
              new Style({
                fill: new Fill({
                  color: value.properties.pointColor,
                }),
                stroke: new Stroke({
                  color: value.properties.pointOutlineColor,
                  width: value.properties.pointWidth,
                }),
              })
            );
          }
          else if (['Polygon', 'MultiPolygon', 'GeometryCollection'].includes(geometryType!)) {
            _feature.setStyle(
              new Style({
                fill: new Fill({
                  color: value.properties.polygonColor,
                }),
                stroke: new Stroke({
                  color: value.properties.polygonOutlineColor,
                  width: value.properties.polygonOutlineWidth,
                }),
              })
            );
          }
          _vectorSource.addFeature(_feature);
        });

        layer.olLayer = new VectorLayer({ source: _vectorSource });
        layer.olLayer.set('layerId', layer.id);
        this._mapLayerService.addLayer(layer, true);
        this.mapDataLoading = false;
        this._mapLayerService.addEvent('incidents', 'hideLoader', {})
      },
      error: (error: Error) => console.error(error)
    });
  }

  private makeLayerImpactedAssets() {
    let layer2 = new MapLayer();
    this.mapDataLoading = true;
    this._incidentsByAssetService.getReportDetailAsset(this.filter, {}, this.id).subscribe({
      next: (result: any) => {
        let mapData = result.items;

        layer2.id = "incidents_to_asset_report" + this.entity.name;
        layer2.name = "Impacted assets report " + this.entity.name;
        layer2.visible = true;

        let _vectorSource = new VectorSource();
        let _wkbFormatter = new WKB();

        mapData.forEach(function (value: any) {
          let _feature = _wkbFormatter.readFeature(value.wkb, {
            dataProjection: 'EPSG:3857',
            featureProjection: 'EPSG:3857',
          });

          //Custom geometry handling
          let geometry = _feature.getGeometry();
          let geometryType = geometry?.getType();
          if (['LineString', 'MultiLineString'].includes(geometryType!)) {
            _feature.setStyle(
              new Style({
                stroke: new Stroke({
                  color: "#ff9100",
                  width: 4,
                })
              })
            );
          }
          else if (['Point'].includes(geometryType!)) {
            let pointGeom = <Point>_feature.getGeometry();
            let coord = pointGeom.getCoordinates();
            let startPoint = coord;
            let endPoint = [coord[0] + 0.1, coord[1] + 0.1]; // Create a short line
            _feature.setGeometry(new LineString([startPoint, endPoint]));
            _feature.setStyle(
              new Style({
                stroke: new Stroke({
                  color: "#ff9100",
                  width: 10,
                })
              })
            );
          }
          else if (['Polygon', 'MultiPoint'].includes(geometryType!)) {
            _feature.setStyle(
              new Style({
                image: new Circle({
                  radius: 5,
                  fill: new Fill({
                    color: "#ff9100",
                  }),
                  stroke: new Stroke({
                    color: "#000",
                    width: 1,
                  })
                })
              })
            );
          }
          _vectorSource.addFeature(_feature);
        });
        layer2.olLayer = new VectorLayer({ source: _vectorSource });
        this._mapLayerService.addLayer(layer2, true);
        this.mapDataLoading = false;
        this._mapLayerService.addEvent('incidents', 'hideLoader', {})
      },
      error: (error: Error) => console.error(error)
    });
  }

  private makeLayerCompareImpactedAssets2() {
    let layer2 = new MapLayer();
    this.mapDataLoading = true;
    let params = {
      parentId: this.entity.id,
      "filter": this.filter,
    }
    this.http.post<any>(`${environment.apiUrl}/api/Report/GetMapDataCompare2`, params).subscribe({
      next: (result: any) => {
        let mapData = result.items;

        layer2.id = "incidents_to_asset_compare" + this.entity.name;
        layer2.name = "Impacted assets compare report " + this.entity.name;
        layer2.visible = true;

        let _vectorSource = new VectorSource();
        let _wkbFormatter = new WKB();

        //let max = mapData.reduce((prev: any, current: any) => (prev && prev.growthRate > current.growthRate) ? prev : current)
        //let min = mapData.reduce((prev: any, current: any) => (prev && prev.growthRate < current.growthRate) ? prev : current)

        layer2.categories = [
          {
            name: '>50%',
            outlineColor: "#FF0000",
            color: "#FF0000",
            expression: "",
            outlineWidth: 1,
            pxlval: 0,
            geometryType: ""
          },
          {
            name: "11 to 50%",
            outlineColor: "#fc5603",
            color: "#fc5603",
            expression: "",
            outlineWidth: 1,
            pxlval: 0,
            geometryType: ""
          },
          {
            name: "0 to 10%",
            outlineColor: "#fcb103",
            color: "#fcb103",
            expression: "",
            outlineWidth: 1,
            pxlval: 0,
            geometryType: ""
          },
          {
            name: '-10% to 0',
            outlineColor: "#fceb03",
            color: "#fceb03",
            expression: "",
            outlineWidth: 1,
            pxlval: 0,
            geometryType: ""
          },
          {
            name: '-50% to -11%',
            outlineColor: "#bafc03",
            color: "#bafc03",
            expression: "",
            outlineWidth: 1,
            pxlval: 0,
            geometryType: ""
          },
          {
            name: '<-50%',
            outlineColor: "#1b6636",
            color: "#1b6636",
            expression: "",
            outlineWidth: 1,
            pxlval: 0,
            geometryType: ""
          }
        ];

        mapData.forEach(function (value: any) {
          let _feature4 = _wkbFormatter.readFeature(value.geometry, {
            dataProjection: 'EPSG:3857',
            featureProjection: 'EPSG:3857',
          });
          let geometry4 = _feature4.getGeometry();
          let geometryType4 = geometry4?.getType();
          if (['LineString', 'MultiLineString'].includes(geometryType4!)) {
            if (value.growthRate > 50) {
              _feature4.setStyle(
                new Style({
                  stroke: new Stroke({
                    color: "#FF0000",
                    width: 3,
                  })
                })
              );
            } else if (value.growthRate > 10 && value.growthRate <= 50) {
              _feature4.setStyle(
                new Style({
                  stroke: new Stroke({
                    color: "#fc5603",
                    width: 3,
                  })
                })
              );
            } else if (value.growthRate <= 10 && value.growthRate >= 0) {
              _feature4.setStyle(
                new Style({
                  stroke: new Stroke({
                    color: "#fcb103",
                    width: 3,
                  })
                })
              );
            } else if (value.growthRate < 0 && value.growthRate >= -10) {
              _feature4.setStyle(
                new Style({
                  stroke: new Stroke({
                    color: "#fceb03",
                    width: 3,
                  })
                })
              );
            } else if (value.growthRate < -10 && value.growthRate >= -50) {
              _feature4.setStyle(
                new Style({
                  stroke: new Stroke({
                    color: "#bafc03",
                    width: 3,
                  })
                })
              );
            } else if (value.growthRate < -50) {
              _feature4.setStyle(
                new Style({
                  stroke: new Stroke({
                    color: "#1b6636",
                    width: 3,
                  })
                })
              );
            }
          }
          _vectorSource.addFeature(_feature4);
        });
        layer2.olLayer = new VectorLayer({ source: _vectorSource });
        this._mapLayerService.addLayer(layer2, true);
        this.mapDataLoading = false;
        this._mapLayerService.addEvent('incidents', 'hideLoader', {})
      },
      error: (error: Error) => console.error(error)
    });
  }

  private makeLayerCompareImpactedAssets() {
    let layer2 = new MapLayer();
    this.mapDataLoading = true;
    this._incidentByAssetCompareService.getNonPagedList(this.filter, {}, this.id).subscribe({
      next: (result: any) => {
        let mapData = result.items;

        layer2.id = "incidents_to_asset_compare" + this.entity.name;
        layer2.name = "Impacted assets compare report " + this.entity.name;
        layer2.visible = true;

        let _vectorSource = new VectorSource();
        let _wkbFormatter = new WKB();

        let max = mapData.reduce((prev: any, current: any) => (prev && prev.growthRate > current.growthRate) ? prev : current)
        let min = mapData.reduce((prev: any, current: any) => (prev && prev.growthRate < current.growthRate) ? prev : current)

        if (min.growthRate < 0) {
          layer2.categories = [
            {
              name: max.growthRate + " - " + max.growthRate / 2,
              outlineColor: "#FF0000",
              color: "#FF0000",
              expression: "",
              outlineWidth: 1,
              pxlval: 0,
              geometryType: ""
            },
            {
              name: max.growthRate / 2 + " - 0",
              outlineColor: "#880000",
              color: "#880000",
              expression: "",
              outlineWidth: 1,
              pxlval: 0,
              geometryType: ""
            },
            {
              name: "0 - " + min.growthRate / 2,
              outlineColor: "#008800",
              color: "#008800",
              expression: "",
              outlineWidth: 1,
              pxlval: 0,
              geometryType: ""
            },
            {
              name: min.growthRate / 2 + " - " + min.growthRate,
              outlineColor: "#00FF00",
              color: "#00FF00",
              expression: "",
              outlineWidth: 1,
              pxlval: 0,
              geometryType: ""
            }];
        } else {
          layer2.categories = [
            {
              name: max.growthRate + " - " + max.growthRate / 2,
              outlineColor: "#FF0000",
              color: "#FF0000",
              expression: "",
              outlineWidth: 1,
              pxlval: 0,
              geometryType: ""
            },
            {
              name: max.growthRate / 2 + " - 0",
              outlineColor: "#880000",
              color: "#880000",
              expression: "",
              outlineWidth: 1,
              pxlval: 0,
              geometryType: ""
            }
          ];
        }

        mapData.forEach(function (value: any) {
          let _feature4 = _wkbFormatter.readFeature(value.geometryAsset, {
            dataProjection: 'EPSG:3857',
            featureProjection: 'EPSG:3857',
          });
          let geometry4 = _feature4.getGeometry();
          let geometryType4 = geometry4?.getType();
          if (['LineString', 'MultiLineString'].includes(geometryType4!)) {
            if (value.growthRate > max.growthRate / 2) {
              _feature4.setStyle(
                new Style({
                  stroke: new Stroke({
                    color: "#FF0000",
                    width: 3,
                  })
                })
              );
            } else if (value.growthRate > 0 && value.growthRate <= max.growthRate / 2) {
              _feature4.setStyle(
                new Style({
                  stroke: new Stroke({
                    color: "#880000",
                    width: 3,
                  })
                })
              );
            } else if (value.growthRate <= 0 && value.growthRate >= min.growthRate / 2) {
              _feature4.setStyle(
                new Style({
                  stroke: new Stroke({
                    color: "#008800",
                    width: 3,
                  })
                })
              );
            } else if (value.growthRate < 0 && value.growthRate < min.growthRate / 2) {
              _feature4.setStyle(
                new Style({
                  stroke: new Stroke({
                    color: "#00FF00",
                    width: 3,
                  })
                })
              );
            }
          }
          _vectorSource.addFeature(_feature4);
        });
        layer2.olLayer = new VectorLayer({ source: _vectorSource });
        this._mapLayerService.addLayer(layer2, true);
        this.mapDataLoading = false;
        this._mapLayerService.addEvent('incidents', 'hideLoader', {})
      },
      error: (error: Error) => console.error(error)
    });
  }

  private makeReportEventsLayer() {
    let layer = new MapLayer();
    this.mapDataLoading = true;
    let params = {
      parentId: this.entity.id,
      "filter": this.filter,
    }
    this.http.post<any>(`${environment.apiUrl}/api/Report/GetMapData2`, params).subscribe({
      next: (result: any) => {
        let mapData = result.items;
        layer.id = "incidents_report" + this.entity.name;
        layer.name = "Report " + this.entity.name;
        layer.visible = true;

        let _vectorSource = new VectorSource();
        let _vectorSource2 = new VectorSource();
        let _wkbFormatter = new WKB();

        mapData.forEach(function (value: any) {
          {
            let _feature = _wkbFormatter.readFeature(value.wkb, {
              dataProjection: 'EPSG:3857',
              featureProjection: 'EPSG:3857',
            });
            _feature.setId(value.id ?? value.Id);
            _feature.setProperties({
              popup: JSON.stringify({
                'Distance': value.distance,
                'Height': value.height,
                'Class': value.class,
                'NDVI': value.ndvi,
                'Name': value.name
              }, undefined, 2)
            });
            handleGeometryStyle(_feature, value.properties);

            _vectorSource.addFeature(_feature);
          }
          if (value.wkb2) {
            let _feature = _wkbFormatter.readFeature(value.wkb2, {
              dataProjection: 'EPSG:3857',
              featureProjection: 'EPSG:3857',
            });
            _feature.setId(value.id2 ?? value.Id2);
            _feature.setProperties({
              popup: JSON.stringify({
                'Distance': value.distance,
                'Name': value.Name,
                'Height': value.height,
                'Class': value.class,
                'NDVI': value.ndvi
              }, undefined, 2)
            });
            let geometry = _feature.getGeometry();
            let geometryType = geometry?.getType();
            if (['LineString', 'MultiLineString'].includes(geometryType!)) {
              _feature.setStyle(
                new Style({
                  stroke: new Stroke({
                    color: value.properties2.lineColor,
                    width: value.properties2.lineWidth,
                  })
                })
              );
            }
            else if (['Point', 'MultiPoint'].includes(geometryType!)) {
              _feature.setStyle(
                new Style({
                  fill: new Fill({
                    color: value.properties2.pointColor,
                  }),
                  stroke: new Stroke({
                    color: value.properties2.pointOutlineColor,
                    width: value.properties2.pointWidth,
                  }),
                })
              );
            }
            else if (['Polygon', 'MultiPolygon', 'GeometryCollection'].includes(geometryType!)) {
              _feature.setStyle(
                new Style({
                  fill: new Fill({
                    color: value.properties2.polygonColor,
                  }),
                  stroke: new Stroke({
                    color: value.properties2.polygonOutlineColor,
                    width: value.properties2.polygonOutlineWidth,
                  }),
                })
              );
            }
            _vectorSource2.addFeature(_feature);
          }
        });

        layer.olLayer = new VectorLayer({ source: _vectorSource });
        layer.olLayer.set('layerId', layer.id);
        this._mapLayerService.addLayer(layer, true);
        var featureCount = _vectorSource2.getFeatures().length;
        if (featureCount > 0) {
          layer.olLayer = new VectorLayer({ source: _vectorSource2 });
          layer.olLayer.set('layerId', layer.id + '_2');
          this._mapLayerService.addLayer(layer, true);
        }

        this.mapDataLoading = false;
        this._mapLayerService.addEvent('incidents', 'hideLoader', {})
      },
      error: (error: Error) => console.error(error)
    });
  }

  private makeLayerSatImages() {
    this.http.get<any>(`${environment.apiUrl}/api/SatelliteImage/GetReportBaseLayer?id=${this.id}`).subscribe({
      next: (result: any) => {
        let extent = undefined;
        let item = result.item;
        if (item) {
          let imageLayer = new MapLayer();
          imageLayer.name = item.name;
          imageLayer.id = "image" + item.id;

          if (item.wkt != null) {
            let feature = new WKT().readFeature(item.wkt, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' });
            extent = feature.getGeometry()?.getExtent();
          }
          imageLayer.olLayer = new TileLayer({
            extent: extent,
            preload: Infinity,
            source: new TileWMS({
              url: item.wmsUrl,
              params: { 'LAYERS': item.layers, 'TILED': true },
              transition: 0
            })
          });

          this._mapLayerService.addLayer(imageLayer, false, true);
        }
        return extent;
      },
      error: (error: Error) => console.error(error)
    });
  }

  private loadAssetsOnMap() {
    this._mapLayerService.addEvent('incidents', 'showLoader', {})
    let layer = new MapLayer();
    this.mapDataLoading = true;
    layer.id = "assets";
    this._assetsService.getNonPagedList(this.filter, {}).subscribe({
      next: (result: any) => {
        let mapData = result.items;
        layer.name = "Assets";
        layer.visible = true;
        layer.total = result.items.length;
        let _vectorSource = new VectorSource();
        let _wkbFormatter = new WKB();

        mapData.forEach(function (value: any) {
          let _feature = _wkbFormatter.readFeature(value.wkb, {
            dataProjection: 'EPSG:3857',
            featureProjection: 'EPSG:3857',
          });
          _feature.setId(value.id);
          _feature.setProperties({
            popup: JSON.stringify({
              'Name': value.name,
            }, undefined, 2)
          })

          let geometry = _feature.getGeometry();
          let geometryType = geometry?.getType();
          if (['Point'].includes(geometryType!)) {
            let pointGeom = <Point>_feature.getGeometry();
            let coord = pointGeom.getCoordinates();
            let startPoint = coord;
            let endPoint = [coord[0] + 0.1, coord[1] + 0.1]; // Create a short line
            _feature.setGeometry(new LineString([startPoint, endPoint]));
            geometry = _feature.getGeometry();
            geometryType = geometry?.getType();
          }
          if (['LineString', 'MultiLineString'].includes(geometryType!)) {
            _feature.setStyle(
              new Style({
                stroke: new Stroke({
                  color: value.properties.lineColor,
                  width: value.properties.lineWidth,
                })
              })
            );
          }
          else if (['Point', 'MultiPoint'].includes(geometryType!)) {
            _feature.setStyle(
              new Style({
                image: new Circle({
                  radius: value.properties.pointWidth,
                  fill: new Fill({
                    color: value.properties.pointColor,
                  }),
                  stroke: new Stroke({
                    color: value.properties.pointOutlineColor,
                    width: 1,
                  })
                })
              })
            );
          }
          else if (['Polygon', 'MultiPolygon', 'GeometryCollection'].includes(geometryType!)) {
            _feature.setStyle(
              new Style({
                fill: new Fill({
                  color: value.properties.polygonColor,
                }),
                stroke: new Stroke({
                  color: value.properties.polygonOutlineColor,
                  width: value.properties.polygonOutlineWidth,
                }),
              })
            );
          }
          _vectorSource.addFeature(_feature);
        });

        layer.olLayer = new VectorLayer({ source: _vectorSource });
        layer.olLayer.set('layerId', layer.id);
        this._mapLayerService.addLayer(layer, true, true);
        this.mapDataLoading = false;
        this._mapLayerService.addEvent('incidents', 'hideLoader', {})
      },
      error: (error: Error) => console.error(error)
    });
    return layer;
  }

  private downloadFile(endpoint: string) {
    let params = {
      "operationType": "SEARCH",
      "startRow": 0,
      "pageSize": 20,
      "updateCount": true,
      "parentId": this.id,
      "sort": {},
      "filter": this.filter
    }
    this.http.post<any>(`${environment.apiUrl}/api/Incident/GetExcelExport`, params).subscribe({
      next: (result: any) => {
        const url = `${environment.apiUrl}/api/Incident/` + endpoint + `?key=` + result.item;
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
      },
      error: (error: Error) => console.error(error)
    });
  }

  downloadExcel() {
    let endpoint = 'GetExcelReportExportFile';
    if (this.viewType == "0") {
      endpoint = 'GetExcelReportAssetExportFile';
    } else if (this.viewType == "3") {
      endpoint = 'GetExcelReportClimateExportFile';
    }
    this.downloadFile(endpoint);
  }

  downloadGeojson() {
    let endpoint = 'GetExcelReportExportGeojsonFile';
    if (this.viewType == "0") {
      endpoint = 'GetExcelAssetReportExportGeojsonFile';
    } else if (this.viewType == "3") {
      endpoint = 'GetExcelClimateReportExportGeojsonFile';
    }
    this.downloadFile(endpoint);
  }
  showParameters() {
    if (this.variable == null) {
      this.variable = this.entity.variable;
    }
    this.dialog.open(ReportParametersDialogComponent, {
      data: {
        entity: this.entity,
        variable: this.variable
      }
    });
  }
  showOnMap(item?: any) {
    if (item != null) {
      if (this.viewType == "1") {
        this._olMapService.addEvent('incidents', 'selectFeature', { layerId: "incidents_report" + this.entity.name, featureId: item.id })
        this._mapLayerService.addEvent('incidents', 'zoomToFeature', { layerId: "incidents_report" + this.entity.name, featureId: item.id })
      }
      else if (this.viewType == "0") {
        this._mapLayerService.addEvent('incidents', 'zoomToFeature', { layerId: 'assets', featureId: item.id })
      }
      else if (this.viewType == "2") {
        this._mapLayerService.addEvent('incidents', 'zoomToFeature', { layerId: 'assets', featureId: item.id })
      }
      else if (this.viewType == "3") {
        if (item.Id2) {
          this._olMapService.addEvent('incidents', 'selectFeature', { layerId: "incidents_report" + this.entity.name + '_2', featureId: item.Id2 })
          this._mapLayerService.addEvent('incidents', 'zoomToFeature', { layerId: "incidents_report" + this.entity.name, featureId: item.Id2 })
        }
        else {
          this._olMapService.addEvent('incidents', 'selectFeature', { layerId: 'assets', featureId: item.Id })
          this._mapLayerService.addEvent('incidents', 'zoomToFeature', { layerId: "incidents_report" + this.entity.name, featureId: item.Id })
        }
      }
      this.setMode("map");
    }
  }

  public setMode(mode: string, zoomToLayer: boolean = false) {
    this.mode = mode;
    if (mode == 'map') {
      this._olMapService.addEvent('incidents', 'updateSize', {});
      if (zoomToLayer) {
        if (this.viewType == "0") {
          this._mapLayerService.addEvent('incidents', 'zoomToLayer', { layerId: "incidents_to_asset_report" + this.entity.name })
        }
        else if (this.viewType == "1") {
          this._mapLayerService.addEvent('incidents', 'zoomToLayer', { layerId: "incidents_report" + this.entity.name })
        }
        else if (this.viewType == "3") {
          this._mapLayerService.addEvent('incidents', 'zoomToLayer', { layerId: "incidents_report" + this.entity.name })
        }
      }
    }
  }
}

function handleGeometryStyle(_feature: any, properties: any) {
  let geometry = _feature.getGeometry();
  let geometryType = geometry?.getType();
  if (['LineString', 'MultiLineString'].includes(geometryType!)) {
    _feature.setStyle(
      new Style({
        stroke: new Stroke({
          color: properties.lineColor,
          width: properties.lineWidth,
        })
      })
    );
  }
  else if (['Point', 'MultiPoint'].includes(geometryType!)) {
    _feature.setStyle(
      new Style({
        fill: new Fill({
          color: properties.pointColor,
        }),
        stroke: new Stroke({
          color: properties.pointOutlineColor,
          width: properties.pointWidth,
        }),
      })
    );
  }
  else if (['Polygon', 'MultiPolygon', 'GeometryCollection'].includes(geometryType!)) {
    _feature.setStyle(
      new Style({
        fill: new Fill({
          color: properties.polygonColor,
        }),
        stroke: new Stroke({
          color: properties.polygonOutlineColor,
          width: properties.polygonOutlineWidth,
        }),
      })
    );
  }
}
