import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResourcesService } from "./resources.service";
import {Observable, Subject, switchMap} from "rxjs";
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class IncidentsService extends ResourcesService {
  constructor(protected override httpClient: HttpClient) {
    super(httpClient);
  }
  override getResourceUrl(): string {
    return 'Incident';
  }
}
