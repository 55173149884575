import { getMultipleValuesInSingleSelectionError } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, ViewChild } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { environment } from '@envs/environment';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from "ngx-bootstrap-multiselect";
import { DialogService } from '@/_services/dialog.service';
import { CompanyConfigService } from "@/_services/company.config";
import { CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray } from '@angular/cdk/drag-drop';
import { defaultReportEventViewGridConfigColDef } from '../../_constants/events/default-reports-event-view-grid-config-coldef';
import { defaultReportCompareViewGridConfigColDef } from '../../_constants/events/default-reports-compare-view-grid-config-coldef';
import { defaultReportAssetViewGridConfigColDef } from '../../_constants/events/default-reports-asset-view-grid-config-coldef';

@Component({
  standalone: false,
  selector: 'grid-config-dialog',
  styleUrls: ['grid-config-dialog.component.scss'],
  templateUrl: 'grid-config-dialog.component.html',
})
export class GridConfigDialog {
  lookupData: any;

  public filter: any;

  public entity: any;

  public createLoader: boolean = false; http: HttpClient;
  private _companyConfig: any;

  //@ViewChild("createRepotTabs", { static: false }) createRepotTabs: MatTabGroup | undefined;

  constructor(
    public dialogRef: MatDialogRef<GridConfigDialog>, @Inject(MAT_DIALOG_DATA) public data: any, private _http: HttpClient,
    public dialogService: DialogService,
    private _companyConfigService: CompanyConfigService,
  ) {
    this.http = _http;
  }

  save() {
    if (!this.createLoader) {
      this.createLoader = true;
      this.http.post<any>(`${environment.apiUrl}/api/Report/SaveReportGrid/`, { colDefs: this.data.colDefs, customiseParams: this.data.customiseParams }).subscribe(result => {
        if (result.ok) {
          this.dialogRef.close();
        }
        else {
          this.dialogService.displayErrorMessages(result.messages);
        }
        this.createLoader = false;
      });
    }
  }

  resetToDefault() {
    if (this.data?.customiseParams?.column == "event") {
      this.data.colDefs = JSON.parse(JSON.stringify(defaultReportEventViewGridConfigColDef));
    } else
      if (this.data?.customiseParams?.column == "asset") {
        if (this.data?.defaultColumns) {
          this.data.colDefs = this.data?.defaultColumns;
        } else {
          this.data.colDefs = JSON.parse(JSON.stringify(defaultReportAssetViewGridConfigColDef));
        }
      } else
        if (this.data?.customiseParams?.column == "compare") {
          this.data.colDefs = JSON.parse(JSON.stringify(defaultReportCompareViewGridConfigColDef));
        }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.data.colDefs, event.previousIndex, event.currentIndex);
  }

  close() {
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
