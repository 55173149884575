<div class="flex-container">
  <div id="spottitt-layer-manager" class="panel-left spottitt-layer-manager" [hidden]="!showLayers">
    <div class="items-list data-panel resizable-container-float">
      <h5 *ngIf="layers.length > 0">Layers</h5>
      <ul class="list-group" cdkDropList (cdkDropListDropped)="drop($event)">
        <li *ngFor="let layer of layers" class="list-group-item" cdkDrag>
          <div class="layer-title">
            <a (click)="toogleLayerCategories(layer)" *ngIf="layer.categories">
              <i-feather name="chevron-down" class="xsm" [hidden]="!layer.expanded"></i-feather>
              <i-feather name="chevron-right" class="xsm" [hidden]="layer.expanded"></i-feather>
            </a>
            <span class="layer-name" [title]="layer.name">{{layer.name}}</span>
            <div class="layer-attributes">
              <div class="btn-group btn-group-sm">
                <button title="Show/Hide layer" class="btn btn-sm btn-secondary" (click)="toggleLayerVisibility(layer)">
                  <i-feather *ngIf="layer.visible" name="eye" class="xsm"></i-feather>
                  <i-feather *ngIf="!layer.visible" name="eye-off" class="xsm"></i-feather>
                </button>
                <button title="Zoom to area" class="btn btn-sm btn-secondary" (click)="zoomToArea(layer)">
                  <i-feather name="zoom-in" class="xsm"></i-feather>
                </button>
                <button title="Remove layer" class="btn btn-sm btn-secondary" (click)="removeLayer(layer)">
                  <i-feather name="trash-2" class="xsm"></i-feather>
                </button>
              </div>
            </div>
          </div>
          <span class="layer-description">{{layer.description}}</span>

          <div class="categories" *ngIf="layer.expanded">
            <div *ngFor="let cat of layer.categories" class="categories-layer">
              <div style="{{getStyle(cat)}}" class="categories-type" [attr.data-range-start]="cat?.dataRangeStart" [attr.data-range-end]="cat?.dataRangeEnd" [ngClass]="{'gradient-type': cat?.dataRangeStart != undefined}"></div>
              {{cat.name}}
            </div>
          </div>
        </li>
      </ul>

      <h5>Basemap</h5>
      <div class="btn-group btn-group-sm">
        <button title="Show/Hide Google basemap" class="btn btn-sm btn-secondary" [ngClass]="{'active': isGoogleMapBasemap}" (click)="googleMapBasemap()">
          Google
        </button>
        <button title="Show/Hide OSM basemap" class="btn btn-sm btn-secondary" [ngClass]="{'active': isOsmBasemap}" (click)="osmBasemap()">
          OSM
        </button>
        <button title="Show/Hide no basemap" class="btn btn-sm btn-secondary" [ngClass]="{'active': (!isGoogleMapBasemap && !isOsmBasemap)}" (click)="noBasemap()">
          No basemap
        </button>
      </div>
    </div>
  </div>
  <div class="flex-map-container">
    <app-ol-map class="map" [ngClass]="{'spinner': loader}" [center]="[-3008084, 3177492]" [zoom]="0" (mapReady)="onMapReady($event)" [name]="name"></app-ol-map>
    <a (click)="toogleLayers()" class="btn btn-primary btn-sm btn-legend">
      <span [hidden]="showLayers">Show legend</span>
      <span [hidden]="!showLayers">Hide legend</span>
    </a>
  </div>
</div>
