export const defaultEventsFilters =[
  {
    field: "assetDistance",
    title: "Horizontal distance [m]",
    type: "number",
    value: { operator: 6, valuePrimary: 5, valueSecondary: null }
  },
  {
    field: "assetDistance3d",
    title: "Absolute distance [m]",
    type: "number",
    value: { operator: 6, valuePrimary: null, valueSecondary: null }
  },
  {
    field: "NDVI",
    title: "Ndvi",
    type: "number",
    value: { operator: 11, valuePrimary: null, valueSecondary: null }
  },
  {
    field: "height",
    title: "Height estimate [m]",
    type: "number",
    value: { operator: 4, valuePrimary: 10, valueSecondary: null }
  },
  {
    field: "IncidentClassId",
    title: "Type",
    type: "lookup-multi",
    lookupName: "INCIDENTTYPE",
    value: ["3","12"]
  },
  {
    field: "date",
    title: "Date",
    type: "date"
  },
  {
    field: "area",
    title: "Area",
    type: "number",
    value: { operator: 6, valuePrimary: null, valueSecondary: null }
  },
  {
    field: "AssetName",
    title: "Asset name",
    type: "string",
    value: { operator: 7, valuePrimary: "", valueSecondary: null }
  },
  {
    field: "assetType",
    title: "Asset type",
    type: "string",
  },
  {
    field: "AssetCategory",
    title: "Asset Category",
    type: "string",
    value: { operator: 7, valuePrimary: "", valueSecondary: null }
  },
  {
    title: "Elevation",
    field: "elevation",
    type: "number",
  },
  {
    field: "assetLocation",
    title: "Asset Location",
    type: "string",
  },
  {
    field: "assetSublocation",
    title: "Location subgroup",
    type: "string",
  }
]
