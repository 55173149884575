<div class="d-flex flex-row align-items-center justify-content-between pt-2 pb-2 mb-2 border-bottom">
  <h1 class="h4 tab-header">Create Report</h1>
</div>
<div class="row my-2 name-input">
  <mat-form-field appearance="outline" subscriptSizing="dynamic">
    <mat-label>Report Name</mat-label>
    <input matInput [(ngModel)]="reportName" required>
  </mat-form-field>
</div>
<div class="m-1">
  <mat-tab-group #createRepotTabs dynamicHeight>
    <mat-tab label="Select variables" class="center">
      <div class="row w-100 overflow-x-hidden">
        <div class="col-12">
          <div class="row">
            <div class="col-4 variable-card" *ngFor="let variable of variables">
              <button class="left-border-curve right-border-curve center card variable" [class.variable-selected]="variable ==  selectedVariable" (click)="setVariable(variable)">
                <div class="row w-100">
                  <div class="col-2 center-button-parent">
                    <button class="btn-sm btn btn-primary icon-button center-button" (click)="openDetails(variable)" title="info">
                      <i-feather name=info class="xsm"></i-feather>
                    </button>
                  </div>
                  <div class="col-8">
                    <div style="font-weight: bold;">{{ variable.name }}</div>
                    <div flexible>{{ variable.description }}</div>
                  </div>
                  <div class="col-2 center-button-parent">
                    <mat-radio-group class="center-button" [(ngModel)]="selectedVariable" name="selectedVariable">
                      <mat-radio-button [value]=variable [class.variable-selected-chk]="variable ==  selectedVariable"></mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Select assets" class="center flex-grow-1 overflow-y-visible">
      <div class="row w-100 overflow-y-visible">
        <div class="col-4 overflow-y-visible">
        </div>
        <div class="col-4 overflow-y-visible">
          <div style="font-size:16px; font-weight: bold;">
            <span>
              Leave empty to have all assets selected.
            </span>
          </div>
        </div>
        <div class="col-4 overflow-y-visible">
        </div>
      </div>
      <div class="row w-100 overflow-y-visible">
        <div class="col-6 overflow-y-visible" *ngFor="let filter of assetFilter">
          <div class="row overflow-y-visible m-3">
            <div class="col-4 overflow-y-visible">
              <label for="form-{{filter.field}}" class="form-label form-label-sm m-1">{{filter.title}}</label>
            </div>
            <div class="col-8 overflow-y-visible">
              <app-filter-input *ngIf="filter.type == 'string'" [(ngModel)]="filter.value" type="text"></app-filter-input>
              <app-filter-input *ngIf="filter.type == 'number'" [(ngModel)]="filter.value" type="number"></app-filter-input>
              <app-filter-input *ngIf="filter.type == 'date'" [(ngModel)]="filter.value" type="date"></app-filter-input>
              <app-lookup *ngIf="filter.type == 'lookup-single'" [name]="filter.lookupName" [(ngModel)]="filter.value" mode="single"></app-lookup>
              <app-lookup *ngIf="filter.type == 'lookup-multi'" [name]="filter.lookupName" [(ngModel)]="filter.value" mode="multi"></app-lookup>
              <app-lookup *ngIf="filter.type == 'lookup-single-nonnull'" [name]="filter.lookupName" [(ngModel)]="filter.value" mode="singlenn"></app-lookup>
            </div>
          </div>
        </div>
      </div>
      <div style="height:200px"></div>
    </mat-tab>
    <mat-tab label="Define view" class="cente br overflow-x-hidden">
      <div class="row overflow-x-hidden">
        <div class="col-6 variable-card">
          <button class="left-border-curve right-border-curve center card variable " [class.variable-selected]="reportMode ==  0" (click)="setView(0)">
            <div class="row">
              <div class="col-10">
                <div style=" font-weight: bold;">Asset/Event View</div>
                <div flexible>Asset view shows how each asset is affected by the selected variable. Event view shows the location and characteristics of each identified variable in the AoI. Allows you to switch between the two.</div>
              </div>
              <div class="col-2 center-button-parent">
                <mat-radio-group class="center-button" [(ngModel)]="reportMode" name="reportMode">
                  <mat-radio-button [value]="0"></mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
          </button>
        </div>
        <div class="col-6 variable-card">
          <button class="left-border-curve right-border-curve center card variable" [class.variable-selected]="reportMode ==  2" (click)="setView(2)">
            <div class="row">
              <div class="col-10">
                <div style=" font-weight: bold;">Comparative View</div>
                <div flexible>This view shows how a specific metric changed from one schedule to the other</div>
              </div>
              <div class="col-2 center-button-parent">
                <mat-radio-group class="center-button" [(ngModel)]="reportMode" name="reportMode">
                  <mat-radio-button [value]="2"></mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
          </button>
        </div>
      </div>
      <div class="row" *ngIf="reportMode ==  0" overflow-y-visible>
        <div class="col-6 overflow-y-visible ">
          <strong>
            Select data range for the report:
          </strong>
          <div class="d-flex flex-row">
            <label class="form-label form-label-sm m-1 ms-0">By Pass: </label>
            <app-lookup [name]="'GROUP'" [(ngModel)]="groupId" mode="singlenn"></app-lookup>
            <label class="form-label form-label-sm m-1">or by Date: </label>
            <app-filter-input [(ngModel)]="dataDate" type="date"></app-filter-input>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="reportMode ==  2">
        <!-- <div class="col-12">
           <strong>
             Select data ranges for the reports to compare
           </strong>
         </div>-->
        <div class="col-6 overflow-y-visible">
        </div>

        <div class="col-6 overflow-y-visible">
          <strong>Select data range for report 1:</strong>
          <div class="d-flex flex-row mb-2">
            <label class="form-label form-label-sm m-1 ms-0">By Pass: </label>
            <app-lookup [name]="'GROUP'" [(ngModel)]="groupId" mode="singlenn"></app-lookup>
            <label class="form-label form-label-sm m-1">or by Date: </label>
            <app-filter-input [(ngModel)]="dataDate" type="date"></app-filter-input>
          </div>

          <strong>Select data range for report 2:</strong>
          <div class="d-flex flex-row">
            <label class="form-label form-label-sm m-1 ms-0">By Pass: </label>
            <app-lookup [name]="'GROUP'" [(ngModel)]="groupId2" mode="singlenn"></app-lookup>
            <label class="form-label form-label-sm m-1">or by Date: </label>
            <app-filter-input [(ngModel)]="dataDate2" type="date"></app-filter-input>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12">
          <b>Merge events</b>
        </div>
        <div class="col-12">
          <span>
            the number of single events can sometimes be overwelming. Use the selector below to group events related to the same variable, that are close to each other:
          </span>
        </div>

        <div class="col-12 overflow-y-visible">
          Merge events that are less than <app-lookup [name]="'MERGERADIUS'" [(ngModel)]="mergeR" mode="singlenn"></app-lookup> from each other.
        </div>
      </div>
      <div style="height:240px"></div>
    </mat-tab>
  </mat-tab-group>
</div>
<button (click)="close()" class="btn btn-secondary" style="margin-right:20px;">Close</button>
<button (click)="nextTab()" class="btn btn-primary" *ngIf="createRepotTabs.selectedIndex !=2">Next</button>
<button (click)="create()" class="btn btn-primary" [disabled]="createLoader" *ngIf="createRepotTabs.selectedIndex  == 2">
  <span *ngIf="createLoader" class="spinner-border spinner-border-sm mr-1"></span>
  Create
</button>
