import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResourcesService } from "./resources.service";
import { Observable, Subject, switchMap } from "rxjs";
import { catchError } from "rxjs/operators";
import { IncidentsService } from "@/_services/incidents.service";

@Injectable({
  providedIn: 'root'
})
export class ReportDetailAssetClimateService extends IncidentsService {
  protected getIncidentsMappedToAssetsEndpoint = "/GetReportDataAsset";
  protected getReportDetailRequest$ = new Subject();
  protected getReportDetailResponse$ = new Subject();
  constructor(protected override httpClient: HttpClient) {
    super(httpClient);
    this.getListEndpoint = "/GetClimateReportByAssetList";
    this.getNonPagedListEndpoint = "/GetReportsClimateByAssetNonPagedList";
    this.getReportDetailRequest$.pipe(switchMap(
      params => {
        return this.httpClient.post<any[]>(`${this.apiUrl + this.getIncidentsMappedToAssetsEndpoint}`, params)
          .pipe(
            catchError(this.handleError)
          )
      })).subscribe(results => this.getReportDetailResponse$.next(results));
  }
  getReportDetailAsset(filter: any = {}, sort: any = {}, parentId: any): Observable<any> {
    let params = {
      "operationType": "SEARCH",
      "updateCount": true,
      "sort":
      {
        "columnName": sort?.columnName,
        "sortDirectionText": sort?.order
      },
      "filter": filter,
      "parentId": parentId
    }
    this.getReportDetailRequest$.next(params);
    return this.getReportDetailResponse$.asObservable();
  }
}
