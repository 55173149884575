import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import WKB from "ol/format/WKB";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Style, Stroke, Fill, Text as olText, Circle } from "ol/style";
import { environment } from "@envs/environment";
import { MapLayer, MapLayerCategory } from "@/map/models";
import { GridComponent } from "@/_components/grid";
import { JsonItemResponse } from "@/_models/response";
import { AssetsService } from "@/_services/assets.service";
import { MapLayerService } from "@/_services/map-layer.service";
import { OlMapService } from "@/_services/ol-map.service";
import { ReportDetailAssetCompareService } from "@/_services/report-detail-asset-compare.service";
import { ReportDetailAssetService } from "@/_services/report-detail-asset.service";
import { ReportDetailService } from "@/_services/report-detail.service";
import { ResourcesService } from "@/_services/resources.service";
import { MatDialog } from "@angular/material/dialog";
import { ReportParametersDialogComponent } from "@/_components/report-parameters-dialog";
import { cloneDeep } from "lodash";
import { CompanyConfigService } from "@/_services/company.config";
import { defaultReportAssetViewGridConfigColDef } from "../_constants/events/default-reports-asset-view-grid-config-coldef";
import { defaultReportCompareViewGridConfigColDef } from "../_constants/events/default-reports-compare-view-grid-config-coldef";
import { defaultReportEventViewGridConfigColDef } from "../_constants/events/default-reports-event-view-grid-config-coldef";
import { GridConfigDialog } from "../_components/grid-config-dialog/grid-config-dialog.component";
import TileLayer from "ol/layer/Tile";
import WKT from "ol/format/WKT";
import { TileWMS } from "ol/source";
import { LineString, Point } from "ol/geom";
import { ReportDetailAssetClimateService } from "../_services/report-detail-asset-climate.service";

@Component({
  selector: 'app-report-details',
  providers: [
    {
      provide: ResourcesService,
      useClass: ReportDetailService
    },
    {
      provide: ReportDetailAssetService
    },
    {
      provide: ReportDetailAssetCompareService
    },
    {
      provide: ReportDetailAssetClimateService
    },
    {
      provide: AssetsService
    }
  ],
  templateUrl: './report-details.component.html',
  styleUrls: ['./report-details.component.scss']
})
export class ReportDetailsComponent implements OnInit {
  public items: any[] = [];
  public gridConfig: any;
  private _filter: any;
  public mapDataLoading: boolean = false;
  public filterConfig: any;
  public id: number | undefined;
  public mode: string = 'table';
  public dataSource: string = 'incidents';
  public entity: any;
  public service: ResourcesService | undefined;
  public variable: any;
  public dataGridLoadData: boolean = false;
  public loading: boolean = true;
  public assetFilter: any;
  public viewType: string = '0';
  private tempAssetColDefs = null;
  private tempEventColDefs = null;
  private _companyConfig: any;
  private readonly _incidentService: ResourcesService;
  private readonly _incidentByAssetService: ResourcesService;
  private readonly _incidentByAssetCompareService: ResourcesService;
  private readonly _incidentByAssetClimateService: ResourcesService;

  @ViewChild('dataGrid') dataGrid!: GridComponent;

  constructor(public http: HttpClient,
    public _resourceService: ResourcesService,
    private _mapLayerService: MapLayerService,
    private route: ActivatedRoute,
    public _incidentsByAssetService: ReportDetailAssetService,
    public _incidentsByAssetCompareService: ReportDetailAssetCompareService,
    public _incidentsByAssetClimateService: ReportDetailAssetClimateService,
    public _assetsService: AssetsService,
    private _olMapService: OlMapService,
    private dialog: MatDialog,
    private _companyConfigService: CompanyConfigService,
  ) {
    this._incidentService = _resourceService;
    this._incidentByAssetService = _incidentsByAssetService;
    this._incidentByAssetCompareService = _incidentsByAssetCompareService;
    this._incidentByAssetClimateService = _incidentsByAssetClimateService;
    this._assetsService = _assetsService;

    this.gridConfig = {
      _self: this,
      colDefs: [
        {
          title: '',
          type: 'action',
          btnText: '',
          btnIconName: 'map',
          btnTooltip: 'Show on map',
          action: this.showOnMap,
          paramFields: ['id'],
          editable: true,
          visible: true,
        },
        {
          title: "Event Id",
          field: "incidentName",
          sortColumn: "IncidentName",
          editable: true,
          visible: true,
        },
        {
          title: "AssetName",
          field: "assetName",
          sortColumn: "Asset.Name",
          editable: true,
          visible: true,
        },
        {
          title: "Variable",
          field: "class",
          sortColumn: "IncidentClassLabel",
          editable: true,
          visible: true,
        },
        {
          title: "Horizontal distance [m]",
          field: "distance",
          sortColumn: "AssetDistance",
          editable: true,
          visible: true,
        },
        {
          title: "Absolute distance [m]",
          field: "distance3D",
          sortColumn: "AssetDistance3D",
          editable: true,
          visible: true,
        },
        //{
        //  title: "Vegetation type",
        //  field: "vegetationsStatus",
        //  sortColumn: "vegetationsStatus",
        //},
        {
          title: "Ndvi",
          field: "ndvi",
          sortColumn: "NDVI",
          editable: true,
          visible: true,
        },
        {
          title: "Height estimate [m]",
          field: "height",
          sortColumn: "Height",
          editable: true,
          visible: true,
        },
        {
          title: "Area [m2]",
          field: "area",
          sortColumn: "Area",
          editable: true,
          visible: true,
        },
        {
          title: "Date",
          field: "date",
          sortColumn: "Date",
          editable: true,
          visible: true,
        }
      ]
    }

    this.filterConfig = [
      {
        field: "Name",
        title: "Asset name",
        type: "string",
        value: { operator: 7, valuePrimary: "", valueSecondary: null }
      },
      {
        field: "IncidentName",
        title: "Event Id",
        type: "string",
        value: { operator: 7, valuePrimary: "", valueSecondary: null }
      },
      {
        field: "IncidentClassId",
        title: "Variable",
        type: "lookup-multi",
        lookupName: "INCIDENTTYPE",
      },
      {
        field: "assetDistance",
        title: "Horizontal distance [m]",
        type: "number",
        value: { operator: 6, valuePrimary: null, valueSecondary: null }
      },
      {
        field: "assetDistance3d",
        title: "Absolute distance [m]",
        type: "number",
        value: { operator: 6, valuePrimary: null, valueSecondary: null }
      },
      {
        field: "NDVI",
        title: "Ndvi",
        type: "number",
        value: { operator: 11, valuePrimary: null, valueSecondary: null }
      },
      {
        field: "height",
        title: "Height estimate [m]",
        type: "number",
        value: { operator: 4, valuePrimary: null, valueSecondary: null }
      },
      {
        field: "assetType",
        title: "Asset type",
        type: "string",
      },
      {
        field: "AssetCategory",
        title: "Asset Category",
        type: "string",
        value: { operator: 7, valuePrimary: "", valueSecondary: null }
      },
      {
        title: "Elevation",
        field: "elevation",
        type: "number",
      },
      {
        field: "assetLocation",
        title: "Asset Location",
        type: "string",
      },
      {
        field: "assetSublocation",
        title: "Location subgroup",
        type: "string",
      }

    ]
  }

  set filter(value: any) {
    this._filter = value;
    if (this.viewType == "1") {
      this.makeLayerIncidents();
    } else if (this.viewType == "0") {
      this.makeLayerImpactedAssets();
    }
  }

  get filter() {
    return this._filter;
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params['itemId'];
      this.http.get<JsonItemResponse<any>>(`${environment.apiUrl}/api/Report/GetItem?id=${this.id}`).subscribe(result => {
        if (result.ok) {
          this.entity = result.item;
          this._companyConfigService.configStore.subscribe(
            config => {
              if (this._companyConfigService.configLoaded) {
                this._companyConfig = config;
                this.viewType = this.entity.reportMode;
                this.setViewType(this.entity.reportMode);
                this.handleGridShowOnMapAssign();
              }
            }
          );
          if (result.item.parameters != null && result.item.parameters.selectedVariables != null && result.item.parameters.selectedVariables.length > 0) {
            this.http.get<JsonItemResponse<any>>(`${environment.apiUrl}/api/Variable/GetItem?id=${result.item.parameters.selectedVariables[0]}`).subscribe(result => {
              if (result.ok) {
                this.variable = result.item;
              }
              this.loading = false;
            });
          }
          else {
            this.loading = false;
          }
        }
      });
    });
    this.loadAssetsOnMap();
  }
  public setViewType(viewType: number) {
    this.viewType = viewType.toString();
    if (this.dataGrid != undefined) {
      this.dataGrid.loading = true;
      this.dataGrid.clearSort();
    }
    if (viewType == 1) {
      this.service = this._incidentService;
      this.makeLayerIncidents();

      if (this.entity.reportGridConfig?.eventConfig) {
        this.gridConfig = {
          _self: this,
          colDefs: this.entity.reportGridConfig?.eventConfig.colDefs,
          customiseParams: this.entity.reportGridConfig?.eventConfig.customiseParams
        }
      } else {
        if (this.tempEventColDefs) {
          this.gridConfig = {
            _self: this,
            colDefs: JSON.parse(JSON.stringify(this.tempEventColDefs)),
            customiseParams: {
              column: "event",
              id: this.id
            }
          }
        } else {
          this.gridConfig = {
            _self: this,
            colDefs: JSON.parse(JSON.stringify(defaultReportEventViewGridConfigColDef)),
            customiseParams: {
              column: "event",
              id: this.id
            }
          }
        }
      }
      this.filterConfig = [

        {
          field: "IncidentName",
          title: "Event Id",
          type: "string",
          value: { operator: 7, valuePrimary: "", valueSecondary: null }
        },
        {
          field: "Name",
          title: "Asset name",
          type: "string",
          value: { operator: 7, valuePrimary: "", valueSecondary: null }
        },
        {
          field: "IncidentClassId",
          title: "Variable",
          type: "lookup-multi",
          lookupName: "INCIDENTTYPE",
        },
        {
          field: "assetDistance",
          title: "Horizontal distance [m]",
          type: "number",
          value: { operator: 6, valuePrimary: null, valueSecondary: null }
        },
        {
          field: "assetDistance3d",
          title: "Absolute distance [m]",
          type: "number",
          value: { operator: 6, valuePrimary: null, valueSecondary: null }
        },
        {
          field: "NDVI",
          title: "Ndvi",
          type: "number",
          value: { operator: 11, valuePrimary: null, valueSecondary: null }
        },
        {
          field: "height",
          title: "Height estimate [m]",
          type: "number",
          value: { operator: 4, valuePrimary: null, valueSecondary: null }
        },

        {
          field: "area",
          title: "Area [m2]",
          type: "number",
          value: { operator: 6, valuePrimary: null, valueSecondary: null }
        },
        {
          field: "date",
          title: "Date",
          type: "date"
        },
        {
          field: "assetType",
          title: "Asset type",
          type: "string",
        },
        {
          field: "AssetCategory",
          title: "Asset Category",
          type: "string",
          value: { operator: 7, valuePrimary: "", valueSecondary: null }
        },
        {
          title: "Elevation",
          field: "elevation",
          type: "number",
        },
        {
          field: "assetLocation",
          title: "Asset Location",
          type: "string",
        },
        {
          field: "assetSublocation",
          title: "Location subgroup",
          type: "string",
        },
        {
          title: "Start Point",
          field: "startName",
          type: "string",
        },
        {
          title: "End Point",
          field: "endName",
          type: "string",
        },

      ]

      //if (!this._companyConfig?.showAbsoluteDistance) {
      //  this.gridConfig.colDefs.splice(this.gridConfig.colDefs.findIndex((item: any) => item.field === "distance3D"), 1)
      //  this.filterConfig.splice(this.filterConfig.findIndex((item: any) => item.field === "assetDistance3d"), 1)
      //}
    }
    else if (viewType == 0) {
      if (this.entity.reportGridConfig?.assetConfig) {
        this.gridConfig = {
          _self: this,
          colDefs: this.entity.reportGridConfig?.assetConfig.colDefs,
          customiseParams: this.entity.reportGridConfig?.assetConfig.customiseParams
        }
      } else {
        if (this.tempAssetColDefs) {
          this.gridConfig = {
            _self: this,
            colDefs: JSON.parse(JSON.stringify(this.tempAssetColDefs)),
            customiseParams: {
              column: "asset",
              id: this.id
            }
          }
        } else {
          this.gridConfig = {
            _self: this,
            colDefs: JSON.parse(JSON.stringify(defaultReportAssetViewGridConfigColDef)),
            customiseParams: {
              column: "asset",
              id: this.id
            }
          }
        }
      }
      this.filterConfig = [
        {
          field: "AssetName",
          title: "Asset name",
          type: "string",
          value: { operator: 7, valuePrimary: "", valueSecondary: null }
        },
        {
          title: "Event count",
          field: "IncidentCount",
          type: "number",
        },
        {
          title: "Minimal horizontal distance to asset [m]",
          field: "MinimalDistanceToAsset",
          type: "number",
        },
        {
          title: "Minimal absolute distance to asset [m]",
          field: "MinimalDistance3DToAsset",
          type: "number",
        },
        {
          title: "Estimated volume [m<sup>3</sup>]",
          field: "EstimatedVolumeImpacted",
          type: "number",
        },
        {
          title: "Estimated area [m<sup>2</sup>]",
          field: "EstimatedAreaImpacted",
          type: "number",
        },
        {
          title: "Estimated length asset impacted [m]",
          field: "EstimatedAssetLengthImpacted",
          type: "number",
        },
        {
          title: "Estimated  asset length impacted [%]",
          field: "EstimatedAssetPercentageImpacted",
          type: "number",
        },
        {
          title: "Total length of asset [m]",
          field: "AssetLength",
          type: "number",
        },
        {
          field: "assetType",
          title: "Asset type",
          type: "string",
        },
        {
          field: "AssetCategory",
          title: "Asset Category",
          type: "string",
          value: { operator: 7, valuePrimary: "", valueSecondary: null }
        },
        {
          title: "Elevation",
          field: "elevation",
          type: "number",
        },
        {
          field: "assetLocation",
          title: "Asset Location",
          type: "string",
        },
        {
          field: "assetSublocation",
          title: "Location subgroup",
          type: "string",
        },
        {
          title: "Start Point",
          field: "startName",
          type: "string",
        },
        {
          title: "End Point",
          field: "endName",
          type: "string",
        },

      ];
      //if (!this._companyConfig?.showAbsoluteDistance) {
      //  this.gridConfig.colDefs.splice(this.gridConfig.colDefs.findIndex((item: any) => item.field === "minimalDistance3DToAsset"), 1)
      //  this.filterConfig.splice(this.filterConfig.findIndex((item: any) => item.field === "MinimalDistance3DToAsset"), 1)
      //}
      this.service = this._incidentByAssetService;
      this.makeLayerImpactedAssets();
    }
    else if (viewType == 2) {
      if (this.entity.reportGridConfig?.compareConfig) {
        this.gridConfig = {
          _self: this,
          colDefs: this.entity.reportGridConfig?.compareConfig.colDefs,
          customiseParams: this.entity.reportGridConfig?.compareConfig.customiseParams
        }
      } else {
        this.gridConfig = {
          _self: this,
          colDefs: JSON.parse(JSON.stringify(defaultReportCompareViewGridConfigColDef)),
          customiseParams: {
            column: "compare",
            id: this.id
          }
        }
      }

      this.filterConfig = [
        {
          field: "assetName",
          title: "Asset name",
          type: "string",
          value: { operator: 7, valuePrimary: "", valueSecondary: null }
        },
        {
          title: "Event Count Pass 1",
          field: "IncidentCountR1",
          type: "number",
        },
        {
          title: "Event Count Pass 2",
          field: "IncidentCountR2",
          type: "number",
        },
        {
          title: "Event count Diff",
          field: "IncidentCountDiffrence",
          type: "number",
        },
        {
          title: "Min. horizontal distance Pass 1 [m]",
          field: "MinimalDistanceToAssetR1",
          type: "number",
        },
        {
          title: "Min. horizontal distance Pass 2 [m]",
          field: "MinimalDistanceToAssetR2",
          type: "number",
        },
        {
          title: "Min. horizontal distance Diff",
          field: "MinimalDistanceToAssetDiffrence",
          type: "number",
        },
        {
          title: "Min. absolute distance Pass 1 [m]",
          field: "MinimalDistance3DToAssetR1",
          type: "number",
        },
        {
          title: "Min. absolute distance Pass 2 [m]",
          field: "MinimalDistance3DToAssetR2",
          type: "number",
        },
        {
          title: "Min. absolute distance Diff",
          field: "MinimalDistance3DToAssetDiffrence",
          type: "number",
        },
        {
          title: "Impacted length Pass 1 [m]",
          field: "EstimatedAssetLengthImpactedR1",
          type: "number",
        },
        {
          title: "Impacted length Pass 2 [m]",
          field: "EstimatedAssetLengthImpactedR2",
          type: "number",
        },
        {
          title: "Impacted length Diff [m]",
          field: "ImpactDifference",
          type: "number",
        },
        {
          title: "Impacted length Pass 1 [%]",
          field: "EstimatedAssetPercentageImpactedR1",
          type: "number",
        },
        {
          title: "Impacted length Pass 2 [%]",
          field: "EstimatedAssetPercentageImpactedR2",
          type: "number",
        },
        {
          title: "Impacted length Diff [%]",
          field: "ImpactDifferencePrecent",
          type: "number",
        },
        {
          title: "Total length of asset [m]",
          field: "AssetLength",
          type: "number",
        },
        {
          field: "assetType",
          title: "Asset type",
          type: "string",
        },
        {
          field: "AssetCategory",
          title: "Asset Category",
          type: "string",
          value: { operator: 7, valuePrimary: "", valueSecondary: null }
        },
        {
          title: "Elevation",
          field: "elevation",
          type: "number",
        },
        {
          field: "assetLocation",
          title: "Asset Location",
          type: "string",
        },
        {
          field: "assetSublocation",
          title: "Location subgroup",
          type: "string",
        },
        {
          title: "Start Point",
          field: "startName",
          type: "string",
        },
        {
          title: "End Point",
          field: "endName",
          type: "string",
        }

      ];
      //if (!this._companyConfig?.showAbsoluteDistance) {
      //  this.gridConfig.colDefs.splice(this.gridConfig.colDefs.findIndex((item: any) => item.field === "minimalDistance3DToAssetR1"), 1)
      //  this.gridConfig.colDefs.splice(this.gridConfig.colDefs.findIndex((item: any) => item.field === "minimalDistance3DToAssetR2"), 1)
      //  this.gridConfig.colDefs.splice(this.gridConfig.colDefs.findIndex((item: any) => item.field === "minimalDistance3DToAssetDiffrence"), 1)
      //  this.filterConfig.splice(this.filterConfig.findIndex((item: any) => item.field === "MinimalDistance3DToAssetR1"), 1)
      //  this.filterConfig.splice(this.filterConfig.findIndex((item: any) => item.field === "MinimalDistance3DToAssetR2"), 1)
      //  this.filterConfig.splice(this.filterConfig.findIndex((item: any) => item.field === "MinimalDistance3DToAssetDiffrence"), 1)
      //}
      this.service = this._incidentByAssetCompareService;
      this.makeLayerCompareImpactedAssets();
    }
    else if (viewType == 3) {
      if (this.entity.reportGridConfig?.assetConfig) {
        this.gridConfig = {
          _self: this,
          colDefs: this.entity.reportGridConfig?.assetConfig.colDefs,
          customiseParams: this.entity.reportGridConfig?.assetConfig.customiseParams
        }
      } else {
        if (this.tempAssetColDefs) {
          this.gridConfig = {
            _self: this,
            colDefs: JSON.parse(JSON.stringify(this.tempAssetColDefs)),
            customiseParams: {
              column: "asset",
              id: this.id
            }
          }
        } else {
          this.gridConfig = {
            _self: this,
            colDefs: JSON.parse(JSON.stringify(defaultReportAssetViewGridConfigColDef)),
            customiseParams: {
              column: "asset",
              id: this.id
            }
          }
        }
      }
      this.filterConfig = [
        {
          field: "AssetName",
          title: "Asset name",
          type: "string",
          value: { operator: 7, valuePrimary: "", valueSecondary: null }
        },
        {
          title: "Event count",
          field: "IncidentCount",
          type: "number",
        },
        {
          title: "Minimal horizontal distance to asset [m]",
          field: "MinimalDistanceToAsset",
          type: "number",
        },
        {
          title: "Minimal absolute distance to asset [m]",
          field: "MinimalDistance3DToAsset",
          type: "number",
        },
        {
          title: "Estimated volume [m<sup>3</sup>]",
          field: "EstimatedVolumeImpacted",
          type: "number",
        },
        {
          title: "Estimated area [m<sup>2</sup>]",
          field: "EstimatedAreaImpacted",
          type: "number",
        },
        {
          title: "Estimated length asset impacted [m]",
          field: "EstimatedAssetLengthImpacted",
          type: "number",
        },
        {
          title: "Estimated  asset length impacted [%]",
          field: "EstimatedAssetPercentageImpacted",
          type: "number",
        },
        {
          title: "Total length of asset [m]",
          field: "AssetLength",
          type: "number",
        },
        {
          field: "assetType",
          title: "Asset type",
          type: "string",
        },
        {
          field: "AssetCategory",
          title: "Asset Category",
          type: "string",
          value: { operator: 7, valuePrimary: "", valueSecondary: null }
        },
        {
          title: "Elevation",
          field: "elevation",
          type: "number",
        },
        {
          field: "assetLocation",
          title: "Asset Location",
          type: "string",
        },
        {
          field: "assetSublocation",
          title: "Location subgroup",
          type: "string",
        },
        {
          title: "Start Point",
          field: "startName",
          type: "string",
        },
        {
          title: "End Point",
          field: "endName",
          type: "string",
        },

      ];
      //if (!this._companyConfig?.showAbsoluteDistance) {
      //  this.gridConfig.colDefs.splice(this.gridConfig.colDefs.findIndex((item: any) => item.field === "minimalDistance3DToAsset"), 1)
      //  this.filterConfig.splice(this.filterConfig.findIndex((item: any) => item.field === "MinimalDistance3DToAsset"), 1)
      //}
      this.service = this._incidentByAssetClimateService;
      this.makeLayerImpactedAssets();
    }
    let self = this;
    setTimeout(function () {
      self.dataGrid.unsubscribeService()
        .then(() => {
          self.dataGrid.loadData = true;
          self.dataGrid.refreshGrid()
        });
    }, 50);
  }

  public openGridConfig(): void {
    const dialogRef = this.dialog.open(GridConfigDialog, {
      data: this.gridConfig,
      width: '30vw',
      height: '80vh',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      //this.entity.reportGridConfig.eventConfig.colDefs = this.gridConfig.colDefs;
      if (this.viewType == "0") {
        this.tempAssetColDefs = this.gridConfig.colDefs;
      }
      if (this.viewType == "1") {
        this.tempEventColDefs = this.gridConfig.colDefs;
      }

      this.handleGridShowOnMapAssign();
      //this.refreshGrid();
    });
  }

  private handleGridShowOnMapAssign() {
    var foundIndex = this.gridConfig.colDefs.findIndex((x: any) => x.action === 'showOnMap');
    if (foundIndex > -1) {
      this.gridConfig.colDefs[foundIndex].action = this.showOnMap;
    }

    var foundIndex = this.gridConfig.colDefs.findIndex((x: any) => x.originalTitle === 'Show on Map');
    if (foundIndex > -1) {
      this.gridConfig.colDefs[foundIndex].action = this.showOnMap;
    }
  }

  private makeLayerIncidents() {
    this._mapLayerService.addEvent('incidents', 'showLoader', {})
    let layer = new MapLayer();
    this.mapDataLoading = true;
    //layer.id = "Report";
    this._incidentService.getNonPagedList(this.filter, {}, this.id).subscribe({
      next: (result: any) => {
        let mapData = result.items;
        layer.id = "incidents_report" + this.entity.name;
        layer.name = "Report " + this.entity.name;
        layer.visible = true;

        let _vectorSource = new VectorSource();
        let _wkbFormatter = new WKB();

        mapData.forEach(function (value: any) {
          let _feature = _wkbFormatter.readFeature(value.wkb, {
            dataProjection: 'EPSG:3857',
            featureProjection: 'EPSG:3857',
          });
          _feature.setId(value.id);
          _feature.setProperties({
            popup: JSON.stringify({
              'Distance': value.distance,
              'Height': value.height,
              'Class': value.class,
              'NDVI': value.ndvi
            }, undefined, 2)
          })

          var geometry = _feature.getGeometry();
          let geometryType = geometry?.getType();
          if (['LineString', 'MultiLineString'].includes(geometryType!)) {
            _feature.setStyle(
              new Style({
                stroke: new Stroke({
                  color: value.properties.lineColor,
                  width: value.properties.lineWidth,
                })
              })
            );
          }
          else if (['Point', 'MultiPoint'].includes(geometryType!)) {
            _feature.setStyle(
              new Style({
                fill: new Fill({
                  color: value.properties.pointColor,
                }),
                stroke: new Stroke({
                  color: value.properties.pointOutlineColor,
                  width: value.properties.pointWidth,
                }),
              })
            );
          }
          else if (['Polygon', 'MultiPolygon', 'GeometryCollection'].includes(geometryType!)) {
            _feature.setStyle(
              new Style({
                fill: new Fill({
                  color: value.properties.polygonColor,
                }),
                stroke: new Stroke({
                  color: value.properties.polygonOutlineColor,
                  width: value.properties.polygonOutlineWidth,
                }),
              })
            );
          }
          _vectorSource.addFeature(_feature);
        });

        layer.olLayer = new VectorLayer({ source: _vectorSource });
        layer.olLayer.set('layerId', layer.id);
        this._mapLayerService.addLayer(layer, true);
        this.mapDataLoading = false;
        this._mapLayerService.addEvent('incidents', 'hideLoader', {})
      },
      error: (error: Error) => console.error(error)
    });
  }

  private makeLayerImpactedAssets() {
    let layer2 = new MapLayer();
    this.mapDataLoading = true;
    //layer2.id = "incidents_to_asset";
    this._incidentsByAssetService.getReportDetailAsset(this.filter, {}, this.id).subscribe({
      next: (result: any) => {
        let mapData = result.items;

        layer2.id = "incidents_to_asset_report" + this.entity.name;
        layer2.name = "Impacted assets report " + this.entity.name;
        layer2.visible = true;

        let _vectorSource = new VectorSource();
        let _wkbFormatter = new WKB();

        mapData.forEach(function (value: any) {
          let _feature = _wkbFormatter.readFeature(value.wkb, {
            dataProjection: 'EPSG:3857',
            featureProjection: 'EPSG:3857',
          });
          //_feature.setId(value.id);
          var geometry = _feature.getGeometry();
          let geometryType = geometry?.getType();
          if (['LineString', 'MultiLineString'].includes(geometryType!)) {
            _feature.setStyle(
              new Style({
                stroke: new Stroke({
                  color: "#ff9100",
                  width: 4,
                })
              })
            );
          }
          else if (['Point'].includes(geometryType!)) {
            var pointGeom = <Point>_feature.getGeometry();
            var coord = pointGeom.getCoordinates();
            var startPoint = coord;
            var endPoint = [coord[0] + 0.1, coord[1] + 0.1]; // Create a short line
            _feature.setGeometry(new LineString([startPoint, endPoint]));
            _feature.setStyle(
              new Style({
                stroke: new Stroke({
                  color: "#ff9100",
                  width: 10,
                })
              })
            );
          }
          else if (['Point', 'MultiPoint'].includes(geometryType!)) {
            _feature.setStyle(
              new Style({
                image: new Circle({
                  radius: 5,
                  fill: new Fill({
                    color: "#ff9100",
                  }),
                  stroke: new Stroke({
                    color: "#000",
                    width: 1,
                  })
                })
              })
            );
          }
          _vectorSource.addFeature(_feature);
        });
        layer2.olLayer = new VectorLayer({ source: _vectorSource });
        this._mapLayerService.addLayer(layer2, true);
        this.mapDataLoading = false;
        this._mapLayerService.addEvent('incidents', 'hideLoader', {})
      },
      error: (error: Error) => console.error(error)
    });
  }

  private makeLayerCompareImpactedAssets() {
    let layer2 = new MapLayer();
    this.mapDataLoading = true;
    //layer2.id = "incidents_to_asset_compare";
    this._incidentByAssetCompareService.getNonPagedList(this.filter, {}, this.id).subscribe({
      next: (result: any) => {
        let mapData = result.items;

        layer2.id = "incidents_to_asset_compare" + this.entity.name;
        layer2.name = "Impacted assets compare report " + this.entity.name;
        layer2.visible = true;

        let _vectorSource = new VectorSource();
        let _wkbFormatter = new WKB();

        let max = mapData.reduce((prev: any, current: any) => (prev && prev.growthRate > current.growthRate) ? prev : current)
        let min = mapData.reduce((prev: any, current: any) => (prev && prev.growthRate < current.growthRate) ? prev : current)

        if (min.growthRate < 0) {
          layer2.categories = [
            {
              name: max.growthRate + " - " + max.growthRate / 2,
              outlineColor: "#FF0000",
              color: "#FF0000",
              expression: "",
              outlineWidth: 1,
              pxlval: 0,
              geometryType: ""
            },
            {
              name: max.growthRate / 2 + " - 0",
              outlineColor: "#880000",
              color: "#880000",
              expression: "",
              outlineWidth: 1,
              pxlval: 0,
              geometryType: ""
            },
            {
              name: "0 - " + min.growthRate / 2,
              outlineColor: "#008800",
              color: "#008800",
              expression: "",
              outlineWidth: 1,
              pxlval: 0,
              geometryType: ""
            },
            {
              name: min.growthRate / 2 + " - " + min.growthRate,
              outlineColor: "#00FF00",
              color: "#00FF00",
              expression: "",
              outlineWidth: 1,
              pxlval: 0,
              geometryType: ""
            }];
        } else {
          layer2.categories = [
            {
              name: max.growthRate + " - " + max.growthRate / 2,
              outlineColor: "#FF0000",
              color: "#FF0000",
              expression: "",
              outlineWidth: 1,
              pxlval: 0,
              geometryType: ""
            },
            {
              name: max.growthRate / 2 + " - 0",
              outlineColor: "#880000",
              color: "#880000",
              expression: "",
              outlineWidth: 1,
              pxlval: 0,
              geometryType: ""
            }
          ];
        }

        mapData.forEach(function (value: any) {
          //if (value.geometryR1 != "LINESTRING EMPTY") {
          //  let _feature = _wkbFormatter.readFeature(value.geometryR1, {
          //    dataProjection: 'EPSG:3857',
          //    featureProjection: 'EPSG:3857',
          //  });
          //  //_feature.setId(value.id);
          //  var geometry = _feature.getGeometry();
          //  let geometryType = geometry?.getType();
          //  if (['LineString', 'MultiLineString'].includes(geometryType!)) {
          //    _feature.setStyle(
          //      new Style({
          //        stroke: new Stroke({
          //          color: "#FF0000",
          //          width: 4,
          //        })
          //      })
          //    );
          //  }
          //  _vectorSource.addFeature(_feature);
          //}
          //if (value.geometryR2 != "LINESTRING EMPTY") {
          //  let _feature2 = _wkbFormatter.readFeature(value.geometryR2, {
          //    dataProjection: 'EPSG:3857',
          //    featureProjection: 'EPSG:3857',
          //  });
          //  //_feature.setId(value.id);
          //  var geometry2 = _feature2.getGeometry();
          //  let geometryType2 = geometry2?.getType();
          //  if (['LineString', 'MultiLineString'].includes(geometryType2!)) {
          //    _feature2.setStyle(
          //      new Style({
          //        stroke: new Stroke({
          //          color: "#00FF00",
          //          width: 4,
          //        })
          //      })
          //    );
          //  }
          //  _vectorSource.addFeature(_feature2);
          //}
          //if (value.geometryBoth != "LINESTRING EMPTY") {
          //  let _feature3 = _wkbFormatter.readFeature(value.geometryBoth, {
          //    dataProjection: 'EPSG:3857',
          //    featureProjection: 'EPSG:3857',
          //  });
          //  //_feature.setId(value.id);
          //  var geometry3 = _feature3.getGeometry();
          //  let geometryType3 = geometry3?.getType();
          //  if (['LineString', 'MultiLineString'].includes(geometryType3!)) {
          //    _feature3.setStyle(
          //      new Style({
          //        stroke: new Stroke({
          //          color: "#FFFF00",
          //          width: 4,
          //        })
          //      })
          //    );
          //  }
          //  _vectorSource.addFeature(_feature3);
          //}

          let _feature4 = _wkbFormatter.readFeature(value.geometryAsset, {
            dataProjection: 'EPSG:3857',
            featureProjection: 'EPSG:3857',
          });
          //_feature.setId(value.id);
          var geometry4 = _feature4.getGeometry();
          let geometryType4 = geometry4?.getType();
          if (['LineString', 'MultiLineString'].includes(geometryType4!)) {
            if (value.growthRate > max.growthRate / 2) {
              _feature4.setStyle(
                new Style({
                  stroke: new Stroke({
                    color: "#FF0000",
                    width: 3,
                  })
                })
              );
            } else if (value.growthRate > 0 && value.growthRate < max.growthRate / 2) {
              _feature4.setStyle(
                new Style({
                  stroke: new Stroke({
                    color: "#880000",
                    width: 3,
                  })
                })
              );
            } else if (value.growthRate <= 0 && value.growthRate > min.growthRate / 2) {
              _feature4.setStyle(
                new Style({
                  stroke: new Stroke({
                    color: "#008800",
                    width: 3,
                  })
                })
              );
            } else if (value.growthRate < 0 && value.growthRate < min.growthRate / 2) {
              _feature4.setStyle(
                new Style({
                  stroke: new Stroke({
                    color: "#00FF00",
                    width: 3,
                  })
                })
              );
            }
          }
          _vectorSource.addFeature(_feature4);
        });
        layer2.olLayer = new VectorLayer({ source: _vectorSource });
        this._mapLayerService.addLayer(layer2, true);
        this.mapDataLoading = false;
        this._mapLayerService.addEvent('incidents', 'hideLoader', {})
      },
      error: (error: Error) => console.error(error)
    });
  }

  private makeLayerSatImages() {
    this.http.get<any>(`${environment.apiUrl}/api/SatelliteImage/GetReportBaseLayer?id=${this.id}`).subscribe({
      next: (result: any) => {
        let extent = undefined;
        var item = result.item;
        if (item) {
          let imageLayer = new MapLayer();
          imageLayer.name = item.name;
          imageLayer.id = "image" + item.id;

          if (item.wkt != null) {
            let feature = new WKT().readFeature(item.wkt, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' });
            extent = feature.getGeometry()?.getExtent();
          }
          imageLayer.olLayer = new TileLayer({
            extent: extent,
            preload: Infinity,
            source: new TileWMS({
              url: item.wmsUrl,
              params: { 'LAYERS': item.layers, 'TILED': true },
              transition: 0
            })
          });

          this._mapLayerService.addLayer(imageLayer, false, true);
        }
        return extent;
      },
      error: (error: Error) => console.error(error)
    });
  }

  private loadAssetsOnMap() {
    this._mapLayerService.addEvent('incidents', 'showLoader', {})
    let layer = new MapLayer();
    this.mapDataLoading = true;
    layer.id = "assets";
    this._assetsService.getNonPagedList(this.filter, {}).subscribe({
      next: (result: any) => {
        let mapData = result.items;
        layer.name = "Assets";
        layer.visible = true;
        layer.total = result.items.length;
        let _vectorSource = new VectorSource();
        let _wkbFormatter = new WKB();

        mapData.forEach(function (value: any) {
          let _feature = _wkbFormatter.readFeature(value.wkb, {
            dataProjection: 'EPSG:3857',
            featureProjection: 'EPSG:3857',
          });
          _feature.setId(value.id);
          _feature.setProperties({
            popup: JSON.stringify({
              'Name': value.name,
            }, undefined, 2)
          })

          var geometry = _feature.getGeometry();
          let geometryType = geometry?.getType();
          if (['Point'].includes(geometryType!)) {
            var pointGeom = <Point>_feature.getGeometry();
            var coord = pointGeom.getCoordinates();
            var startPoint = coord;
            var endPoint = [coord[0] + 0.1, coord[1] + 0.1]; // Create a short line
            _feature.setGeometry(new LineString([startPoint, endPoint]));
            geometry = _feature.getGeometry();
            geometryType = geometry?.getType();
          }
          if (['LineString', 'MultiLineString'].includes(geometryType!)) {
            _feature.setStyle(
              new Style({
                stroke: new Stroke({
                  color: value.properties.lineColor,
                  width: value.properties.lineWidth,
                })
              })
            );
          }
          else if (['Point', 'MultiPoint'].includes(geometryType!)) {
            _feature.setStyle(
              new Style({
                image: new Circle({
                  radius: value.properties.pointWidth,
                  fill: new Fill({
                    color: value.properties.pointColor,
                  }),
                  stroke: new Stroke({
                    color: value.properties.pointOutlineColor,
                    width: 1,
                  })
                })
              })
            );
          }
          else if (['Polygon', 'MultiPolygon', 'GeometryCollection'].includes(geometryType!)) {
            _feature.setStyle(
              new Style({
                fill: new Fill({
                  color: value.properties.polygonColor,
                }),
                stroke: new Stroke({
                  color: value.properties.polygonOutlineColor,
                  width: value.properties.polygonOutlineWidth,
                }),
              })
            );
          }
          _vectorSource.addFeature(_feature);
        });

        layer.olLayer = new VectorLayer({ source: _vectorSource });
        this._mapLayerService.addLayer(layer, true, true);
        this.mapDataLoading = false;
        this._mapLayerService.addEvent('incidents', 'hideLoader', {})
      },
      error: (error: Error) => console.error(error)
    });
    return layer;
  }

  downloadExcel() {
    let params = {
      "operationType": "SEARCH",
      "startRow": 0,
      "pageSize": 20,
      "updateCount": true,
      "parentId": this.id,
      "sort": {},
      "filter": this.filter
    }

    let endpoint = 'GetExcelReportExportFile';
    if (this.viewType == "0") {
      endpoint = 'GetExcelReportAssetExportFile';
    };

    this.http.post<any>(`${environment.apiUrl}/api/Incident/GetExcelExport`, params).subscribe({
      next: (result: any) => {
        const url = `${environment.apiUrl}/api/Incident/` + endpoint + `?key=` + result.item;
        const link = document.createElement('a');
        link.href = url;
        //link.setAttribute('download', 'fabric-qr-code.pdf');
        document.body.appendChild(link);
        link.click();
      },
      error: (error: Error) => console.error(error)
    });
  }

  downloadGeojson() {
    let params = {
      "operationType": "SEARCH",
      "startRow": 0,
      "pageSize": 20,
      "updateCount": true,
      "parentId": this.id,
      "sort": {},
      "filter": this.filter
    }

    let endpoint = 'GetExcelReportExportGeojsonFile';
    if (this.viewType == "0") {
      endpoint = 'GetExcelAssetReportExportGeojsonFile';
    };

    this.http.post<any>(`${environment.apiUrl}/api/Incident/GetExcelExport`, params).subscribe({
      next: (result: any) => {
        const url = `${environment.apiUrl}/api/Incident/` + endpoint + `?key=` + result.item;
        const link = document.createElement('a');
        link.href = url;
        //link.setAttribute('download', 'fabric-qr-code.pdf');
        document.body.appendChild(link);
        link.click();
      },
      error: (error: Error) => console.error(error)
    });
  }
  showParameters() {
    if (this.variable == null) {
      this.variable = this.entity.variable;
    }
    this.dialog.open(ReportParametersDialogComponent, {
      data: {
        entity: this.entity,
        variable: this.variable
      }
    });
  }
  showOnMap(item?: any) {
    if (item != null) {
      if (this.viewType == "1") {
        this._olMapService.addEvent('incidents', 'selectFeature', { layerId: "incidents_report" + this.entity.name, featureId: item.id })
        this._mapLayerService.addEvent('incidents', 'zoomToFeature', { layerId: "incidents_report" + this.entity.name, featureId: item.id })
      }
      else if (this.viewType == "0") {
        this._mapLayerService.addEvent('incidents', 'zoomToFeature', { layerId: 'assets', featureId: item.id })
      }
      else if (this.viewType == "2") {
        this._mapLayerService.addEvent('incidents', 'zoomToFeature', { layerId: 'assets', featureId: item.id })
      }
      this.setMode("map");
    }
    /*else {
      if (this.selectedCaterogry == "monitoring") {
        this.makeLayerAssets();
      } else {
        this.makeLayerIncidents();
      }
    }*/
  }

  public setMode(mode: string, zoomToLayer: boolean = false) {
    this.mode = mode;
    if (mode == 'map') {
      this._olMapService.addEvent('incidents', 'updateSize', {});
      if (zoomToLayer) {
        if (this.viewType == "0") {
          this._mapLayerService.addEvent('incidents', 'zoomToLayer', { layerId: "incidents_to_asset_report" + this.entity.name })
        }
        else if (this.viewType == "1") {
          this._mapLayerService.addEvent('incidents', 'zoomToLayer', { layerId: "incidents_report" + this.entity.name })
        }
      }
    }
  }
}
