<h1 mat-dialog-title *ngIf="data.title">{{data.title}}</h1>
<div mat-dialog-content>
  <ul>
    <li *ngFor="let item of data.dataTable | keyvalue">
      <strong>{{item.key}}</strong> : {{item.value}}
    </li>
  </ul>
</div>
<div mat-dialog-actions>
  <button mat-stroked-button mat-dialog-close>Close</button>
</div>
