import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";
import { DialogService } from "@/_services/dialog.service";
import { environment } from "@envs/environment";
import { Router } from "@angular/router";
import { DataLayersService } from "@/_services/data-layers.service";
import { ProductTypeService } from "@/_services/product-type.service";

@Component({
  standalone: false,
  selector: 'data-layer-create',
  providers: [
    {
      provide: DataLayersService
    },
    {
      provide: ProductTypeService
    }
  ],
  styleUrls: ['data-layer-create.component.scss'],
  templateUrl: 'data-layer-create.component.html',
})
export class DataLayerCreateComponent {
  public customDataLayerName: string = "";
  public createLoader: boolean = false;
  http: HttpClient;
  public selectedDataLayers: any = [];
  public availableFilters: any = [];
  public selectedFilters: any = [];
  public showHint: boolean = true;
  public hintText: string | undefined;
  private readonly _dataLayersLookupsService: DataLayersService;
  private readonly _productTypeService: ProductTypeService;
  public groupId: any;
  public mergeR: any = ["0.5"];
  public dataLayers: any = [];
  public distanceFilter = {
    field: "Distance",
    title: "Horizontal distance [m]",
    type: "numberlimited",
    value: { operator: 6, valuePrimary: null, valueSecondary: null }
  };
  public areaFilter = {
    field: "Area",
    title: "Area",
    type: "numberlimited",
    value: { operator: 4, valuePrimary: null, valueSecondary: null }
  };
  public dataLayersGroups: any = [];
  public filteredDataLayers: any = [];

  constructor(
    private readonly _http: HttpClient,
    public dialogService: DialogService,
    private readonly router: Router,
    _dataLayersLookupsService: DataLayersService,
    _productTypeService: ProductTypeService
  ) {
    this.http = _http;
    this.showHint = false;
    this._dataLayersLookupsService = _dataLayersLookupsService;
    this._productTypeService = _productTypeService;
    this._productTypeService.getNonPagedList().subscribe(result => {
      if (result.ok && result.items.length > 0) {
        result.items.forEach((productType: any) => {
          if(productType.data?.length > 0) {
            this.dataLayers = [...this.dataLayers, ...productType.data];
          }
        });
        this.dataLayers.forEach((dataLayer: any) => {
          dataLayer.selected = false;
          if(dataLayer.filters == undefined) {
            dataLayer.filters = [];
          }
          dataLayer.filters.push({
            field: "Date",
            title: "Date",
            type: "date"
          })
        })

        this._dataLayersLookupsService.getNonPagedList().subscribe(result => {
          if (result.ok && result.items.length > 0) {
            result.items.forEach((item: any) => {
              this.dataLayers = [...this.dataLayers, {
                id: item.id,
                name: item.name,
                group: "Custom data layer inputs",
                filters: item.availableFilters,
                selected: false
              }];
            });
          }
          this.refreshDataLayersGroups();
          this.filterDataLayers();
        });
      }
    });
  }
  refreshDataLayersGroups() {
    if(this.dataLayers?.length > 0) {
      this.dataLayersGroups = Object.entries(
        this.dataLayers.reduce((acc: any, item: any) => {
          acc[item.group] = (acc[item.group] || 0) + 1;
          return acc;
        }, {} as Record<string, number>)
      ).map(([name, count]) => ({ name, count}));
      let index = this.dataLayersGroups.findIndex((x: any) => { return x.active })
      if (index == -1) {
        this.dataLayersGroups.forEach((item: any) => {
          item.active = false
          item.id = item.name.toLowerCase().replace(/\s/g, '');
        })
        this.dataLayersGroups[0].active = true;
      }
    }
  }
  selectDataLayerGroup(dataLayerGroup: any) {
    this.dataLayersGroups.forEach((item: any) => item.active = false)
    dataLayerGroup.active = true;
    this.filterDataLayers();
  }
  filterDataLayers() {
    let currentDataLayerGroup = this.dataLayersGroups.find((x: any) => x.active);
    this.filteredDataLayers = this.dataLayers.filter((x: any) => x.group === currentDataLayerGroup.name );
  }
  selectDataLayer(dataLayer: any) {
    dataLayer.selected = true;
    let index = this.selectedDataLayers.findIndex((x: any) => { return x.id == dataLayer.id })
    if (index == -1) {
      this.selectedDataLayers = [...this.selectedDataLayers, dataLayer];
    }
    this.onDataLayersChange(this.selectedDataLayers)
  }

  onMouseEnterDataLayerGroup(dataLayerGroup: any) {

  }
  onMouseEnterDataLayer(dataLayer: any) {

  }

  onDataLayersChange($event: any) {
    this.availableFilters = [];
    $event.forEach((dataLayer: any) => {
      if(dataLayer.filters != undefined) {
        dataLayer.filters.forEach((filter: any) => {
          filter.parentId = dataLayer.id
          filter.parentName = dataLayer.name;
          if (!this.availableFilters.find((x: any) => {
            return x.id == filter.id && x.parentId == dataLayer.id
          })) {
            this.availableFilters = [...this.availableFilters, filter];
          }
          if (!this.selectedFilters.find((x: any) => {
            return x.id == filter.id && x.parentId == dataLayer.id
          })) {
            if(dataLayer.defaultFilters?.find((x: any) => x == filter.id)) {
              this.selectedFilters = [...this.selectedFilters, filter];
            }
          }
        })
      }
    })
  }
  onDataLayersClear($event: any) {
    this.availableFilters = [];
    this.dataLayers.forEach((item: any) => item.selected = false)
  }

  onDataLayerRemove($event: any) {
    $event.selected = false;
    this.selectedFilters = [...this.selectedFilters.filter((x: any) => {
      return x.parentId != $event.id
    })]
  }
  removeDataLayer(dataLayer: any) {
    let index = this.selectedDataLayers.findIndex((x: any) => { return x.id == dataLayer.id })
    dataLayer.selected = false;
    if (index > -1) {
      this.selectedDataLayers.splice(index, 1);
    }
    this.selectedFilters = [...this.selectedFilters.filter((x: any) => {
      return x.parentId != dataLayer.id
    })]
  }

  toogleFilter(dataLayer: any, filter: any) {
    let index = this.selectedFilters.findIndex((x: any) => { return x.id == filter.id && x.parentId == dataLayer.id })
    if (index == -1) {
      filter.parentId = dataLayer.id
      filter.parentName = dataLayer.name;
      this.selectedFilters = [...this.selectedFilters, filter];
    }
  }
  removeFilter(dataLayer: any, filter: any) {
    this.selectedFilters = [...this.selectedFilters.filter((x: any) => {
      return !(x.id == filter.id && x.parentId == dataLayer.id)
    })]
  }

  create() {
    let selectedDataLayers: SingleDataLayerInfo[] = [];

    this.selectedDataLayers.forEach((dataLayer: any) => {
      selectedDataLayers.push({
        Id: dataLayer.id,
        Name: dataLayer.name,
        Date: undefined,
        GroupId: undefined,
        Columns: [],
        Table: "",
        Filters: []
      })
    });

    this.selectedFilters.forEach((filter: any) => {
      let index = selectedDataLayers.findIndex((x: any) => { return x.Id == filter.parentId })
      if (index > -1) {
        if(selectedDataLayers[index].Filters != undefined) {
          selectedDataLayers[index].Filters.push({
            Field: filter.field,
            Title: filter.title,
            Type: filter.type,
            Value: (filter.value.operator != undefined) ? filter.value : {Values: filter.value},
          });
        }
      } else {
        selectedDataLayers.push({
          Id: filter.parentId,
          Name: filter.parentName,
          Date: undefined,
          GroupId: undefined,
          Columns: [],
          Table: "",
          Filters: [{
            Field: filter.field,
            Title: filter.title,
            Type: filter.type,
            Value: (filter.value.operator != undefined) ? filter.value : {Values: filter.value},
          }]
        })
      }
    })

    const params = {
      Name: this.customDataLayerName,
      SelectedLayers: selectedDataLayers,
      DistanceFilter: this.distanceFilter,
      AreaFilter: this.areaFilter,
      MergeDistance: this.mergeR[0],
    };
    let closeme = true;

    if (!this.createLoader) {
      this.createLoader = true;
      this.http.post<any>(`${environment.apiUrl}/api/DataLayer/CreateCustomDataLayer/`, params).subscribe(result => {
        if (result.ok) {
          this.createLoader = false;
          this.router.navigate(["/data-layers"]);
        }
        else {
          this.dialogService.displayErrorMessages(result.messages);
          closeme = false;
          this.createLoader = false;
        }
      });
      setTimeout(() => {
        if (closeme) {
          this.createLoader = false;
          this.router.navigate(["/data-layers"]);
        }
      }, 5000);
    }
  }

  close() {
    this.router.navigate(["/data-layers"]);
  }
}

class SingleDataLayerInfo {
  Id: number | undefined;
  Name: string = "";
  Table: string = "";
  Date: any = { operator: 11, valuePrimary: null, valueSecondary: null };
  GroupId: number | undefined;
  Columns: string[] = [];
  Filters: GenericFilterDefinition[] = [];
}

class GenericFilterDefinition {
  Field: string = "";
  Type: string = "";
  Title: string = "";
  Value: any = { operator: null, valuePrimary: null, valueSecondary: null };
}
