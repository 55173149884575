import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResourcesService } from "./resources.service";

@Injectable({
  providedIn: 'root'
})
export class VariablesService extends ResourcesService {
  constructor(protected override httpClient: HttpClient) {
    super(httpClient);
  }
  override getResourceUrl(): string {
    return 'Variable';
  }
}
