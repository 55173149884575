import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpClient } from "@angular/common/http";
import { Component, Inject, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { environment } from "@envs/environment";
import { JsonCollectionResponse, JsonItemResponse, JsonResponse } from "@/_models/response";
import { InputDialogComponent } from '../input-dialog/input-dialog.component';
import { MatTabGroup } from "@angular/material/tabs";
import { DialogService } from '@/_services/dialog.service';
import { Category, Properties, Template } from '@/_models/templates';

@Component({
  selector: 'analysis-import',
  templateUrl: './analysis-import.component.html',
  styleUrls: ['./analysis-import.component.scss']
})
export class AnalysisImportComponent {
  public loading: boolean = false;
  public items: Category[] = [];
  public content: IDictionary<string>[] = [];
  public contentColumns: string[] = [];
  public guid: string = "";
  public analysisName: string = "New analysis";
  public analysisDate: any;
  public analysisType: string = "";

  public fileUploaded: boolean = false;
  public showSelectColumns: boolean = false;
  public showEditLayers: boolean = false;

  public selectedTemplate: Template | null = null;
  public currentTemplate: Template | null = null;
  public templates: Template[] = [];
  http: HttpClient;

  @ViewChild("shpUploadTabs", { static: false }) shpUploadTabs: MatTabGroup | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AnalysisImportComponent>,
    private _http: HttpClient,
    public dialog: MatDialog,
    public dialogService: DialogService
  ) {
    this.http = _http;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
  }

  applyTemplate() {
    this.showSelectColumns = true;
    if (this.selectedTemplate == null) {
      this.currentTemplate = new Template("New template");
      this.showEditLayers = false;
    }
    else {
      this.currentTemplate = JSON.parse(JSON.stringify(this.selectedTemplate));
      if (!this.currentTemplate?.properties) {
        this.currentTemplate!.properties = new Properties();
      }
      if (this.currentTemplate?.pxlvalColumn != null) {
        this.applyColumns(this.selectedTemplate.categories);
      }
      else {
        this.showEditLayers = false;
      }
    }
  }

  applyColumns(items?: Category[] | null) {
    this.loading = true;
    if (items != null) {
      this.showEditLayers = true;

      this.items = JSON.parse(JSON.stringify(items));
      this.loading = false;
    }
    else {
      this.http.get<JsonCollectionResponse<Category>>(`${environment.apiUrl}/api/Resource/GetShapeFileLayers?guid=${this.guid}&pxlval=${this.currentTemplate?.pxlvalColumn}&name=${this.currentTemplate?.nameColumn ?? ""}`).subscribe(result => {
        if (result.ok) {
          this.showEditLayers = true;
          this.items = result.items;
        }
        else {
          this.dialogService.displayErrorMessages(result.messages);
        }
        this.loading = false;
      });
    }
  }

  onCancelled(index: number) {
    this.guid = "";
  }

  onError(index: number, message: string) {
    this.dialogService.displayError(message);
    this.loading = false;
    this.guid = "";
  }

  onRemoved(index: number, guid: string) {
    this.guid = "";
  }

  onFileProgress(index: number, rangeStart: number, guid: string) {
  }

  onFileUploaded(index: number, guid: string) {
    this.guid = guid;
    this.loading = true;
    this.http.get<JsonCollectionResponse<IDictionary<any>>>(`${environment.apiUrl}/api/Resource/GetShapeFileContent?guid=${guid}`).subscribe(result => {
      if (result.ok) {
        this.content = [];
        this.contentColumns = [];
        var count = 0;
        if (result.items.length > 0) {
          for (let i = 0; i < result.items.length; i++) {
            var dict = result.items[i];
            var len = Object.keys(dict).length;
            if (this.contentColumns.length == 0) {
              Object.entries(dict).forEach(([key, value]: [string, any], index) => {
                this.contentColumns.push(value.key);
              });
            }

            var item: IDictionary<string> = {};
            Object.entries(dict).forEach(([key, value]: [string, any], index) => {
              item[value.key] = value.value;
            });
            this.content.push(item);
            if (count++ >= 15) {
              break;
            }
          }
          const tabGroup = this.shpUploadTabs;
          if (tabGroup != undefined && tabGroup != null && tabGroup instanceof MatTabGroup) {
            // @ts-ignore
            tabGroup.selectedIndex = tabGroup.selectedIndex + 1;
          }
        }

        this.http.post<JsonCollectionResponse<Template>>(`${environment.apiUrl}/api/AnalysisTemplate/GetNonPagedList`, {}).subscribe(result => {
          if (result.ok) {
            this.fileUploaded = true;
            this.templates = result.items;
          }
          else {
            this.dialogService.displayErrorMessages(result.messages);
          }
          this.loading = false;
        });
      }
      else {
        this.dialogService.displayErrorMessages(result.messages);
        this.loading = false;
      }
    });
  }

  overrideTemplate() {
    this.currentTemplate!.categories = JSON.parse(JSON.stringify(this.items));

    this.http.post<JsonResponse>(`${environment.apiUrl}/api/AnalysisTemplate/Update`, this.currentTemplate).subscribe(result => {
      if (!result.ok) {
        this.dialogService.displayErrorMessages(result.messages);
        this.loading = false;
      }
    }, error => console.error(error));
  }

  saveTemplate() {
    const dialogRef = this.dialog.open(InputDialogComponent, {
      width: '250px',
      data: { name: 'New template' }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.currentTemplate!.categories = JSON.parse(JSON.stringify(this.items));
      this.currentTemplate!.name = result;

      this.http.post<JsonItemResponse<number>>(`${environment.apiUrl}/api/AnalysisTemplate/Create`, this.currentTemplate).subscribe(result => {
        if (result.ok) {
          this.currentTemplate!.id = result.item;
          this.templates.push(this.currentTemplate!);
          this.selectedTemplate = this.currentTemplate!;
        }
        else {
          this.dialogService.displayErrorMessages(result.messages);
          this.loading = false;
        }
      }, error => console.error(error));
    });
  }

  import() {
    this.loading = true;
    this.currentTemplate!.categories = JSON.parse(JSON.stringify(this.items));
    var request = {
      categories: JSON.parse(JSON.stringify(this.items)),
      properties: this.currentTemplate!.properties,
      guid: this.guid,
      name: this.analysisName,
      type: this.analysisType,
      date: (new Date(Date.UTC(this.analysisDate.getFullYear(), this.analysisDate.getMonth(), this.analysisDate.getDate(), 0, 0, 0))).toISOString(),
      nameColumn: this.currentTemplate!.nameColumn,
      pxlvalColumn: this.currentTemplate!.pxlvalColumn,
      labelColumn: this.currentTemplate!.labelColumn,
      analysisTemplateId: this.currentTemplate!.id
    }
    this.http.post<JsonItemResponse<number>>(`${environment.apiUrl}/api/AnalysisData/Create`, request).subscribe(result => {
      if (result.ok) {
        this.dialogRef.close();
      }
      else {
        this.dialogService.displayErrorMessages(result.messages);
        this.loading = false;
      }
      this.loading = false;
    });
  }
}

interface IDictionary<T> {
  [key: string]: T
}
